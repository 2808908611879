import React from 'react';
import { Paper, TextField } from '@material-ui/core';
import Button from '@Components/Button';

export default function RefundCard(h) {
  return (
    <Paper style={{ flex: '1 0 auto', marginTop: 20, padding: 35 }}>
      <h3>Refund</h3>
      <p>Please enter Transaction ID</p>
      <div className="d-flex align-items-lg-center mt-3" style={{ width: '100%' }}>
        <p className="pr-1" style={{ width: '15%' }}>Transaction ID</p>
        <TextField
          variant="outlined"
          size="small"
          value={h.transactionId}
          fullWidth
          onChange={(e) => h.setTransactionId(e.target.value)}
          style={{ width: '85%' }}
        />
      </div>
      <div className="w-100 d-flex justify-content-end mt-3">
        <Button onClick={() => h.getRefund()}>Submit</Button>
      </div>
    </Paper>
  );
}
