import { useState, useEffect } from 'react';
import {
  Typography, CircularProgress, IconButton, Tooltip, Avatar,
} from '@material-ui/core';
import { Close, Edit } from '@material-ui/icons';
import Button from '@Components/Button';
import AvatarEdit from './AvatarEdit';

const style = {
  marginRight: -24,
  float: 'right',
};

export default ({
  user, isLoadingSave, isNotSelf = false, updateOrganization, organization,
}) => {
  const [isEdit, setIsEdit] = useState(false);
  const [image, setImage] = useState(organization?.image ?? []);

  useEffect(() => {
    resetForm();
  }, [user]);

  const resetForm = () => {
    setImage(user?.image);
  };

  const onSave = () => {
    closeEdit();
    updateOrganization(image);
  };

  const openEdit = () => setIsEdit(true);
  const closeEdit = () => { resetForm(); setIsEdit(false); };

  return (
    <div className="bg-white shadow rounded-xl px-4" style={{ height: !isNotSelf ? '41rem' : '100%' }}>
      <Tooltip title="Edit Organization">
        <IconButton style={style} onClick={isEdit ? closeEdit : openEdit} disabled={isLoadingSave}>
          {isEdit ? <Close style={{ color: '#EA0000' }} /> : <Edit className="color-primary" />}
        </IconButton>
      </Tooltip>
      <div className="w-100 flex-standard">
        {isEdit
          ? <AvatarEdit currentImage={`${process.env.REACT_APP_S3}/${organization?.image}`} setPhoto={setImage} />
          : <Avatar src={`${process.env.REACT_APP_S3}/${organization?.image}`} style={{ height: 120, width: 120 }} />}
      </div>
      <Typography variant="h6" className="text-center mt-3 mb-4">{user?.['Organization.name']}</Typography>
      {isEdit && (
        <Button className="my-4 float-right" disabled={isLoadingSave} onClick={onSave}>
          {isLoadingSave && <CircularProgress size={24} className="mr-2" />}
          Save changes
        </Button>
      )}
    </div>
  );
};
