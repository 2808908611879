import React from 'react';
import NoData from '@Assets/Images/DataNotFound.svg';
import Button from '@Components/Button';
import { Link } from 'react-router-dom';

export default function DeniedPage() {
  return (
    <div className="flex-standard flex-column">
      <img src={NoData} style={{ height: '70vh' }} />
      <Link to="/">
        <Button style={{ width: 200 }}>
          RETURN TO HOME PAGE
        </Button>
      </Link>
    </div>
  );
}
