import React from 'react';
import SmartBanner from 'react-smartbanner';

function SmartBannerContainer({ children }) {
  return (
    <>
      <SmartBanner title="geoRÄISE" />
      {children}
    </>
  );
}

export default SmartBannerContainer;
