/* eslint-disable no-nested-ternary */
import React from 'react';
import { makeStyles, createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { Checkbox, Chip } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    '&:hover': {
      backgroundColor: 'transparent !important',
    },
  },
}));

export default function RadioFilter({ tags = [], tagsFilter = 'All', setTagsFilter }) {
  const classes = useStyles();
  return (
    <MuiThemeProvider theme={getMuiTheme()}>
      {['All', ...tags]?.map(tag => (
        <Checkbox
          id="tags"
          onClick={e => setTagsFilter(e.target.value)}
          classes={{ root: classes.root }}
          value={tag === 'All' ? '' : tag}
          checked={tag === 'All' ? tagsFilter === '' : tagsFilter === tag}
          icon={(
            <Chip
              label={tag}
              size="small"
              style={{
                backgroundColor: '#F6F6F6', color: 'var(--secondary-color)',
              }}
            />
          )}
          checkedIcon={(
            <Chip
              label={tag}
              size="small"
              style={{
                backgroundColor: 'var(--secondary-color)', color: '#F6F6F6',
              }}
            />
          )}
        />
      ))}
    </MuiThemeProvider>
  );
}

const getMuiTheme = () => createMuiTheme({
  typography: {
    fontFamily: 'CeraProRegular',
  },
});
