/* eslint-disable no-tabs */
/* eslint-disable max-lines */
/* eslint-disable max-len */
import React from 'react';
import { primaryColor } from '@Assets/css';

export const FpxIcon = (props) => {
  return (
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 280 300" {...props}><path d="M60.22,181.44a5.17,5.17,0,0,1,.38-2.05,4.38,4.38,0,0,1,1-1.56,4.86,4.86,0,0,1,1.62-1,6.26,6.26,0,0,1,4.07,0,4.81,4.81,0,0,1,1.61,1A4.54,4.54,0,0,1,70,179.39a5.72,5.72,0,0,1,0,4.1A4.5,4.5,0,0,1,67.34,186a6.09,6.09,0,0,1-4.07,0,4.68,4.68,0,0,1-1.62-1,4.47,4.47,0,0,1-1-1.56,5.17,5.17,0,0,1-.38-2.05m2.15,0a3.43,3.43,0,0,0,.21,1.22,2.92,2.92,0,0,0,.59,1,2.73,2.73,0,0,0,.93.63,3.27,3.27,0,0,0,2.41,0A2.69,2.69,0,0,0,68,182.66a3.62,3.62,0,0,0,0-2.43,2.67,2.67,0,0,0-.59-1,2.6,2.6,0,0,0-.92-.63,3.27,3.27,0,0,0-2.41,0,2.73,2.73,0,0,0-.93.63,3,3,0,0,0-.59,1,3.42,3.42,0,0,0-.21,1.21" />
      <path className="cls-1" d="M71.54,179.68h1.92v.88h0a1.67,1.67,0,0,1,.26-.37,1.7,1.7,0,0,1,.42-.33,2.69,2.69,0,0,1,.54-.25,2.41,2.41,0,0,1,.67-.09,2.67,2.67,0,0,1,1.22.24,1.75,1.75,0,0,1,.73.64,2.67,2.67,0,0,1,.35,1,7.73,7.73,0,0,1,.09,1.21v3.57h-2V183c0-.18,0-.38,0-.58a2.44,2.44,0,0,0-.12-.55,1,1,0,0,0-.31-.41.92.92,0,0,0-.59-.16,1.28,1.28,0,0,0-.63.14.91.91,0,0,0-.36.37,1.5,1.5,0,0,0-.17.53,5.86,5.86,0,0,0,0,.61v3.22h-2Z" />
      <rect className="cls-1" x="79.21" y="176.09" width="2" height="10.06" />
      <path className="cls-1" d="M82.51,177.65a1.09,1.09,0,0,1,.34-.82,1.16,1.16,0,0,1,1.64,0,1.13,1.13,0,0,1,.34.82,1.16,1.16,0,0,1-2,.81,1.07,1.07,0,0,1-.34-.81m.16,2h2v6.47h-2Z" />
      <path className="cls-1" d="M86.11,179.68H88v.88h0a1.45,1.45,0,0,1,.27-.37,1.49,1.49,0,0,1,.41-.33,2.69,2.69,0,0,1,.54-.25,2.41,2.41,0,0,1,.67-.09,2.67,2.67,0,0,1,1.22.24,1.75,1.75,0,0,1,.73.64,2.67,2.67,0,0,1,.35,1,7.69,7.69,0,0,1,.1,1.21v3.57h-2V183c0-.18,0-.38,0-.58a1.69,1.69,0,0,0-.12-.55,1,1,0,0,0-.31-.41.92.92,0,0,0-.59-.16,1.28,1.28,0,0,0-.63.14.91.91,0,0,0-.36.37,1.5,1.5,0,0,0-.17.53,5.86,5.86,0,0,0,0,.61v3.22h-2Z" />
      <path className="cls-1" d="M99.87,185a3.15,3.15,0,0,1-1.21.95,3.68,3.68,0,0,1-1.52.33,4,4,0,0,1-1.4-.24,3.47,3.47,0,0,1-1.15-.68,3.2,3.2,0,0,1-.76-1.08,3.29,3.29,0,0,1-.28-1.39,3.33,3.33,0,0,1,.28-1.4,3.16,3.16,0,0,1,.76-1.07,3.33,3.33,0,0,1,1.15-.69,4,4,0,0,1,1.4-.24,3.22,3.22,0,0,1,1.26.24,2.65,2.65,0,0,1,1,.69,3.1,3.1,0,0,1,.6,1.07,4.37,4.37,0,0,1,.22,1.4v.62H95.54a1.6,1.6,0,0,0,.52.91,1.64,1.64,0,0,0,1.82.12,2.23,2.23,0,0,0,.59-.56Zm-1.69-2.85a1.15,1.15,0,0,0-.33-.86A1.19,1.19,0,0,0,97,181a1.34,1.34,0,0,0-.58.11,1.43,1.43,0,0,0-.43.27,1.08,1.08,0,0,0-.28.39,1.16,1.16,0,0,0-.12.45Z" />
      <path className="cls-1" d="M105.58,176.73h3.51a10.16,10.16,0,0,1,1.23.08,3.5,3.5,0,0,1,1.12.35,2.13,2.13,0,0,1,.79.73,2.16,2.16,0,0,1,.31,1.23,2,2,0,0,1-.45,1.33,2.43,2.43,0,0,1-1.17.75v0a2.5,2.5,0,0,1,.84.26,2.1,2.1,0,0,1,.65.49,2,2,0,0,1,.42.7,2.37,2.37,0,0,1,.15.85,2.26,2.26,0,0,1-.32,1.24,2.54,2.54,0,0,1-.82.81,3.81,3.81,0,0,1-1.14.44,6,6,0,0,1-1.26.13h-3.86Zm2.08,3.76h1.5a1.83,1.83,0,0,0,.47-.05,1.13,1.13,0,0,0,.42-.17.84.84,0,0,0,.3-.32.9.9,0,0,0,.11-.48.91.91,0,0,0-.12-.49,1,1,0,0,0-.33-.3,1.49,1.49,0,0,0-.45-.15,2.81,2.81,0,0,0-.49-.05h-1.41Zm0,3.9h1.86a2,2,0,0,0,.48,0,1.29,1.29,0,0,0,.45-.18,1.23,1.23,0,0,0,.33-.35,1.05,1.05,0,0,0,.12-.52.85.85,0,0,0-.16-.54,1,1,0,0,0-.42-.31,2.34,2.34,0,0,0-.55-.15,3.44,3.44,0,0,0-.54,0h-1.57Z" />
      <path className="cls-1" d="M118,185.34h0a2,2,0,0,1-.89.75,3.08,3.08,0,0,1-1.17.22,2.82,2.82,0,0,1-.87-.13,2.17,2.17,0,0,1-.74-.38,1.69,1.69,0,0,1-.5-.62,1.86,1.86,0,0,1-.19-.87,2,2,0,0,1,.21-.94,1.82,1.82,0,0,1,.56-.64,2.59,2.59,0,0,1,.8-.39,5.83,5.83,0,0,1,.94-.21c.32,0,.65-.07,1-.08h.9a1.05,1.05,0,0,0-.38-.85,1.4,1.4,0,0,0-.9-.31,1.89,1.89,0,0,0-.89.21,2.2,2.2,0,0,0-.73.56L114,180.56a3.93,3.93,0,0,1,1.31-.78,4.74,4.74,0,0,1,1.54-.26,4.21,4.21,0,0,1,1.45.22,2.08,2.08,0,0,1,.9.65,2.31,2.31,0,0,1,.47,1,6.72,6.72,0,0,1,.14,1.43v3.29H118Zm-.49-2c-.15,0-.34,0-.57,0a2.85,2.85,0,0,0-.65.11,1.41,1.41,0,0,0-.53.28.63.63,0,0,0-.22.52.57.57,0,0,0,.3.53,1.25,1.25,0,0,0,.64.17,1.79,1.79,0,0,0,.57-.08,2,2,0,0,0,.49-.22,1,1,0,0,0,.33-.38,1,1,0,0,0,.13-.53v-.42Z" />
      <path className="cls-1" d="M121.1,179.68H123v.88h0a1.45,1.45,0,0,1,.27-.37,1.49,1.49,0,0,1,.41-.33,2.54,2.54,0,0,1,.55-.25,2.29,2.29,0,0,1,.66-.09,2.69,2.69,0,0,1,1.23.24,1.73,1.73,0,0,1,.72.64,2.5,2.5,0,0,1,.35,1,6.66,6.66,0,0,1,.1,1.21v3.57h-2V183c0-.18,0-.38,0-.58a1.69,1.69,0,0,0-.12-.55.85.85,0,0,0-.9-.57,1.24,1.24,0,0,0-.62.14.94.94,0,0,0-.37.37,1.74,1.74,0,0,0-.17.53,5.93,5.93,0,0,0,0,.61v3.22h-2Z" />
      <polygon className="cls-1" points="128.74 176.09 130.74 176.09 130.74 182.24 132.95 179.68 135.39 179.68 132.82 182.6 135.46 186.15 132.95 186.15 130.76 182.88 130.74 182.88 130.74 186.15 128.74 186.15 128.74 176.09" />
      <path className="cls-1" d="M136,177.65a1.09,1.09,0,0,1,.34-.82,1.07,1.07,0,0,1,.81-.34,1.16,1.16,0,0,1,1.16,1.16,1.07,1.07,0,0,1-.34.81,1.09,1.09,0,0,1-.82.34,1.16,1.16,0,0,1-1.15-1.15m.16,2h2v6.47h-2Z" />
      <path className="cls-1" d="M139.6,179.68h1.92v.88h0a1.74,1.74,0,0,1,.27-.37,1.49,1.49,0,0,1,.41-.33,2.54,2.54,0,0,1,.55-.25,2.29,2.29,0,0,1,.66-.09,2.69,2.69,0,0,1,1.23.24,1.73,1.73,0,0,1,.72.64,2.68,2.68,0,0,1,.36,1,7.73,7.73,0,0,1,.09,1.21v3.57h-2V183c0-.18,0-.38,0-.58a1.69,1.69,0,0,0-.12-.55.85.85,0,0,0-.9-.57,1.26,1.26,0,0,0-.62.14,1,1,0,0,0-.37.37,1.49,1.49,0,0,0-.16.53,4,4,0,0,0,0,.61v3.22h-2Z" />
      <path className="cls-1" d="M154,185.61a3.81,3.81,0,0,1-.93,2.78,3.72,3.72,0,0,1-2.81,1,7.54,7.54,0,0,1-1.71-.2,3.88,3.88,0,0,1-1.54-.75l1.1-1.65a4.08,4.08,0,0,0,1,.61,2.78,2.78,0,0,0,1.13.23,1.8,1.8,0,0,0,1.35-.45A1.54,1.54,0,0,0,152,186v-.62h0a2,2,0,0,1-.86.66,2.77,2.77,0,0,1-1,.19,3.21,3.21,0,0,1-1.3-.25,2.84,2.84,0,0,1-1-.69,3,3,0,0,1-.64-1.06,3.92,3.92,0,0,1,0-2.59,3.3,3.3,0,0,1,.58-1.08,2.85,2.85,0,0,1,.93-.75,2.61,2.61,0,0,1,1.24-.29,2.78,2.78,0,0,1,.78.1,2.94,2.94,0,0,1,.63.24,2.36,2.36,0,0,1,.48.32,1.66,1.66,0,0,1,.31.36h0v-.86H154Zm-5-2.73a1.53,1.53,0,0,0,.12.6,1.57,1.57,0,0,0,.34.51,1.61,1.61,0,0,0,.5.35,1.45,1.45,0,0,0,.63.13,1.48,1.48,0,0,0,.63-.13,1.78,1.78,0,0,0,.51-.35,1.57,1.57,0,0,0,.34-.51,1.53,1.53,0,0,0,.12-.6,1.58,1.58,0,0,0-.12-.61,1.43,1.43,0,0,0-.34-.5,1.82,1.82,0,0,0-.51-.36,1.48,1.48,0,0,0-.63-.13,1.45,1.45,0,0,0-.63.13,1.64,1.64,0,0,0-.5.36,1.43,1.43,0,0,0-.34.5,1.58,1.58,0,0,0-.12.61" />
      <path className="cls-1" d="M159.82,176.73h3.5a6.74,6.74,0,0,1,1.38.13,3.07,3.07,0,0,1,1.14.46,2.27,2.27,0,0,1,.77.88,3,3,0,0,1,.29,1.39,3.14,3.14,0,0,1-.27,1.38,2.24,2.24,0,0,1-.73.9,3.13,3.13,0,0,1-1.1.47,6.75,6.75,0,0,1-1.39.14h-1.52v3.67h-2.07Zm2.07,4h1.39a3.29,3.29,0,0,0,.54-.05,1.58,1.58,0,0,0,.46-.18,1,1,0,0,0,.34-.35,1.12,1.12,0,0,0,.12-.55,1,1,0,0,0-.16-.58,1.1,1.1,0,0,0-.43-.34,1.7,1.7,0,0,0-.58-.15c-.21,0-.41,0-.61,0h-1.07Z" />
      <path className="cls-1" d="M172,185.34h0a1.89,1.89,0,0,1-.88.75,3.08,3.08,0,0,1-1.17.22,2.82,2.82,0,0,1-.87-.13,2.17,2.17,0,0,1-.74-.38,1.69,1.69,0,0,1-.5-.62,1.86,1.86,0,0,1-.19-.87,2,2,0,0,1,.21-.94,1.82,1.82,0,0,1,.56-.64,2.59,2.59,0,0,1,.8-.39,5.83,5.83,0,0,1,.94-.21c.32,0,.65-.07,1-.08h.9a1.05,1.05,0,0,0-.38-.85,1.4,1.4,0,0,0-.9-.31,2,2,0,0,0-.9.21,2.27,2.27,0,0,0-.72.56L168,180.56a3.93,3.93,0,0,1,1.31-.78,4.7,4.7,0,0,1,1.54-.26,4.21,4.21,0,0,1,1.45.22,2.08,2.08,0,0,1,.9.65,2.31,2.31,0,0,1,.47,1,6.72,6.72,0,0,1,.14,1.43v3.29H172Zm-.49-2c-.15,0-.34,0-.57,0a2.76,2.76,0,0,0-.65.11,1.41,1.41,0,0,0-.53.28.63.63,0,0,0-.22.52.57.57,0,0,0,.3.53,1.25,1.25,0,0,0,.64.17,1.79,1.79,0,0,0,.57-.08,2,2,0,0,0,.49-.22,1,1,0,0,0,.33-.38,1,1,0,0,0,.13-.53v-.42Z" />
      <path className="cls-1" d="M178.83,187.2c-.14.35-.27.65-.39.92a2.23,2.23,0,0,1-.47.67,2,2,0,0,1-.73.42,4,4,0,0,1-1.16.14,4.7,4.7,0,0,1-1.49-.24l.27-1.64a2.32,2.32,0,0,0,.93.2,1.65,1.65,0,0,0,.54-.07.81.81,0,0,0,.36-.22,1.07,1.07,0,0,0,.23-.34c.06-.13.13-.28.2-.46l.15-.38-2.84-6.52h2.16l1.66,4.28h0l1.42-4.28h2.05Z" />
      <path className="cls-1" d="M182.5,179.68h1.92v.88h0a1.45,1.45,0,0,1,.27-.37,1.65,1.65,0,0,1,.41-.33,2.54,2.54,0,0,1,.55-.25,2.29,2.29,0,0,1,.66-.09,2.49,2.49,0,0,1,1.21.28,1.8,1.8,0,0,1,.79.88,2.14,2.14,0,0,1,.84-.89,2.49,2.49,0,0,1,1.21-.27,2.6,2.6,0,0,1,1.1.22,1.87,1.87,0,0,1,.7.6,2.47,2.47,0,0,1,.38.89,5.54,5.54,0,0,1,.11,1.1v3.82h-2v-3.76a1.46,1.46,0,0,0-.19-.78.73.73,0,0,0-.69-.33,1.43,1.43,0,0,0-.58.11,1,1,0,0,0-.37.32,1.29,1.29,0,0,0-.2.46,2.6,2.6,0,0,0-.06.56v3.42h-2v-3.42c0-.11,0-.26,0-.42a1.86,1.86,0,0,0-.09-.48.87.87,0,0,0-.26-.39.76.76,0,0,0-.53-.16,1.26,1.26,0,0,0-.62.14,1,1,0,0,0-.37.37,1.74,1.74,0,0,0-.17.53,5.93,5.93,0,0,0,0,.61v3.22h-2Z" />
      <path className="cls-1" d="M200.2,185A3.15,3.15,0,0,1,199,186a3.65,3.65,0,0,1-1.52.33,4.08,4.08,0,0,1-1.4-.24,3.57,3.57,0,0,1-1.15-.68,3.35,3.35,0,0,1-.76-1.08,3.29,3.29,0,0,1-.28-1.39,3.28,3.28,0,0,1,1-2.47,3.43,3.43,0,0,1,1.15-.69,4.08,4.08,0,0,1,1.4-.24,3.18,3.18,0,0,1,1.26.24,2.57,2.57,0,0,1,1,.69,3.29,3.29,0,0,1,.61,1.07,4.38,4.38,0,0,1,.21,1.4v.62h-4.63a1.54,1.54,0,0,0,.52.91,1.43,1.43,0,0,0,1,.34,1.45,1.45,0,0,0,.83-.22,2,2,0,0,0,.59-.56Zm-1.69-2.85a1.15,1.15,0,0,0-.33-.86,1.18,1.18,0,0,0-.89-.36,1.38,1.38,0,0,0-.59.11,1.43,1.43,0,0,0-.43.27,1.21,1.21,0,0,0-.28.39,1.15,1.15,0,0,0-.11.45Z" />
      <path className="cls-1" d="M201.72,179.68h1.92v.88h0a1.45,1.45,0,0,1,.27-.37,1.49,1.49,0,0,1,.41-.33,2.54,2.54,0,0,1,.55-.25,2.29,2.29,0,0,1,.66-.09,2.69,2.69,0,0,1,1.23.24,1.73,1.73,0,0,1,.72.64,2.68,2.68,0,0,1,.36,1,7.73,7.73,0,0,1,.09,1.21v3.57h-2V183c0-.18,0-.38,0-.58a1.69,1.69,0,0,0-.12-.55.85.85,0,0,0-.9-.57,1.24,1.24,0,0,0-.62.14.94.94,0,0,0-.37.37,1.49,1.49,0,0,0-.16.53,4,4,0,0,0,0,.61v3.22h-2Z" />
      <path className="cls-1" d="M213.77,181.28H212v2.16c0,.17,0,.34,0,.48a1.06,1.06,0,0,0,.12.38.6.6,0,0,0,.29.25,1.24,1.24,0,0,0,.51.08l.42,0a.73.73,0,0,0,.4-.15v1.66a2.84,2.84,0,0,1-.7.16,5.58,5.58,0,0,1-.7,0,4,4,0,0,1-.94-.11,2.17,2.17,0,0,1-.74-.33,1.66,1.66,0,0,1-.5-.61,2.08,2.08,0,0,1-.18-.91v-3.07h-1.28v-1.6H210v-1.91h2v1.91h1.76Z" />
      <path className="cls-1" d="M218.44,181.58a1.43,1.43,0,0,0-1.16-.54,1.07,1.07,0,0,0-.49.12.42.42,0,0,0-.24.41.38.38,0,0,0,.24.36,4.29,4.29,0,0,0,.63.2l.81.18a3.16,3.16,0,0,1,.81.31,2.05,2.05,0,0,1,.63.58,1.78,1.78,0,0,1,.24,1,1.81,1.81,0,0,1-.27,1,2,2,0,0,1-.7.65,2.92,2.92,0,0,1-.95.35,5.51,5.51,0,0,1-1.06.1,5,5,0,0,1-1.37-.19,2.57,2.57,0,0,1-1.17-.69l1.21-1.34a1.87,1.87,0,0,0,.62.52,1.67,1.67,0,0,0,.79.18,1.81,1.81,0,0,0,.63-.1.35.35,0,0,0,.28-.36.4.4,0,0,0-.25-.38,2.46,2.46,0,0,0-.62-.22l-.82-.18a3.74,3.74,0,0,1-.81-.3,1.61,1.61,0,0,1-.62-.57,1.66,1.66,0,0,1-.25-1,2,2,0,0,1,.23-1,2.12,2.12,0,0,1,.62-.68,2.66,2.66,0,0,1,.87-.39,4.22,4.22,0,0,1,1-.13,4.53,4.53,0,0,1,1.29.19,2.29,2.29,0,0,1,1.1.67Z" />
      <polygon className="cls-1" points="118.57 117.39 144.55 117.39 144.55 125.26 127.1 125.26 127.1 133.14 143.24 133.14 143.24 141.01 127.1 141.01 127.1 156.11 118.57 156.11 118.57 117.39" />
      <path className="cls-1" d="M150.68,117.39h14.38a28.67,28.67,0,0,1,5.68.54,13.08,13.08,0,0,1,4.68,1.89,9.51,9.51,0,0,1,3.17,3.61,12.28,12.28,0,0,1,1.18,5.72,13.13,13.13,0,0,1-1.1,5.68,9.3,9.3,0,0,1-3,3.67,12.2,12.2,0,0,1-4.54,1.94,26.83,26.83,0,0,1-5.69.57h-6.23v15.1h-8.53Zm8.53,16.4h5.69a11.43,11.43,0,0,0,2.21-.21,6.08,6.08,0,0,0,1.91-.74,4,4,0,0,0,1.37-1.42,4.49,4.49,0,0,0,.52-2.27,3.8,3.8,0,0,0-.69-2.38,4.43,4.43,0,0,0-1.74-1.4,7.52,7.52,0,0,0-2.38-.63,24.75,24.75,0,0,0-2.52-.13h-4.37Z" />
      <polygon className="cls-1" points="194.75 135.87 181.96 117.39 192.67 117.39 200.93 130.95 208.86 117.39 219.2 117.39 206.67 135.71 220.67 156.11 209.95 156.11 200.5 140.52 191.31 156.11 181.19 156.11 194.75 135.87" />
      <path className="cls-2" d="M81.21,148.84l-5.4-7.31c-.8-1.09-2.18-2.52-2.18-3.95s1.37-2.87,2.18-4l5.4-7.32a4.11,4.11,0,0,1,.75-.79v-8.42c0-6.77-2.83-8.47-6.29-3.79L64,129.06c-1.73,2.35-4.69,5.43-4.69,8.52s3,6.17,4.69,8.52l11.65,15.75c3.46,4.68,6.29,3,6.29-3.78v-8.43a3.67,3.67,0,0,1-.75-.8" />
      <path className="cls-1" d="M104.09,129.06,92.44,113.3c-3.46-4.69-6.29-3-6.29,3.79v8.42a3.82,3.82,0,0,1,.74.79l5.41,7.32c.81,1.09,2.18,2.53,2.18,4s-1.37,2.86-2.18,3.95l-5.41,7.31a4,4,0,0,1-.74.81v8.42c0,6.78,2.83,8.46,6.29,3.78l11.65-15.75c1.73-2.36,4.69-5.45,4.69-8.53s-3-6.16-4.69-8.51" />
    </svg>
  );
};

export const CreditCardIcon = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props}>
      <g id="Group_7304" data-name="Group 7304" transform="translate(-980 -398)">
        <g id="Group_7311" data-name="Group 7311">
          <g id="credit-card" transform="translate(1072.868 365.9)">
            <path id="Path_1935" data-name="Path 1935" d="M84.451,60.443a6.416,6.416,0,0,0-4.393-2.265L19.51,53.122A6.457,6.457,0,0,0,12.54,59L11.322,70.428H6.458A6.465,6.465,0,0,0,0,76.885v37.9a6.465,6.465,0,0,0,6.458,6.458H67.215a6.465,6.465,0,0,0,6.458-6.458v-6.148l2.156.18q.273.023.542.023a6.466,6.466,0,0,0,6.431-5.921l3.154-37.77a6.416,6.416,0,0,0-1.505-4.708ZM6.458,73.786H67.215a3.1,3.1,0,0,1,3.1,3.1v3.454H3.359V76.885a3.1,3.1,0,0,1,3.1-3.1ZM3.359,83.7H70.315v6.967H3.359Zm63.857,34.188H6.458a3.1,3.1,0,0,1-3.1-3.1V94.023H70.315v20.764A3.1,3.1,0,0,1,67.215,117.886ZM82.609,64.871l-3.154,37.77a3.1,3.1,0,0,1-3.346,2.831l-2.435-.2V76.885a6.465,6.465,0,0,0-6.458-6.458H14.7l1.182-11.09c0-.013,0-.025,0-.038a3.1,3.1,0,0,1,3.346-2.83l60.547,5.056a3.1,3.1,0,0,1,2.831,3.346Z" fill={primaryColor} />
            <path id="Path_1936" data-name="Path 1936" d="M288.472,326.532H272.31a1.679,1.679,0,0,0-1.679,1.679v10.544a1.679,1.679,0,0,0,1.679,1.679h16.162a1.679,1.679,0,0,0,1.679-1.679V328.211A1.679,1.679,0,0,0,288.472,326.532Zm-1.679,10.544h-12.8v-7.185h12.8Z" transform="translate(-225.185 -227.516)" fill={primaryColor} />
          </g>
          <text id="Credit_Card" data-name="Credit Card" transform="translate(1116 502)" fill="#001847" fontSize="14" fontFamily="Poppins-Regular, Poppins"><tspan x="-40.705" y="0">Credit Card</tspan></text>
        </g>
      </g>
    </svg>

  );
};

export const RaiseCoinIcon = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 280 300">
      <g id="svgg" transform="translate(-980 -398)">
        <g id="svgg" transform="translate(1072.868 400.9)">
          <path
            id="path0"
            className="st0"
            d="M41,10.3c-1.3,0.1-3.3,0.3-4.1,0.4c-1.1,0.2-1.3,0.3-1.6,0.3c-0.1,0-0.4,0.1-0.5,0.1
		c-0.1,0-0.2,0-0.3,0.1c0,0-0.2,0.1-0.3,0.1c-0.1,0-0.3,0-0.3,0.1c0,0-0.2,0.1-0.3,0.1c-0.1,0-0.3,0-0.3,0.1c0,0-0.2,0.1-0.3,0.1
		c-0.1,0-0.3,0-0.3,0.1c0,0-0.1,0.1-0.3,0.1c-0.1,0-0.3,0-0.3,0.1c0,0-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.3,0.1c0,0-0.1,0.1-0.2,0.1
		c-0.1,0-0.2,0-0.3,0.1c-0.2,0.1-0.6,0.3-0.8,0.4c-0.6,0.2-3,1.3-3.5,1.7c-0.2,0.1-0.5,0.3-0.7,0.4c-0.2,0.1-0.4,0.3-0.5,0.3
		c-0.1,0-0.1,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1c-0.1,0-0.1,0-0.1,0.1c0,0-0.1,0.1-0.1,0.1c-0.1,0-0.1,0-0.1,0.1c0,0-0.1,0.1-0.3,0.2
		c-0.1,0.1-0.3,0.1-0.3,0.2c0,0-0.1,0.1-0.2,0.2c-0.1,0.1-0.3,0.2-0.4,0.2c-0.1,0.1-0.6,0.4-1.1,0.8c-0.5,0.4-1,0.8-1.1,0.8
		c-0.1,0.1-0.3,0.2-0.4,0.4c-1.1,0.9-3.7,3.6-5,5.5c-0.3,0.5-0.7,0.9-0.7,0.9c0,0-0.1,0.1-0.1,0.1s-0.1,0.2-0.1,0.3
		c-0.1,0.2-0.2,0.3-0.5,0.7c-0.1,0.1-0.1,0.2-0.1,0.2c-0.1,0.1-1.7,3.3-2,3.9c-0.2,0.4-0.3,0.7-0.3,0.8c0,0.1-0.1,0.2-0.1,0.3
		s0,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.2s0,0.2-0.1,0.3s-0.1,0.1-0.1,0.3s0,0.2-0.1,0.2c0,0-0.1,0.1-0.1,0.2c0,0.1,0,0.2-0.1,0.3
		s-0.1,0.2-0.1,0.3s0,0.3-0.1,0.3c0,0-0.1,0.1-0.1,0.3c0,0.1,0,0.2-0.1,0.2c0,0-0.1,0.2-0.1,0.3c-0.1,0.5-0.2,0.8-0.2,1
		c0,0.1-0.1,0.3-0.1,0.5S11,35.8,10.9,36c-0.8,3.8-0.8,9.2,0,12.9c0,0.2,0.1,0.6,0.1,0.8c0,0.2,0.1,0.4,0.1,0.4c0,0,0.1,0.2,0.1,0.3
		c0,0.2,0,0.3,0.1,0.3c0,0,0.1,0.2,0.1,0.3c0,0.2,0,0.3,0.1,0.3c0,0,0.1,0.1,0.1,0.3c0,0.1,0,0.2,0.1,0.3c0,0,0.1,0.1,0.1,0.3
		s0,0.2,0.1,0.3c0,0,0.1,0.1,0.1,0.3c0,0.1,0,0.2,0.1,0.3c0,0,0.1,0.1,0.1,0.2c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.2,0.4,0.3,0.8
		c0.3,0.8,0.4,0.9,1.1,2.4c0.7,1.4,1,2,1.3,2.4c0.1,0.1,0.2,0.3,0.2,0.3c0,0.1,1,1.5,1.2,1.7c0.1,0.1,0.3,0.4,0.6,0.7
		c0.6,0.8,0.9,1.2,1.2,1.6c0.2,0.2,0.4,0.5,0.6,0.7c0.3,0.3,1.3,1.3,3,3c0.2,0.2,0.3,0.3,0.3,0.3c0,0,0.3,0.2,0.7,0.5
		c0.7,0.6,3,2.1,3.1,2.1c0.1,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.3,0.2s0.3,0.1,0.3,0.2s0.1,0.1,0.1,0.1c0.1,0,0.4,0.2,0.7,0.3
		c0.3,0.2,0.6,0.3,0.7,0.4c0.2,0.1,1.9,0.9,2.6,1.1c0.1,0,0.3,0.1,0.4,0.2c0.1,0,0.3,0.1,0.5,0.2c0.2,0.1,0.4,0.1,0.4,0.2
		c0,0,0.3,0.1,0.5,0.2s0.4,0.1,0.5,0.2s0.2,0.1,0.3,0.1s0.2,0,0.2,0.1c0,0,0.2,0.1,0.3,0.1c0.1,0,0.3,0,0.3,0.1c0,0,0.2,0.1,0.3,0.1
		c0.2,0,0.3,0,0.3,0.1c0,0,0.2,0.1,0.4,0.1c0.2,0,0.5,0.1,0.7,0.1c1.1,0.3,1.6,0.3,3.3,0.5c1.3,0.1,5.7,0.1,6.9,0
		c0.5-0.1,1.1-0.1,1.4-0.2c0.3,0,0.5-0.1,0.6-0.1c0.1,0,0.3-0.1,0.5-0.1c0.2,0,0.5-0.1,0.8-0.1c0.2-0.1,0.6-0.1,0.7-0.1
		c0.2,0,0.3-0.1,0.4-0.1c0,0,0.2-0.1,0.3-0.1c0.2,0,0.3,0,0.3-0.1c0,0,0.2-0.1,0.3-0.1s0.3,0,0.3-0.1c0,0,0.1-0.1,0.2-0.1
		s0.2,0,0.3-0.1c0.1,0,0.3-0.1,0.5-0.2c0.2-0.1,0.4-0.1,0.5-0.2c0,0,0.2-0.1,0.4-0.2c0.2-0.1,0.4-0.1,0.6-0.2s0.3-0.1,0.4-0.2
		c0.1,0,0.3-0.1,0.5-0.2c0.2-0.1,0.5-0.2,0.7-0.3c0.9-0.4,2.8-1.3,2.9-1.5c0.1-0.1,0.6-0.4,0.7-0.4c0.1,0,0.1-0.1,0.1-0.1
		c0,0,0-0.1,0.1-0.1c0.1,0,1.8-1.1,2.8-1.9c2.4-1.8,5.1-4.7,6.5-6.8c0.2-0.3,0.4-0.5,0.4-0.6c0,0,0.1-0.1,0.2-0.3
		c0.1-0.1,0.2-0.2,0.2-0.2c0,0,0.1,0,0.1-0.1c0,0,0.1-0.3,0.3-0.5c0.1-0.2,0.3-0.4,0.3-0.5c0,0,0-0.1,0.1-0.1c0.1,0,0.4-0.5,0.4-0.7
		c0-0.1,0.1-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.1,0,1-2,1.3-2.7c0-0.1,0.1-0.3,0.2-0.6c0.1-0.2,0.2-0.5,0.3-0.7
		c0.1-0.2,0.1-0.4,0.2-0.4c0,0,0.1-0.3,0.2-0.5c0.1-0.2,0.1-0.4,0.2-0.5c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.2,0.1-0.2
		c0,0,0.1-0.2,0.1-0.3s0-0.3,0.1-0.3c0,0,0.1-0.2,0.1-0.3c0-0.1,0-0.3,0.1-0.3c0,0,0.1-0.2,0.1-0.3c0-0.2,0.1-0.5,0.1-0.7
		c0.5-2.5,0.8-4.5,0.8-7.1c0-2.2-0.1-3.5-0.4-5.5c0-0.3-0.1-0.7-0.1-0.9c0-0.2-0.1-0.4-0.1-0.5c-0.1-0.2-0.1-0.5-0.2-1.1
		c0-0.2-0.1-0.3-0.1-0.4c0,0-0.1-0.2-0.1-0.3s0-0.3-0.1-0.3c0,0-0.1-0.2-0.1-0.3c0-0.1,0-0.3-0.1-0.3c0,0-0.1-0.1-0.1-0.3
		s0-0.2-0.1-0.3c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2-0.1-0.3c-0.1-0.2-0.2-0.5-0.4-0.9c-0.1-0.2-0.2-0.5-0.3-0.7
		c-0.1-0.2-0.2-0.4-0.2-0.4c0,0-0.1-0.2-0.2-0.4c-0.1-0.2-0.4-0.9-0.7-1.5c-0.3-0.7-0.6-1.2-0.6-1.2c0,0-0.1-0.1-0.1-0.2
		c0-0.1,0-0.2-0.1-0.2c0,0-0.1,0-0.1-0.1c0,0-0.1-0.3-0.3-0.5s-0.3-0.4-0.3-0.5s0-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1
		c0-0.1-0.1-0.2-0.1-0.3c-0.1-0.1-0.2-0.3-0.3-0.4c-0.8-1.4-3.9-5-5.3-6.1c-0.2-0.2-0.5-0.4-0.7-0.6c-0.2-0.2-0.4-0.3-0.5-0.4
		c-0.1-0.1-0.5-0.4-0.8-0.6c-0.4-0.3-0.9-0.6-1.1-0.8c-0.2-0.2-0.5-0.3-0.5-0.4c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1,0-0.2-0.1-0.2-0.1
		s0-0.1-0.1-0.1c0,0-0.3-0.1-0.5-0.3c-0.2-0.1-0.4-0.3-0.4-0.3c0,0-0.2-0.1-0.4-0.2c-0.3-0.2-2.6-1.4-3.3-1.7
		c-0.2-0.1-0.5-0.2-0.6-0.3c-0.2-0.1-0.4-0.2-0.6-0.2c-0.1,0-0.3-0.1-0.4-0.2c-0.1,0-0.2-0.1-0.3-0.1s-0.2,0-0.3-0.1
		c-0.1,0-0.2-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1s-0.2,0-0.3-0.1c0,0-0.1-0.1-0.3-0.1s-0.2,0-0.3-0.1c0,0-0.2-0.1-0.3-0.1
		s-0.3,0-0.3-0.1c0,0-0.2-0.1-0.3-0.1c-0.2,0-0.4-0.1-0.6-0.1C47.4,10.4,44,10.1,41,10.3 M60.7,27.7c0,0.1-1.9,2-5.3,5.4
		c-4.8,4.9-9.5,9.7-9.5,9.8c0,0,1.4,1.4,3.1,3.1c1.7,1.7,5.9,5.8,9.3,9.2c3.4,3.4,6.4,6.4,6.8,6.7c1,1,1.4,0.9-4.3,0.9h-4.9
		l-0.6-0.5C53.8,60.9,35.8,43,35.8,42.9c0,0,1.2-1.3,2.6-2.8c3.5-3.5,5.1-5.2,5.1-5.3c0,0-5.9,0-13.2,0c-9.3,0-13.2,0-13.2-0.1
		c-0.1-0.1,0.9-1.1,3.4-3.6l3.5-3.5l5.4,0C39.9,27.6,60.7,27.6,60.7,27.7 M30.8,40.9c19.5,19.3,21.9,21.8,21.9,21.9
		c0,0.1-1.1,0.1-5,0.1h-5l-5.1-5.1C34.8,55,30.8,51,28.8,49c-8.1-8.1-11.6-11.6-11.6-11.7c0-0.1,0.7-0.1,4.9-0.1H27L30.8,40.9"
            fill="white"
          />
          <path
            id="path1"
            className="st1"
            d="M39.3,0c-0.1,0-0.5,0.1-0.9,0c-0.4,0-0.7,0-0.7,0s-0.3,0.1-0.6,0.1c-0.6,0-0.7,0-0.8,0.1
		c0,0-0.3,0.1-0.6,0.1s-0.6,0.1-0.6,0.1c0,0-0.2,0.1-0.3,0.1c-0.2,0-0.3,0.1-0.3,0.1c0,0-0.2,0.1-0.5,0.1s-0.5,0-0.5,0.1
		c0,0-0.1,0.1-0.2,0.1c-0.2,0-0.9,0.2-1.1,0.3c0,0-0.3,0.1-0.5,0.1c-0.2,0-0.4,0.1-0.5,0.1c-0.1,0-0.2,0.1-0.4,0.2s-0.3,0.1-0.4,0.2
		c-0.1,0-0.3,0.1-0.5,0c-0.2,0-0.3,0-0.2,0c0.2,0.1,0,0.2-0.1,0.1c-0.1,0-0.2,0-0.3,0.1c-0.1,0.1-0.1,0.1-0.1,0c0-0.1,0-0.1-0.1,0
		c-0.1,0.1-0.2,0.1-0.2,0.1s-0.1,0-0.1,0c-0.1,0.1-0.2,0.1-0.3,0.1c-0.1,0-0.2,0-0.2,0.1c0,0.1-0.1,0.1-0.1,0.1c-0.1,0-0.1,0,0-0.1
		c0-0.1,0,0-0.1,0.1c-0.1,0.1-0.3,0.2-0.5,0.2c-0.2,0-0.3,0-0.3,0.1c0,0.1-0.6,0.3-0.8,0.3c-0.1,0-0.1,0-0.2,0.1
		c0,0.1-0.1,0.2-0.1,0.2c-0.2,0-0.4,0-0.4,0.1c0,0,0,0.1-0.1,0.1c-0.1,0-0.3,0.1-0.4,0.1c-0.1,0.1-0.2,0.1-0.1,0c0-0.1,0-0.1-0.1,0
		c-0.1,0.1-0.1,0.1-0.2,0c-0.1-0.1-0.1-0.1-0.1,0c0,0.1,0,0.1-0.1,0.1c-0.1,0-0.1,0-0.1,0.1s0,0.1-0.1,0.1c-0.2,0-0.4,0-0.3,0.1
		c0,0,0,0.1,0,0.1c-0.2,0-0.2,0-0.5,0.2c-0.2,0.1-0.3,0.2-0.3,0.2c0,0,0,0.1-0.1,0c0,0-0.1,0-0.2,0c-0.1,0.1-0.1,0.1-0.1,0
		s-0.1,0-0.2,0.1s-0.3,0.2-0.4,0.2c-0.1,0-0.1,0-0.1,0.1c0,0-0.1,0.1-0.1,0c-0.1,0-0.1,0-0.1,0.1c0,0.1,0,0.1-0.1,0.1
		c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0.1-0.1,0.1-0.2,0.1c0,0-0.1,0-0.1,0.1c0,0.1-0.1,0.1-0.1,0.1c0,0-0.1,0-0.2,0.1
		c-0.1,0.2-0.1,0.2-0.2,0c0-0.1,0-0.1-0.1,0c0,0.1-0.1,0.2-0.1,0.2c-0.1,0-0.1,0-0.1,0c0,0,0-0.1,0-0.1c0,0-0.1,0.1-0.1,0.2
		c0,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0-0.2,0.1c0.1,0.1-0.4,0.4-0.5,0.4c0,0-0.1,0-0.1,0.1c0,0.1,0,0.1,0,0c0-0.1,0-0.1-0.1-0.1
		c-0.1,0-0.1,0-0.1,0.1S19.1,7,19,7c-0.2,0.2-0.3,0.2-0.2,0.1c0-0.1,0-0.1,0,0c-0.1,0-0.1,0.1-0.1,0.2c0,0.1-0.1,0.1-0.1,0.1
		c-0.1,0-0.2,0.1-0.2,0.2c-0.1,0.1-0.2,0.2-0.2,0.2s-0.2,0.1-0.2,0.1c-0.1,0.1-0.2,0.1-0.2,0.1c-0.1,0-0.1,0,0,0
		C17.6,8,17.2,8.3,17,8.3c-0.1,0-0.1,0-0.1,0.1c0,0.1,0,0.1-0.1,0.1c-0.1,0-0.2,0-0.1,0c0.1,0.1-0.3,0.4-0.4,0.3c0,0-0.1,0-0.1,0
		c0,0.1,0,0.1,0,0c0.1,0,0.1,0,0.1,0s-0.1,0.1-0.1,0.1c-0.1,0-0.1,0-0.1,0.1c0,0-0.1,0.1-0.1,0.1c-0.1,0-0.1,0.1-0.2,0.2
		c0,0.1-0.1,0.2-0.1,0.1c0,0-0.1,0-0.2,0.1c-0.1,0.1-0.1,0.1-0.1,0c0-0.1,0-0.1,0-0.1c-0.1,0-0.1,0.1-0.1,0.2c0,0.1-0.4,0.5-0.5,0.5
		c0,0-0.5,0.4-1.1,1c-0.2,0.2-0.4,0.3-0.4,0.3c0,0-0.1,0-0.1,0.1c0,0.1-0.5,0.6-0.6,0.6c-0.1,0-0.1,0.1-0.1,0.1
		c0,0.1-0.1,0.2-0.2,0.2c-0.1,0-0.2,0.1-0.2,0.2c0,0.1-0.4,0.6-0.6,0.6c-0.1,0-0.1,0-0.1,0.1c0,0-0.1,0.2-0.3,0.4
		c-0.7,0.7-1,1.1-1,1.2c0,0,0,0.1-0.1,0.1c0,0-0.1,0-0.1,0.1s0,0.1-0.1,0.1c-0.2,0-0.4,0.2-0.4,0.5c0,0.2,0,0.2-0.1,0.1
		c-0.1-0.1-0.1-0.1-0.1,0c0,0.1-0.1,0.2-0.2,0.3C9,16.2,9,16.2,9,16.1c0.1-0.2,0-0.2-0.1,0.1c-0.1,0.1-0.1,0.1,0,0.1
		c0.1,0,0.1,0,0.1,0c0,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.1,0.1-0.1,0.2c0,0.1,0,0.1-0.1,0.1c-0.1,0-0.2,0-0.2,0.1c0,0.1,0,0.1,0.1,0.1
		c0.1,0,0,0-0.1,0.1c-0.1,0.1-0.1,0.2-0.1,0.2c0,0,0,0.1,0,0.1c0,0-0.1,0-0.1,0.1c0,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.3,0.3-0.3,0.4
		c0,0.1-0.1,0.2-0.2,0.2c-0.1,0-0.1,0,0,0.1c0.1,0.1,0.1,0.1-0.1,0.1c-0.2,0-0.2,0-0.1,0.1c0.1,0.1,0.1,0.1-0.1,0.2
		C7.1,18.9,7,19,7,19c0,0.1-0.4,0.5-0.5,0.4c0,0,0,0,0,0.1c0.1,0.2-0.1,0.5-0.3,0.4c0,0-0.1,0-0.1,0c0,0.2,0,0.5-0.2,0.6
		c-0.1,0.1-0.1,0.2-0.1,0.2c0,0,0,0.1-0.1,0c-0.1,0-0.1,0-0.1,0.1c0,0.3-0.1,0.5-0.2,0.4c0,0-0.1,0,0,0.1c0,0.1,0,0.1,0,0.1
		c-0.1,0-0.2,0.3-0.2,0.4c0,0-0.1,0.1-0.1,0.1c-0.1,0-0.1,0,0,0.1c0.1,0.1,0.1,0.1,0,0.2c-0.1,0-0.2,0.1-0.2,0.2
		c0,0.1-0.1,0.2-0.2,0.4s-0.1,0.3-0.1,0.3c0,0,0,0.1-0.1,0.1c-0.1,0-0.1,0.1-0.1,0.3c0,0.1,0,0.2-0.1,0.2c0,0-0.1,0.1-0.1,0.1
		c0,0.1,0,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.2s0,0.2-0.1,0.2c0,0-0.1,0.1-0.1,0.1c0,0.1,0,0.1-0.1,0.1s-0.1,0-0.1,0.1
		c0,0.2,0,0.4-0.1,0.3c0,0-0.1,0-0.1,0c0,0.2,0,0.4-0.1,0.3c-0.1-0.1-0.1,0,0,0.1c0.1,0.1,0,0.4-0.2,0.3c0,0,0,0,0,0
		c0,0,0,0.2,0,0.3c0,0.1-0.1,0.3-0.1,0.4c0,0.1-0.1,0.1-0.1,0.1c-0.1,0-0.1,0.1-0.1,0.2c0,0.1,0,0.3-0.1,0.4
		c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.1,0,0.1-0.1,0c-0.1,0-0.1,0-0.1,0.1c0.1,0.2-0.1,0.9-0.3,1c-0.1,0.1-0.1,0.2-0.1,0.3
		c0,0.1,0,0.2-0.1,0.3C2,29.2,2,29.3,2,29.4c0,0.1,0,0.2-0.1,0.2c-0.1,0-0.1,0.1-0.1,0.3c0,0.2,0,0.3-0.1,0.3c-0.1,0-0.1,0-0.1,0.2
		c0,0.1,0,0.3-0.1,0.3c0,0-0.1,0.2-0.1,0.3c0,0.2,0,0.3-0.1,0.3s-0.1,0.1-0.1,0.3c0,0.1,0,0.3-0.1,0.4c0,0.1-0.1,0.3-0.1,0.4
		c0,0.1,0,0.2-0.1,0.2c-0.1,0-0.2,0.4-0.2,1.1c0,0.3-0.1,0.5-0.1,0.5c0,0-0.1,0.2-0.1,0.4c0,0.2,0,0.3-0.1,0.3c0,0-0.1,0.3-0.1,0.6
		s0,0.5-0.1,0.5c0,0-0.1,0.2-0.1,0.6c0,0.3-0.1,0.6-0.1,0.6c0,0-0.1,0.3-0.1,0.6c0,0.4,0,0.6-0.1,0.6c-0.1,0-0.1,0,0,0.1
		c0.1,0,0.1,0.1,0,0.2c-0.2,0.3-0.2,7.4,0,7.9c0,0.1,0.1,0.5,0.1,0.8c0,0.3,0.1,0.6,0.1,0.7c0,0,0.1,0.4,0.1,0.7
		c0,0.4,0,0.6,0.1,0.6c0,0,0.1,0.1,0.1,0.2c0,0.3,0,0.5,0.1,0.9c0,0.2,0.1,0.4,0.1,0.5c0,0,0,0.1,0.1,0.1c0,0,0.1,0.2,0.1,0.5
		c0,0.2,0.1,0.4,0.1,0.5c0,0,0.1,0.1,0.1,0.3c0,0.1,0,0.2,0.1,0.2s0.1,0.1,0.1,0.3s0,0.3,0.1,0.3c0,0,0.1,0.1,0.1,0.2
		c0,0.1,0,0.1-0.1,0c-0.1-0.1-0.1-0.1-0.1,0c0,0.1,0,0.2,0.1,0.2c0.1,0.1,0.1,0.2,0.1,0.2c0,0.1,0,0.1,0,0.1c0,0,0.1,0.1,0.1,0.3
		c0,0.2,0.1,0.3,0.1,0.3s0.1,0.2,0,0.3c0,0.2,0,0.3,0,0.2c0.1-0.2,0.2,0,0.2,0.3c0,0.2,0,0.3,0.1,0.3c0,0,0.1,0,0.1,0
		c0,0.2,0,0.2,0.2,0.5c0.1,0.2,0.1,0.3,0.1,0.4c-0.1,0.1-0.1,0.1,0,0.1c0.1,0,0.1,0,0.1,0.3c0,0.2,0,0.3,0.1,0.2
		c0,0,0.1,0.1,0.1,0.2c0,0.1,0.1,0.2,0.1,0.2c0,0,0.1,0.1,0.1,0.2c0,0.1,0,0.2,0.1,0.2c0.1,0,0.1,0.2,0.1,0.3c0,0,0,0.1,0.1,0.1
		S3,58.3,3,58.4c0,0.1,0,0.2,0.1,0.2c0.1,0,0.1,0.1,0.1,0.3c0,0.1,0.1,0.2,0.1,0.2c0.1,0,0.1,0,0.1,0.1c0,0.1,0,0.1,0,0.1
		c0,0,0.1,0.1,0.1,0.2c0,0.1,0.1,0.2,0.1,0.2c0,0,0.1,0.1,0.1,0.3c0,0.1,0.1,0.2,0.1,0.2c0,0,0.1,0.1,0.1,0.1c0,0.1,0,0.1,0.1,0.1
		c0,0,0.1,0,0.1,0.1c0,0.2,0.2,0.6,0.3,0.6c0,0,0.1,0.1,0.1,0.1c0,0.1,0,0.1,0.1,0.1s0.1,0.1,0.1,0.2c0,0.2,0.2,0.6,0.3,0.6
		c0,0,0.1,0.1,0.1,0.3c0,0.2,0.1,0.3,0.2,0.3c0.1,0,0.1,0.1,0.1,0.2c0,0.1,0,0.2,0.1,0.1c0.1,0,0.1,0,0.1,0.1c0,0.1,0,0.1,0.1,0.1
		c0.1,0,0.1,0.1,0.1,0.2c0,0.1,0,0.2,0,0.2c0.1-0.1,0.4,0.3,0.3,0.4c0,0.1,0,0.1,0.1,0.1c0.1,0,0.2,0.1,0.2,0.2
		c0.1,0.1,0.2,0.3,0.2,0.3c0,0,0,0.1,0,0.2c0,0.1,0,0.1,0.1,0.1c0,0,0.1,0,0.2,0.2c0.1,0.1,0.1,0.2,0.2,0.2c0.1,0,0.1,0.1,0.1,0.4
		c0,0.1,0.1,0.1,0.2,0.1c0.1,0,0.1,0,0.1,0.1c0,0,0,0.1,0.1,0.1c0.1,0,0.1,0.1,0.1,0.2c0,0.1,0,0.1,0,0.1c0.1,0,0.1,0,0,0.1
		c0,0.1,0,0.1,0.1,0c0.1,0,0.1,0,0.1,0c0,0,0,0.1,0,0.2c0.2,0.3,0.3,0.4,0.3,0.4c0,0,0,0.1,0.2,0.2c0.1,0.1,0.2,0.3,0.2,0.4
		c0,0.1,0,0.1,0.1,0.1c0,0,0.1,0,0.1,0.1c0,0.1,0.1,0.2,0.1,0.2c0.1,0,0.1,0,0.2,0.1c0,0.1,0,0.1,0,0c-0.1,0-0.1,0-0.1,0
		s0.1,0.1,0.1,0.1s0.2,0.1,0.2,0.2c0,0.1,0.1,0.2,0.1,0.1c0,0,0.2,0.1,0.3,0.2c0.2,0.2,0.2,0.3,0.1,0.2c-0.1,0-0.1,0,0,0.1
		c0.2,0.1,0.3,0.3,1,1c0.6,0.7,0.7,0.8,0.6,0.9c0,0,0,0,0,0c0.1,0,0.1,0,0.1,0.1c0,0.1,0,0.1,0.1,0.1c0,0,0.1,0,0.1,0
		c0,0.1,0,0.1,0.1,0c0.1,0,0.2,0.2,0.2,0.3c0,0,0,0,0.1,0c0,0,0.1,0,0.1,0.1s0,0.1,0.1,0.1c0,0,0.2,0.1,0.3,0.2
		c0.1,0.2,0.3,0.3,0.3,0.3c0,0,0,0,0,0c0,0,0.1,0.1,0.2,0.2c0.3,0.2,0.4,0.3,0.6,0.6c0.1,0.1,0.3,0.3,0.4,0.3c0.1,0,0.1,0.1,0.1,0.2
		c0,0.1,0,0.1,0,0.1c0,0,0.3,0.2,0.6,0.5c0.3,0.3,0.5,0.5,0.5,0.5c0,0,0.1,0,0.1,0.1c0.1,0.1,0.1,0.1,0.1,0.2c0,0.1,0,0.1,0.3,0.1
		c0.1,0,0.1,0,0.1,0.1c0,0,0,0.1,0.1,0.1c0.1,0,0.1,0.1,0.1,0.1s0,0.1,0.1,0.1c0.1,0,0.1,0,0.1,0.1s0,0.1,0.1,0c0,0,0.1,0,0.1,0.1
		c0,0.1,0.1,0.1,0.1,0.1c0.2,0,0.9,0.6,0.9,0.7c0,0.1,0.1,0.1,0.1,0.1c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.2,0.2,0.3,0.2
		c0.1,0.1,0.2,0.1,0.1,0.2c0,0,0,0.1,0.1,0c0.1,0,0.2,0,0.2,0.1c0.1,0.1,0.1,0.2,0.2,0.1c0.1,0,0.1,0,0.1,0c0,0.1,0.3,0.4,0.4,0.4
		c0.2,0,0.3,0,0.3,0.2c0,0.1,0.1,0.2,0.2,0.1c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.2,0.1,0.2,0.1c0.1,0,0.2,0.1,0.3,0.2
		c0.1,0.1,0.2,0.2,0.2,0.1c0,0,0.1,0,0.2,0.1c0.1,0.1,0.1,0.2,0.1,0.2c0,0,0.1,0,0.2,0c0.3,0,0.5,0.1,0.4,0.3c0,0,0,0,0,0
		c0.1-0.1,0.5,0.1,0.8,0.4c0.1,0.1,0.3,0.2,0.4,0.2c0.1,0,0.2,0.1,0.3,0.2c0.1,0.1,0.1,0.1,0.2,0.1c0.1-0.1,0.1-0.1,0.1,0
		c0,0.1,0.1,0.1,0.2,0.1c0.1,0,0.2,0,0.2,0.1c0,0,0.1,0.1,0.2,0.1c0.1,0,0.2,0,0.2,0.1c0,0,0.1,0.1,0.1,0.1s0.1,0,0.1,0.1
		c0,0,0,0.1,0,0.1c0.2,0,0.5,0,0.4,0.1c-0.1,0.1,0.3,0.3,0.5,0.3c0.1,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0.1,0,0.3,0.1,0.4,0.2
		c0.2,0.1,0.3,0.1,0.3,0.1c0,0,0.1,0,0.3,0.1c0.2,0.1,0.4,0.2,0.4,0.2c0.1,0,0.1,0,0,0.1c-0.1,0.1-0.1,0.1,0.1,0.1
		c0.1,0,0.3,0,0.3,0.1c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.3,0.1,0.4,0.2c0.1,0.1,0.3,0.1,0.5,0.1c0.2,0,0.2,0,0.2,0.1
		c0,0.1,0,0.1,0.1,0.1c0.3,0,0.4,0,0.4,0.1c0,0,0,0.1,0.1,0.1c0.3,0,0.5,0,0.4,0.1c0,0,0.1,0.1,0.2,0.1c0.1,0,0.3,0,0.3,0.1
		c0,0,0.1,0.1,0.3,0.1c0.2,0,0.3,0,0.3,0.1c0,0,0.1,0.1,0.3,0.1c0.1,0,0.3,0,0.4,0.1c0.2,0.1,0.9,0.3,1.1,0.3c0.1,0,0.2,0,0.2,0.1
		s0.1,0.1,0.2,0.1s0.2,0.1,0.3,0.1c0.1,0.1,0.1,0.1,0.1,0c0-0.1,0-0.1,0.1,0c0,0.1,0.2,0.1,0.3,0.1c0.3,0,0.5,0,0.6,0.1
		c0,0,0.2,0.1,0.5,0.1c0.7,0.1,1.1,0.2,1.2,0.2c0,0,0.3,0.1,0.5,0.1c0.3,0,0.5,0,0.6,0.1c0.1,0,0.3,0.1,0.6,0.1c0.3,0,0.6,0,0.7,0
		c0.1,0,0.1,0,0.1,0.1c-0.1,0.1,0.3,0.1,0.4,0c0.1-0.1,0.1-0.1,0.1,0c-0.1,0.1,0.6,0.1,4,0.2c2.8,0,4.6-0.1,4.5-0.1
		c0-0.1,0.9-0.1,1.2-0.1c0,0,0.1,0,0.1-0.1c0,0,0.3-0.1,0.6-0.1c0.3,0,0.6,0,0.6-0.1c0,0,0.3-0.1,0.5-0.1c0.3,0,0.6-0.1,0.7-0.1
		c0.1,0,0.3-0.1,0.4,0c0.1,0,0.1,0,0.1-0.1c0-0.1,0.2-0.1,0.4-0.1c0.2,0,0.4,0,0.4-0.1c0-0.1,0.3-0.2,0.9-0.2c0.2,0,0.3-0.1,0.4-0.1
		c0,0,0.2-0.1,0.3-0.1s0.3,0,0.3-0.1c0,0,0.2-0.1,0.4-0.1c0.2,0,0.3,0,0.3-0.1s0.1,0,0.3,0c0.2,0,0.3,0,0.3-0.1
		c0-0.1,0.2-0.1,0.3-0.1c0.1,0,0.2,0,0.2-0.1c0-0.1,0-0.1,0.4-0.1c0.1,0,0.2,0,0.2-0.1c0,0,0.1-0.1,0.2-0.1c0.1,0,0.2-0.1,0.4-0.1
		c0.1-0.1,0.3-0.1,0.3-0.1c0,0,0.1,0,0.1-0.1c0-0.1,0.1-0.1,0.1,0c0.1,0,0.1,0,0.1,0c0,0,0.1-0.1,0.2-0.1c0.1,0,0.3-0.1,0.3-0.1
		c0-0.1,0.1-0.1,0.1-0.1c0,0,0.1,0,0.1,0c0-0.1,0.1-0.1,0.2,0c0.1,0.1,0.1,0.1,0.1,0c-0.1-0.1,0-0.1,0.2-0.1c0,0,0.1,0,0.1-0.1
		c0-0.1,0.2-0.1,0.3-0.1c0.2,0,0.3-0.1,0.3-0.2c0-0.1,0.1-0.1,0.1-0.1c0.2,0,0.4,0,0.5-0.2c0.1-0.1,0.3-0.2,0.4-0.2
		c0.1,0,0.2,0,0.2-0.1c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1-0.1s0.1-0.1,0.2-0.1c0.1,0,0.2,0,0.2-0.1c0,0,0-0.1,0.1-0.1
		c0.2,0,0.6-0.2,0.6-0.3c0,0,0.1-0.1,0.2-0.1s0.3-0.1,0.3-0.2c0.1-0.1,0.2-0.2,0.3-0.1c0.1,0,0.1,0,0.1,0c0-0.1,0.1-0.1,0.3-0.1
		c0,0,0.1,0,0,0c-0.1-0.1,0.3-0.3,0.4-0.2c0.1,0,0.1,0,0,0c0-0.1,0-0.1,0.2-0.2c0.1,0,0.2-0.1,0.2-0.1c0,0,0-0.1,0.1-0.1
		s0.2-0.1,0.2-0.1s0.2-0.1,0.2-0.1c0.1,0,0.1,0,0.1,0c0-0.1,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1-0.1c0-0.1,0.5-0.5,0.7-0.5
		c0.1,0,0.2,0,0.2-0.1s0.1-0.1,0.2-0.1c0.2,0,0.2,0,0.1-0.1c-0.1-0.1-0.1-0.1,0-0.1c0,0,0.2,0,0.3-0.1c0.1-0.1,0.2-0.1,0.3-0.1
		c0,0,0,0,0-0.1c0,0,0-0.1,0.1,0c0.1,0,0.1,0,0.1,0c0-0.1,0.3-0.4,0.4-0.4c0.1,0,0.1,0,0.1-0.1c0,0,0.1-0.1,0.1-0.1
		c0.1,0,0.1,0,0.1-0.1c0,0,0-0.1,0.1-0.1s0.1,0,0,0.1c0,0.1,0,0.1,0,0c0,0,0.1-0.1,0.1-0.2c0-0.1,0.1-0.1,0.1-0.1s0.2,0,0.2-0.1
		c0-0.1,0.1-0.1,0.2,0c0.1,0,0.1,0,0-0.1c0-0.1,0-0.1,0.1-0.1c0.1,0,0.2,0,0.2-0.1c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2-0.1,0.2-0.2
		s0-0.1,0.1-0.1c0,0,0.2-0.1,0.5-0.4c0.4-0.4,0.5-0.5,0.7-0.4c0,0,0.1,0,0.1-0.1c0,0,0.1-0.2,0.2-0.3c0.1-0.1,0.2-0.2,0.2-0.2
		c0,0,0.2-0.2,0.5-0.4c0.3-0.3,0.5-0.4,0.6-0.4c0.1,0,0.1,0,0,0c0,0,0-0.1,0.1-0.2c0.1-0.1,0.1-0.2,0.1-0.2c0-0.1,0-0.1,0,0
		c0.1,0,0.1,0,0.1-0.1c0-0.1,0-0.1,0.1,0c0.1,0.1,0.1,0.1,0.1-0.1c0-0.2,0.5-0.7,0.7-0.7c0.2,0,0.2,0,0.1-0.1
		c-0.1-0.1-0.1-0.1,0-0.1c0.1,0,0.1,0,0.1-0.1c0,0,0-0.1,0.1-0.1c0.1,0,0.1,0,0.1-0.1c0-0.1,0.1-0.1,0.1-0.2c0.1,0,0.1,0,0.1,0.1
		c0,0.1,0,0.1,0,0c0.1,0,0.1-0.1,0.1-0.1c0,0,0.2-0.3,0.5-0.6c0.3-0.3,0.5-0.6,0.5-0.6c0,0,0.1-0.1,0.2-0.1c0.1-0.1,0.2-0.2,0.2-0.3
		c0-0.1,0.1-0.2,0.3-0.3c0.1-0.1,0.3-0.2,0.3-0.2c0-0.1,0.1-0.2,0.3-0.4s0.3-0.3,0.3-0.4c0-0.1,0.4-0.5,0.5-0.4c0,0,0,0,0,0
		c-0.1-0.1,0.1-0.4,0.3-0.4c0,0,0.1-0.1,0.1-0.1c0-0.1,0.1-0.3,0.2-0.4c0.1-0.1,0.2-0.3,0.2-0.3c0,0,0,0,0.1,0c0.1,0,0.1,0,0,0
		c0,0,0-0.2,0.1-0.2c0.1-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.1,0.1-0.2c0.1,0,0.1-0.1,0.1-0.2c0-0.1,0.1-0.2,0.2-0.3
		c0.1-0.1,0.2-0.2,0.2-0.3c0,0,0.1-0.2,0.2-0.3c0.2-0.1,0.3-0.3,0.2-0.3c0-0.1,0-0.1,0.1-0.2c0,0,0.1-0.1,0.1-0.2
		c0-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.2-0.2,0.2-0.2c0-0.1,0-0.1-0.1,0c-0.1,0.1-0.1,0.1-0.1,0c0-0.1,0.1-0.1,0.1-0.2
		c0.1,0,0.1-0.1,0.1-0.2c0-0.1,0-0.1,0.1-0.1s0.1,0,0-0.1c0,0,0-0.1,0.1-0.2c0.1-0.1,0.2-0.2,0.2-0.3c0-0.1,0.1-0.2,0.1-0.3
		c0,0,0.1-0.1,0.1-0.2c0-0.1,0.1-0.2,0.1-0.2c0,0,0.1-0.1,0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.1,0.1,0.3-0.3,0.3-0.5c0,0,0.1-0.2,0.2-0.3
		c0.2-0.2,0.2-0.3,0.2-0.4c0,0,0-0.1,0.1,0c0,0,0.1,0,0.1-0.1c0-0.1,0-0.2,0.1-0.2c0,0,0.1-0.1,0.1-0.3c0-0.1,0.1-0.3,0.2-0.4
		c0.1-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.2,0.1-0.2c0,0,0.1-0.2,0.1-0.3c0-0.3,0.1-0.3,0.2-0.3c0.1,0,0.1,0,0-0.1
		c-0.1-0.1-0.1-0.1,0-0.1c0.1,0,0.3-0.4,0.2-0.5c0,0,0-0.1,0.1-0.1c0.1,0,0.1-0.1,0.1-0.3c0-0.2,0-0.3,0.1-0.4
		c0.1-0.1,0.3-0.5,0.2-0.7c0-0.1,0-0.2,0.1-0.1c0.1,0,0.1,0,0.1-0.1c0-0.3,0-0.4,0.1-0.4c0.1,0,0.1,0,0-0.2c0-0.1,0-0.3,0.1-0.4
		c0.1-0.2,0.2-0.3,0.2-0.7c0-0.2,0.1-0.3,0.1-0.2c0,0,0.1-0.1,0.1-0.4c0-0.2,0-0.4,0.1-0.4c0,0,0.1-0.1,0.1-0.3s0-0.3,0.1-0.3
		c0,0,0.1-0.2,0.1-0.3s0-0.3,0.1-0.3c0,0,0.1-0.2,0.1-0.4c0-0.2,0-0.3,0.1-0.3s0.1-0.2,0.1-0.5c0-0.3,0-0.5,0.1-0.5
		c0,0,0.1-0.2,0.1-0.4c0-0.2,0-0.4,0.1-0.4c0,0,0.1-0.2,0.1-0.4c0-0.2,0-0.4,0.1-0.3c0,0,0.1-0.2,0.1-0.4c0-0.7,0.1-1,0.2-1.1
		c0.2-0.1,0.1-10.4-0.1-10.5c0,0-0.1-0.2-0.1-0.5c0-0.2-0.1-0.5-0.1-0.5c-0.1-0.1,0-0.1,0-0.1c0.1,0,0.1,0,0-0.1
		c-0.1,0-0.1-0.2-0.1-0.4c0-0.2,0-0.4-0.1-0.5c0-0.1-0.1-0.3-0.1-0.5c0-0.2-0.1-0.4-0.1-0.4c0,0-0.1-0.2-0.1-0.4
		c0-0.2,0-0.4-0.1-0.4s-0.1-0.1-0.1-0.3s0-0.3-0.1-0.3c0,0-0.1-0.3-0.1-0.5c0-0.3,0-0.4-0.1-0.4c-0.1,0.1-0.1-0.1-0.1-0.5
		c0-0.1,0-0.1-0.1-0.1c0,0-0.1,0-0.1-0.2c0-0.1,0-0.2-0.1-0.3c0,0-0.1-0.2-0.1-0.3c0-0.2,0-0.3-0.1-0.3c0,0-0.1-0.1-0.1-0.2
		c0-0.1,0-0.3-0.1-0.4c-0.1-0.1-0.1-0.3-0.1-0.3c0,0,0-0.1-0.1-0.2c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.1,0-0.1-0.1-0.1
		c-0.1,0-0.1,0,0-0.1c0-0.1,0-0.2-0.1-0.3c-0.1-0.1-0.2-0.3-0.2-0.5c0-0.2-0.1-0.4-0.2-0.4c-0.1,0-0.1-0.1-0.1-0.2
		c0-0.1,0-0.2-0.1-0.2c0,0-0.1,0,0-0.1c0,0,0-0.1-0.1-0.1s-0.1,0,0-0.1c0,0,0-0.1,0-0.2c0-0.1-0.1-0.2-0.1-0.3
		c0-0.1-0.1-0.2-0.1-0.3c0-0.1-0.1-0.2-0.1-0.4c0-0.2-0.1-0.3-0.2-0.3c-0.2,0-0.2-0.1-0.1-0.2c0.1,0,0.1-0.1,0-0.1
		c0,0-0.1-0.1-0.1-0.2s-0.1-0.2-0.2-0.2c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.1,0-0.2-0.1-0.2s-0.1-0.1-0.1-0.2c0-0.1,0-0.2-0.1-0.2
		c0,0-0.1-0.1-0.1-0.1c0-0.2-0.2-0.4-0.4-0.7c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.1,0-0.2-0.1-0.2c0,0-0.1-0.1-0.1-0.2
		c0-0.1-0.2-0.3-0.3-0.5c-0.1-0.1-0.2-0.2-0.2-0.2c0,0,0-0.2-0.2-0.4c-0.1-0.2-0.3-0.4-0.3-0.4c0,0-0.1-0.1,0-0.2c0-0.2,0-0.2,0-0.2
		c-0.1,0-0.1,0-0.1,0s0-0.1-0.1,0c-0.1,0-0.1,0,0,0c0,0,0-0.1-0.1-0.2c-0.1-0.1-0.1-0.2-0.1-0.2c0-0.1,0-0.1-0.1-0.1s-0.1,0-0.1-0.1
		s0-0.2-0.1-0.2c-0.1,0-0.1,0,0,0c0.1,0,0.1-0.1-0.1-0.1c-0.2-0.1-0.2-0.2-0.1-0.2c0.1-0.1,0.1-0.1,0-0.1c-0.1,0-0.4-0.3-0.3-0.4
		c0,0,0-0.1-0.1-0.1s-0.1,0,0-0.1c0,0,0-0.1-0.1,0c-0.1,0-0.1,0-0.1-0.1c0-0.1,0-0.1,0-0.1c-0.1,0-0.1,0-0.2-0.2
		c-0.1-0.1-0.2-0.3-0.2-0.3c-0.1,0-0.1-0.1-0.1-0.2c0-0.1-0.1-0.2-0.1-0.2c-0.1-0.1-0.1-0.1-0.1-0.2c0,0,0-0.1,0-0.1
		c-0.1,0-0.3-0.2-0.3-0.3c0,0,0-0.1-0.1-0.1c-0.1,0-0.1-0.1-0.1-0.2c0-0.1,0-0.1-0.1-0.2c-0.1,0-0.1-0.1-0.1-0.1
		c0-0.1,0-0.1-0.1-0.1c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0-0.1-0.1-0.1c-0.1,0.1-0.4-0.4-0.4-0.5c0,0,0,0,0,0c-0.1,0.1-0.4-0.2-0.3-0.3
		c0,0,0,0,0,0c0,0-0.2-0.1-0.3-0.2c-0.1-0.1-0.4-0.4-0.6-0.6c-0.2-0.2-0.4-0.4-0.4-0.4c0,0-0.1-0.1-0.1-0.1c-0.1,0-0.1-0.1-0.1-0.2
		c0-0.1,0-0.1,0,0c-0.1,0.1-0.4-0.2-0.4-0.3c0-0.1-0.1-0.1-0.1-0.2c-0.1,0-0.1-0.1-0.1-0.1c0,0,0-0.1-0.1,0c-0.1,0-0.1,0-0.1-0.1
		c0-0.1,0-0.1-0.1-0.1c-0.1,0-0.4-0.4-0.4-0.4c0,0,0-0.1-0.1,0c-0.1,0.1-1.1-0.8-1.1-1c0-0.1,0-0.1-0.1,0c0,0.1-0.1,0.1-0.2-0.1
		c-0.1-0.1-0.2-0.2-0.2-0.1c0,0-0.2-0.1-0.4-0.3c-0.2-0.2-0.4-0.4-0.5-0.4c0,0-0.1,0-0.1-0.1c0-0.1-0.3-0.4-0.5-0.5
		C69.1,9.2,69,9.1,69,9.1C69,9,69,9,68.9,9c-0.1,0-0.1-0.1-0.2-0.2c0-0.1-0.2-0.2-0.3-0.2c-0.1,0-0.1,0,0-0.1
		c0.1-0.1,0.1-0.1-0.1-0.1c-0.2,0-0.3-0.1-0.3-0.1c0-0.1-0.1-0.1-0.2-0.1c-0.1,0-0.1-0.1-0.1-0.1C67.7,8,67.6,8,67.5,8
		c-0.1,0-0.2-0.1-0.2-0.1c0-0.1-0.1-0.1-0.2-0.1c-0.1,0-0.4-0.2-0.3-0.3c0,0,0-0.1-0.1-0.1c-0.1-0.1-0.1-0.1-0.1,0
		c0,0.1-0.1,0-0.4-0.3c-0.1-0.1-0.2-0.1-0.2-0.1c0,0-0.1,0-0.2-0.1c-0.1-0.1-0.2-0.1-0.1-0.1c0,0.1,0,0.1,0,0.1
		c-0.1,0-0.6-0.5-0.6-0.6c0,0-0.1,0-0.1,0c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.2-0.1-0.2-0.1c0,0-0.1,0-0.1,0s-0.1-0.1-0.1-0.1
		c-0.1,0-0.1,0-0.1-0.1c0-0.1,0-0.1,0,0c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.3-0.2-0.4-0.2c-0.1,0-0.2-0.1-0.2-0.2
		c0-0.1-0.1-0.1-0.1-0.1c-0.1,0-0.2,0-0.3-0.1C62.8,5,62.7,5,62.7,5c0,0-0.1,0-0.2-0.1c-0.1-0.1-0.2-0.2-0.4-0.2
		c-0.1,0-0.2,0-0.1-0.1c0.1-0.1-0.3-0.3-0.5-0.3c-0.1,0-0.1,0-0.1-0.1c0-0.1-0.2-0.2-0.5-0.2c-0.1,0-0.1-0.1-0.2-0.1
		c0,0-0.1-0.1-0.2-0.1c-0.1,0-0.2-0.1-0.3-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c0,0-0.1,0-0.2-0.1c-0.1-0.1-0.2-0.1-0.2-0.1
		c-0.1,0-0.1,0-0.1-0.1c0-0.1,0-0.1-0.1,0c0,0.1-0.1,0.1-0.1,0c0-0.1-0.2-0.2-0.9-0.5c-0.1-0.1-0.3-0.1-0.3-0.1c0,0-0.2-0.1-0.4-0.1
		c-0.2-0.1-0.4-0.2-0.6-0.2c-0.1,0-0.3-0.1-0.4-0.2c-0.1,0-0.2-0.1-0.2-0.1c0,0-0.1,0-0.1-0.1c0-0.1-0.5-0.2-0.5-0.2
		c0,0-0.1-0.1-0.2-0.2c-0.1-0.1-0.1-0.1-0.1-0.1c0.1,0.1-0.1,0.1-0.2,0c0,0-0.1-0.1-0.3,0c-0.2,0-0.2,0-0.2-0.1c0-0.1,0-0.1-0.2-0.1
		c-0.1,0-0.3,0-0.3,0c0,0-0.2-0.1-0.4-0.1s-0.3-0.1-0.3-0.1S54,1.4,53.9,1.4c-0.1,0-0.2,0-0.2-0.1c0,0-0.2-0.1-0.4-0.1
		c-0.2,0-0.3,0-0.3-0.1c0,0-0.1-0.1-0.2-0.1c-0.1,0-0.3,0-0.3-0.1c0,0-0.2-0.1-0.4-0.1c-0.2,0-0.5,0-0.5-0.1s-0.2-0.1-0.4-0.1
		c-0.2,0-0.4,0-0.5-0.1c-0.1-0.1-0.2-0.1-0.5-0.1c-0.1,0-0.2,0-0.2-0.1c0,0,0-0.1,0-0.1s-0.1,0-0.1,0.1c0,0.1,0,0.1-0.1,0
		c0-0.1-0.3-0.1-0.6-0.1c-0.3,0-0.5,0-0.6-0.1c0,0-0.3-0.1-0.6-0.1c-0.3,0-0.6-0.1-0.6-0.1c-0.1,0-0.3-0.1-0.6-0.1c-0.3,0-2,0-3.9,0
		C41.1,0,39.5,0,39.3,0 M46.1,10.1c0.4,0,0.8,0.1,0.8,0.1c0,0,0.3,0.1,0.6,0.1c0.3,0,0.5,0,0.5,0.1c0,0,0.2,0.1,0.4,0.1
		s0.4,0,0.4,0.1c0,0,0.2,0.1,0.4,0.1c0.2,0,0.4,0,0.4,0.1c0,0,0.2,0.1,0.3,0.1s0.4,0,0.4,0.1c0,0,0.2,0.1,0.3,0.1
		c0.2,0,0.3,0,0.3,0.1s0.2,0.1,0.3,0.1s0.3,0,0.3,0.1c0,0,0.1,0.1,0.3,0.1c0.1,0,0.2,0,0.3,0.1c0,0,0.2,0.1,0.3,0.1
		c0.1,0,0.3,0,0.3,0.1c0,0,0.1,0.1,0.2,0.1c0.1,0,0.2,0,0.3,0.1c0,0,0.1,0.1,0.2,0.1s0.2,0,0.3,0.1c0.2,0.1,0.5,0.2,1,0.4
		c0.5,0.2,1.6,0.7,2.3,1.1c0.7,0.4,1.2,0.6,1.3,0.7c0,0,0.2,0.1,0.3,0.2c0.1,0.1,0.4,0.2,0.6,0.4c0.2,0.1,0.4,0.2,0.4,0.2
		c0,0,0.1,0,0.1,0.1s0,0.1,0.1,0.1c0.2,0,3.1,2.1,4,2.9c0.2,0.1,0.6,0.6,1,0.9c0.4,0.4,1,1,1.4,1.4c0.4,0.4,0.7,0.8,0.8,0.9
		c0.1,0.1,0.3,0.4,0.6,0.6c0.2,0.3,0.4,0.5,0.5,0.6c0.2,0.2,0.4,0.4,0.6,0.7c0.1,0.2,0.3,0.3,0.3,0.4c0.1,0.1,0.1,0.1,0.1,0.2
		c0,0,0,0.1,0.1,0.2c0.2,0.2,1.1,1.6,1.1,1.7c0,0,0,0.1,0.1,0.1c0,0,0.1,0.1,0.2,0.3c0.1,0.2,0.1,0.3,0.2,0.3
		c0.1,0.1,0.3,0.5,0.5,0.9c0.1,0.2,0.2,0.3,0.2,0.3c0,0,0.1,0.1,0.1,0.1c0,0.1,0.2,0.5,0.4,0.9c0.5,0.9,1.4,3.1,1.4,3.4
		c0,0.1,0,0.2,0.1,0.2c0,0,0.1,0.1,0.1,0.3c0,0.1,0,0.2,0.1,0.3s0.1,0.2,0.1,0.3s0,0.3,0.1,0.3c0,0,0.1,0.2,0.1,0.3
		c0,0.1,0,0.3,0.1,0.3c0,0,0.1,0.2,0.1,0.3c0,0.1,0,0.3,0.1,0.3c0,0,0.1,0.2,0.1,0.4c0,0.2,0,0.3,0.1,0.3c0.1,0,0.1,0.2,0.2,1.2
		c0,0.2,0.1,0.4,0.1,0.5c0,0,0.1,0.3,0.1,0.5c0,0.2,0,0.4,0.1,0.5c0,0,0.1,0.3,0.1,0.7c0,0.3,0,0.7,0.1,0.7c0.1,0,0.1,0.6,0.1,1.3
		s0,1.3,0.1,1.3c0,0,0.1,0.4,0.1,0.8s0,0.8-0.1,0.8c-0.1,0-0.1,0.6-0.1,1.3c0,0.8,0,1.3-0.1,1.3c0,0-0.1,0.3-0.1,0.6
		c0,0.3,0,0.7-0.1,0.7c0,0-0.1,0.3-0.1,0.5c0,0.3,0,0.5-0.1,0.5c0,0-0.1,0.2-0.1,0.4c0,0.2,0,0.4-0.1,0.4c0,0-0.1,0.2-0.1,0.4
		c0,0.2,0,0.4-0.1,0.4c0,0-0.1,0.2-0.1,0.3s0,0.3-0.1,0.3c0,0-0.1,0.1-0.1,0.3s0,0.3-0.1,0.3c0,0-0.1,0.1-0.1,0.3s0,0.3-0.1,0.4
		c0,0.1-0.2,0.7-0.5,1.3c-0.5,1.3-0.6,1.8-0.8,2.3c-0.3,0.8-1.2,2.4-1.4,2.9c-0.1,0.2-0.3,0.5-0.4,0.7c-0.1,0.2-0.2,0.3-0.2,0.3
		c0,0-0.1,0.1-0.2,0.3c-0.1,0.3-1.4,2.1-1.5,2.2c0,0-0.1,0.1-0.1,0.1c0,0.1-0.1,0.2-0.2,0.4c-0.1,0.1-0.3,0.4-0.5,0.6
		c-0.1,0.2-0.3,0.4-0.4,0.5c-1.4,1.6-3.2,3.5-4.1,4.1c-0.2,0.1-0.4,0.3-0.5,0.4c-0.3,0.3-1.6,1.2-1.7,1.2c0,0-0.1,0-0.1,0.1
		c0,0.1-1.7,1.1-2,1.3c-0.2,0.1-0.3,0.1-0.3,0.2c0,0-0.2,0.1-0.4,0.3c-0.2,0.1-0.4,0.2-0.4,0.2C58,71.2,56.2,72,56.1,72
		c-0.1,0-0.1,0-0.1,0.1c0,0-0.1,0.1-0.1,0.1c-0.1,0-0.2,0.1-0.4,0.1c-1,0.5-3.4,1.3-3.8,1.3c-0.1,0-0.2,0-0.2,0.1s-0.2,0.1-0.3,0.1
		s-0.3,0-0.3,0.1c0,0-0.2,0.1-0.3,0.1c-0.2,0-0.3,0-0.3,0.1c0,0-0.2,0.1-0.4,0.1c-0.2,0-0.3,0-0.3,0.1c0,0.1-0.2,0.1-1.2,0.2
		c-0.2,0-0.5,0.1-0.6,0.1c-0.1,0-0.4,0.1-0.7,0.1c-0.3,0-0.9,0.1-1.4,0.1c-1.6,0.2-7.1,0.1-7.3-0.1c0,0-0.3-0.1-0.6-0.1
		s-0.6,0-0.6-0.1c0,0-0.2-0.1-0.4-0.1s-0.4,0-0.4-0.1c0,0-0.2-0.1-0.4-0.1c-0.2,0-0.4,0-0.4-0.1c0,0-0.2-0.1-0.4-0.1
		c-0.7-0.1-1-0.2-1-0.2c0,0-0.2-0.1-0.3-0.1c-0.1,0-0.3,0-0.3-0.1c0,0-0.1-0.1-0.2-0.1c-0.2,0-1.3-0.3-2.2-0.7
		c-0.6-0.2-0.8-0.3-1.6-0.6c-0.6-0.3-2.5-1.2-3-1.5c-0.2-0.1-0.5-0.3-0.6-0.3c-0.1,0-0.1-0.1-0.1-0.1c0,0,0-0.1-0.1-0.1
		c-0.1,0-2.3-1.4-2.4-1.5c0,0-0.2-0.1-0.3-0.3c-0.2-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.1-0.1-0.7-0.6c-1.2-1-3.4-3.1-4.5-4.5
		c-0.2-0.3-0.4-0.5-0.4-0.5c-0.2-0.2-2-2.8-2-3c0-0.1,0-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1c0-0.1,0-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1
		c0-0.1-0.2-0.3-0.3-0.6c-0.1-0.2-0.3-0.5-0.3-0.6c0-0.1-0.1-0.1-0.1-0.1c0,0-0.1,0-0.1-0.1c0-0.2-0.2-0.6-0.3-0.6
		c0,0-0.1-0.1-0.1-0.1c0-0.1-0.1-0.4-0.3-0.7c-0.3-0.6-0.8-1.8-1-2.5c-0.1-0.2-0.1-0.4-0.2-0.4c0,0-0.1-0.2-0.1-0.3
		c0-0.1,0-0.2-0.1-0.2c0,0-0.1-0.1-0.1-0.3s0-0.2-0.1-0.3c0,0-0.1-0.1-0.1-0.3c0-0.1,0-0.2-0.1-0.3s-0.1-0.2-0.1-0.3s0-0.3-0.1-0.3
		s-0.1-0.2-0.1-0.3s0-0.3-0.1-0.3c-0.1,0-0.1-0.4-0.2-1c0-0.2-0.1-0.4-0.1-0.4c0,0-0.1-0.2-0.1-0.4s0-0.4-0.1-0.4
		c0,0-0.1-0.3-0.1-0.5s0-0.5-0.1-0.5c0,0-0.1-0.3-0.1-0.7c-0.3-3.1-0.4-5.6-0.2-5.8c0.1-0.1,0.1-0.5,0.1-1.1c0-0.5,0-1,0.1-1.1
		c0,0,0.1-0.3,0.1-0.6c0.1-1,0.2-1.5,0.2-1.6c0,0,0.1-0.2,0.1-0.4c0-0.2,0-0.4,0.1-0.4c0,0,0.1-0.2,0.1-0.4c0-0.2,0-0.4,0.1-0.4
		c0,0,0.1-0.2,0.1-0.3s0-0.3,0.1-0.3c0,0,0.1-0.2,0.1-0.3s0-0.3,0.1-0.3c0,0,0.1-0.2,0.1-0.3s0-0.3,0.1-0.3c0,0,0.1-0.2,0.1-0.3
		c0-0.1,0-0.3,0.1-0.3c0,0,0.1-0.1,0.1-0.1c0-0.2,0.2-0.8,0.3-0.8c0,0,0.1-0.1,0.1-0.3c0-0.1,0-0.2,0.1-0.2c0,0,0.1-0.1,0.1-0.2
		c0-0.2,0.3-1,0.7-1.8c0.1-0.3,0.3-0.6,0.4-0.8c0.1-0.2,0.1-0.3,0.2-0.3c0,0,0.1-0.1,0.1-0.1c0-0.1,0.3-0.8,0.7-1.4
		c0.1-0.2,0.3-0.4,0.3-0.5s0.1-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1c0-0.1,0-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1c0-0.1,0.3-0.5,0.4-0.7
		c0,0,0.2-0.4,0.5-0.7c0.4-0.6,0.8-1.2,1.2-1.7c0.1-0.2,0.4-0.4,0.5-0.6c0.1-0.2,0.3-0.4,0.4-0.4c0.1-0.1,0.3-0.4,0.6-0.7
		c0.6-0.7,1.6-1.7,2.3-2.3c0.3-0.3,0.7-0.6,0.8-0.7c0.1-0.1,0.4-0.4,0.5-0.5c0.2-0.1,0.3-0.3,0.4-0.3c0.3-0.3,2.1-1.6,2.2-1.6
		c0,0,0.1-0.1,0.2-0.2c0.1-0.1,0.2-0.2,0.3-0.2c0.1,0,0.1,0,0.1-0.1s0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1-0.1c0,0,0.1-0.1,0.1-0.1
		c0.1,0,0.1,0,0.1-0.1c0,0,0.1-0.1,0.3-0.2c0.2-0.1,0.3-0.1,0.3-0.2c0.2-0.2,3-1.6,4-1.9c0.2-0.1,0.5-0.2,0.6-0.2
		c0.1,0,0.3-0.1,0.4-0.2c0.1,0,0.3-0.1,0.4-0.2c0.1-0.1,0.3-0.1,0.4-0.1c0.1,0,0.2,0,0.2-0.1c0,0,0.1-0.1,0.3-0.1
		c0.1,0,0.3,0,0.3-0.1c0,0,0.1-0.1,0.3-0.1s0.3,0,0.3-0.1c0,0,0.1-0.1,0.3-0.1c0.1,0,0.3,0,0.3-0.1c0,0,0.2-0.1,0.3-0.1
		c0.2,0,0.3,0,0.3-0.1c0,0,0.2-0.1,0.3-0.1c0.2,0,0.3,0,0.4-0.1c0.1-0.1,0.3-0.1,1.2-0.2c0.2,0,0.4-0.1,0.5-0.1c0,0,0.3-0.1,0.6-0.1
		c0.3,0,0.5,0,0.5-0.1c0,0,0.3-0.1,0.7-0.1c0.4,0,0.7,0,0.8-0.1C39.9,10,44.8,10,46.1,10.1 M37.5,27.9l-13.3,0l-0.9,1
		c-0.5,0.5-1.3,1.3-1.7,1.6c-0.4,0.4-1.1,1.1-1.7,1.7c-0.5,0.5-1.3,1.3-1.7,1.7l-0.7,0.7l13.2,0c9,0,13.2,0,13.2,0.1
		c0,0.1-0.4,0.5-1.6,1.8c-0.6,0.6-2,2-3.1,3.2c-1.1,1.2-2.3,2.4-2.6,2.7L36,42.9l3.9,3.9c2.1,2.1,6.3,6.3,9.3,9.2s5.7,5.6,6,6
		l0.6,0.6h4.7c2.6,0,4.7,0,4.7,0c0,0-6.9-6.9-10.8-10.8c-5.6-5.5-8.8-8.7-8.8-8.9c0-0.1,1.3-1.5,5.9-6.1c1.5-1.5,3.3-3.3,3.9-4
		c0.7-0.7,2-2.1,3-3.1c1-1,1.8-1.9,1.8-1.9c0,0-2.1,0-4.7,0C52.9,27.8,44.8,27.8,37.5,27.9 M17.5,37.4c0,0,12.9,12.9,15.3,15.3
		c0.6,0.6,1.2,1.2,1.4,1.4c0.1,0.1,1,1,1.9,1.9c1.9,1.9,1.3,1.3,4.2,4.2l2.5,2.5l4.8,0l4.8,0l-9-9c-5-4.9-9.1-9-9.1-9
		c-0.1,0-1.7-1.7-3.7-3.7l-3.6-3.6h-4.7C19.6,37.3,17.5,37.3,17.5,37.4"
            fill={primaryColor}
          />
          <path
            id="path2"
            className="st2"
            d="M39.8,10.2c-0.3,0,0.2,0,1,0s1.5-0.1,1.5-0.1C42.4,10.1,40.4,10.1,39.8,10.2 M42.9,10.1
		c0,0,0.7,0,1.5,0.1c0.8,0,1.2,0,0.9,0C44.7,10.1,42.9,10.1,42.9,10.1 M38.2,10.3l-0.3,0.1L38.2,10.3c0.2,0.1,0.5,0,0.6,0l0.3-0.1
		l-0.3,0C38.7,10.3,38.4,10.3,38.2,10.3 M46.3,10.3c0.2,0,0.5,0.1,0.6,0.1l0.3,0l-0.3-0.1C46.7,10.3,46.4,10.3,46.3,10.3l-0.3-0.1
		L46.3,10.3 M37.2,10.5l-0.3,0.1L37.2,10.5c0.1,0.1,0.3,0,0.4,0l0.2-0.1l-0.2,0C37.5,10.4,37.3,10.5,37.2,10.5 M47.5,10.5
		c0.1,0,0.3,0.1,0.5,0.1l0.3,0L48,10.5C47.8,10.5,47.6,10.4,47.5,10.5l-0.2-0.1L47.5,10.5 M36.1,10.7l-0.3,0.1L36.1,10.7
		c0.1,0.1,0.3,0,0.4,0l0.2-0.1l-0.2,0C36.5,10.6,36.3,10.6,36.1,10.7 M48.6,10.7c0.2,0.1,0.7,0.1,0.6,0.1c0,0-0.2-0.1-0.4-0.1
		C48.5,10.6,48.5,10.6,48.6,10.7 M35.4,10.9C35.2,10.9,35.1,10.9,35.4,10.9c0.1,0.1,0.2,0,0.3,0C35.9,10.8,35.7,10.8,35.4,10.9
		 M49.4,10.9c0.2,0.1,0.6,0.1,0.5,0.1c0,0-0.2-0.1-0.4-0.1C49.4,10.8,49.3,10.8,49.4,10.9 M34.8,11c0,0-0.3,0.1-0.5,0.1
		c-0.2,0.1-0.5,0.1-0.6,0.2s-0.4,0.1-0.7,0.2c-0.3,0.1-0.5,0.2-0.5,0.2c0,0,0.2,0,0.4-0.1c0.3-0.1,0.8-0.2,1.4-0.4
		C34.9,11,35.1,11,35,11C34.9,11,34.8,11,34.8,11 M50.1,11c0.1,0,0.2,0.1,0.3,0.1c0.3,0,0.2,0-0.2-0.1C50.1,11,50.1,11,50.1,11
		 M50.9,11.2c0.1,0,0.3,0.1,0.6,0.2c0.2,0.1,0.6,0.2,0.8,0.2c0.2,0.1,0.4,0.1,0.4,0.1c0,0-0.2-0.1-0.5-0.2c-0.3-0.1-0.6-0.2-0.7-0.2
		C51.1,11.2,50.7,11.1,50.9,11.2 M52.7,11.7c0.1,0.1,1.3,0.5,1.3,0.4c0,0,0-0.1-0.1-0.1c-0.1,0-0.4-0.1-0.6-0.2
		C52.8,11.7,52.6,11.7,52.7,11.7 M31.5,12c-0.1,0.1-0.3,0.1-0.3,0.2c-0.1,0.1,0.3,0,0.6-0.2C32.1,11.9,31.8,11.9,31.5,12 M30.5,12.4
		c-0.2,0.1-0.3,0.1-0.3,0.2c0,0,0.7-0.2,0.8-0.3C31.1,12.2,30.8,12.2,30.5,12.4 M29.3,12.9C29.2,12.9,29,13,29.1,13
		c0,0,0.2,0,0.4-0.1C29.8,12.7,29.7,12.7,29.3,12.9 M55.7,12.9c0.2,0.1,0.3,0.2,0.4,0.1c0,0-0.5-0.3-0.6-0.3
		C55.4,12.7,55.5,12.8,55.7,12.9 M27.6,13.7c-0.7,0.4-1.3,0.7-1.4,0.8c-0.1,0.1,0,0,0.3-0.1c0.3-0.1,0.9-0.5,1.5-0.8
		c1.1-0.5,1.1-0.6,1-0.6C28.9,13.1,28.3,13.4,27.6,13.7 M56.2,13.1c0,0,0.5,0.3,1.1,0.6c0.6,0.3,1.2,0.6,1.4,0.7
		c0.9,0.5,1.3,0.8,2.2,1.4c0.5,0.4,0.9,0.6,0.9,0.6c-0.1-0.1-1.6-1.1-2.4-1.6C58.2,14.1,56,12.9,56.2,13.1 M24.6,15.5
		c-1.7,1.2-4.2,3.1-4.2,3.3c0,0,0.3-0.2,0.7-0.5C22,17.4,23.3,16.4,24.6,15.5c1.4-0.8,1.3-0.8,1.2-0.8C25.8,14.7,25.3,15,24.6,15.5
		 M61.8,16.4c0,0,0.1,0.2,0.3,0.3c0.2,0.1,0.3,0.2,0.3,0.1C62.3,16.7,61.8,16.4,61.8,16.4 M62.5,17C62.5,17,62.5,17.1,62.5,17
		c0.3,0.1,3.3,2.9,4.3,4.1c0.3,0.3,0.5,0.5,0.5,0.5c0-0.2-2.6-2.9-3.8-3.8c-0.4-0.4-0.8-0.7-0.9-0.8C62.5,16.9,62.5,16.9,62.5,17
		 M19.3,19.8c-1.3,1.4-3,3.4-2.9,3.5c0,0,0,0,0.1,0c0.8-1.1,1.8-2.3,3.2-3.7c0.4-0.4,0.7-0.7,0.7-0.8C20.3,18.9,19.9,19.3,19.3,19.8
		 M67.4,21.7c0,0,0.2,0.3,0.5,0.7c0.3,0.3,0.7,0.9,0.9,1.2c0.4,0.6,0.7,0.9,0.6,0.7C69.2,23.9,67.4,21.6,67.4,21.7 M15.8,24.1
		c-0.3,0.4-0.7,1-0.9,1.3c-0.2,0.4-0.5,0.8-0.6,1c-0.1,0.2-0.1,0.3-0.1,0.3s0.2-0.3,0.5-0.7c0.2-0.4,0.7-1.1,1-1.5
		c0.6-0.9,0.7-1,0.6-1C16.3,23.4,16.1,23.7,15.8,24.1 M69.9,25.2c0.3,0.4,0.4,0.6,0.4,0.5c0-0.1-0.4-0.7-0.5-0.7
		C69.8,25,69.8,25.1,69.9,25.2 M70.5,26.2c0.1,0.2,0.3,0.5,0.4,0.6c0.1,0.1,0,0-0.1-0.2c-0.1-0.2-0.3-0.5-0.4-0.6
		C70.4,25.9,70.4,26,70.5,26.2 M13.6,27.9c-0.3,0.6-0.6,1.1-0.5,1.2C13.1,29,13.4,28.6,13.6,27.9c0.4-0.6,0.6-1.1,0.6-1.1
		C14.2,26.8,13.9,27.3,13.6,27.9 M71.1,27.1c0.1,0.3,0.4,0.9,0.5,0.9c0.1,0,0-0.2-0.2-0.6C71.1,27.2,71,27,71.1,27.1 M53.1,27.7
		c1.4,0,3.6,0,4.9,0c1.3,0,0.2,0-2.5,0S51.7,27.7,53.1,27.7 M29.5,27.8l-5.4,0l-3.5,3.5c-1.9,1.9-3.4,3.5-3.4,3.5
		c0,0,1.6-1.5,3.5-3.4l3.4-3.4l4.8,0c2.6,0,7.3,0,10.4-0.1l5.6-0.1l-5,0C37.1,27.7,32.4,27.8,29.5,27.8 M58.7,29.6
		c-1,1.1-3.1,3.1-4.6,4.6c-5,5.1-8.4,8.5-8.4,8.6c0,0.1,1.5,1.6,3.4,3.5c1.9,1.8,5.2,5.1,7.4,7.3c2.2,2.2,4,4,4,4
		c0,0-5.8-5.8-11.3-11.3c-1.9-1.8-3.4-3.4-3.4-3.5c0-0.1,1.9-2,4.1-4.2c2.2-2.3,4.2-4.2,4.2-4.3c0.1-0.1,1.4-1.5,3-3
		c2.9-3,3.5-3.6,3.5-3.6C60.6,27.7,59.7,28.6,58.7,29.6 M71.9,28.8c0,0.1,0.3,0.7,0.3,0.6c0,0,0-0.2-0.1-0.4
		C72,28.9,71.9,28.8,71.9,28.8 M12.8,29.4c-0.1,0.2-0.1,0.3-0.1,0.4C12.8,29.8,12.8,29.7,12.8,29.4c0.2-0.1,0.2-0.3,0.2-0.3
		C13,29.1,12.9,29.3,12.8,29.4 M12.5,30.3c-0.1,0.2-0.1,0.4-0.1,0.4C12.4,30.7,12.5,30.5,12.5,30.3c0.1-0.1,0.2-0.3,0.2-0.3
		C12.7,29.9,12.6,30.1,12.5,30.3 M73,31.3c0,0,0.1,0.3,0.2,0.5c0.1,0.3,0.2,0.4,0.2,0.3C73.3,31.9,73,31.2,73,31.3 M11.9,31.8
		c0,0-0.1,0.1-0.1,0.2c0,0.2,0,0.2,0.1,0C12,31.8,12,31.7,11.9,31.8 M73.5,32.9c0.1,0.3,0.2,0.7,0.3,0.9c0,0.2,0.1,0.4,0.2,0.6
		l0.1,0.3l0-0.3c0-0.1-0.1-0.4-0.1-0.6c-0.3-1.1-0.4-1.3-0.5-1.4C73.3,32.3,73.4,32.5,73.5,32.9 M11.5,33c0,0.2-0.1,0.3-0.1,0.4
		C11.5,33.4,11.5,33.3,11.5,33c0.1-0.1,0.1-0.3,0.1-0.3C11.6,32.7,11.6,32.9,11.5,33 M11.4,33.6c0,0.1,0,0.2-0.1,0.3
		c0,0.1,0,0.1,0,0.1C11.4,33.9,11.4,33.8,11.4,33.6C11.4,33.5,11.4,33.5,11.4,33.6 M11.2,34.2c0,0.1,0,0.3-0.1,0.3c0,0.1,0,0.1,0,0
		C11.2,34.5,11.3,34.3,11.2,34.2C11.2,34,11.2,34,11.2,34.2 M34.7,34.7l-6.9,0l7.9,0c6.3,0,7.9,0,7.9,0.1c0,0.1,0,0.1-0.1,0.1
		c0,0-0.1,0-0.1,0.1c0,0.1,0.1,0,0.2-0.1c0.3-0.3,0.1-0.3-1.1-0.3C42.1,34.6,38.5,34.7,34.7,34.7 M74,34.8c0.2,0.9,0.4,1.9,0.3,1.2
		C74.3,35.5,74.1,34.9,74,34.8C74,34.6,74,34.6,74,34.8 M11,34.9c0,0.1,0,0.3-0.1,0.4l0,0.2l0.1-0.2C11.1,35.2,11.1,35,11,34.9
		C11,34.7,11,34.7,11,34.9 M39.7,38.9c-2,2.1-3.7,3.8-3.8,3.9c0,0.1,0.7-0.6,1.6-1.6c0.9-0.9,2.1-2.1,2.5-2.6
		c2.8-2.9,3.4-3.5,3.4-3.5C43.4,35.1,41.7,36.8,39.7,38.9 M10.8,35.7c0,0.1,0,0.3-0.1,0.4l0,0.2l0.1-0.2
		C10.9,35.9,10.9,35.8,10.8,35.7C10.9,35.5,10.9,35.5,10.8,35.7 M10.6,36.7c0,0.2,0,0.4,0,0.4C10.6,37.1,10.7,37,10.6,36.7
		c0.1-0.1,0.1-0.3,0.1-0.4C10.7,36.3,10.7,36.5,10.6,36.7 M74.4,36.7c0,0.1,0,0.3,0.1,0.5c0,0.1,0.1,0.1,0-0.2
		C74.5,36.5,74.4,36.3,74.4,36.7 M22.1,37.3l4.9,0l6.2,6.1l6.2,6.1l-6.2-6.2L27,37.2L22.1,37.3l-4.9,0L22.1,37.3 M10.5,37.9
		c0,0.3,0,0.5,0,0.5C10.5,38.4,10.5,38.2,10.5,37.9c0.1-0.2,0.1-0.4,0.1-0.4C10.5,37.4,10.5,37.6,10.5,37.9 M74.6,37.7
		c0,0.2,0,0.5,0.1,0.6C74.7,38.7,74.7,38.3,74.6,37.7l0-0.3L74.6,37.7 M17.8,38c0.1,0.1,0.3,0.2,0.3,0.2c0,0-0.4-0.5-0.5-0.5
		C17.6,37.7,17.6,37.9,17.8,38 M25.3,45.4c3.9,3.9,9.4,9.4,12.3,12.2l5.1,5.1h4.9c3.9,0,4.9,0,4.9-0.1c0-0.1-0.7-0.8-1.4-1.6
		c-0.8-0.8-1.4-1.4-1.4-1.4c0,0,0.6,0.7,1.4,1.4c0.8,0.8,1.4,1.4,1.4,1.5c0,0-2.2,0.1-4.9,0.1h-4.9l-1.5-1.5
		c-0.8-0.8-4.7-4.7-8.6-8.6c-3.9-3.9-8.8-8.7-10.8-10.7C19.8,39.9,21.4,41.5,25.3,45.4 M74.8,39c0,0.3,0,0.7,0.1,1l0.1,0.6l0-0.5
		C74.9,39.9,74.9,39.4,74.8,39l0-0.5L74.8,39 M10.3,39.3c0,0.4,0,0.7,0,0.8c0,0.1,0.1-0.2,0.1-0.6c0-0.4,0-0.7,0-0.8
		C10.4,38.7,10.3,39,10.3,39.3 M10.2,42.5c0,1.2,0,1.7,0,1.1c0-0.6,0-1.6,0-2.2C10.2,40.8,10.2,41.3,10.2,42.5 M74.9,42.5
		c0,1,0,1.4,0,0.9c0-0.5,0-1.3,0-1.7C75,41.2,74.9,41.5,74.9,42.5 M37.1,44.2c0.7,0.7,1.2,1.2,1.2,1.2
		C38.3,45.4,37.8,44.9,37.1,44.2C36.5,43.6,36,43,35.9,43S36.4,43.6,37.1,44.2 M74.8,45.4c0,0.5,0,0.9,0,0.9S74.8,45.9,74.8,45.4
		c0.1-0.4,0.1-0.8,0.1-0.9C74.9,44.5,74.8,44.9,74.8,45.4 M10.2,45.2c0,0.2,0,0.6,0.1,0.8c0.1,0.3,0.1,0.2,0-0.4
		C10.3,44.8,10.2,44.5,10.2,45.2 M38.7,45.8c0,0.1,0.4,0.5,0.5,0.4c0,0-0.1-0.1-0.2-0.3S38.7,45.8,38.7,45.8 M74.6,47
		c0,0.3,0,0.5,0,0.5C74.6,47.5,74.7,47.3,74.6,47c0.1-0.2,0.1-0.4,0.1-0.4C74.7,46.5,74.6,46.7,74.6,47 M10.4,46.8
		c0,0.1,0,0.4,0.1,0.5l0.1,0.3v-0.3C10.6,47.2,10.5,46.9,10.4,46.8v-0.3V46.8 M39.9,47.1c0.2,0.2,0.4,0.4,0.4,0.4
		c0,0-0.1-0.2-0.3-0.4c-0.2-0.2-0.4-0.4-0.4-0.4C39.6,46.7,39.7,46.8,39.9,47.1 M40.7,47.9c0.2,0.2,0.3,0.3,0.4,0.3
		c0,0-0.5-0.6-0.6-0.6C40.4,47.5,40.6,47.7,40.7,47.9 M74.5,47.9c0,0.2-0.1,0.4,0,0.5c0,0.1,0,0,0.1-0.2c0-0.2,0.1-0.4,0-0.5
		C74.5,47.7,74.5,47.8,74.5,47.9 M10.6,48c0,0.1,0,0.3,0.1,0.4l0.1,0.3l0-0.3C10.7,48.3,10.7,48.1,10.6,48l0-0.2L10.6,48 M41.2,48.3
		c0,0,0.2,0.2,0.4,0.4c0.2,0.2,0.4,0.3,0.4,0.3c0,0-0.2-0.2-0.4-0.4S41.2,48.2,41.2,48.3 M74.3,49c0,0.3-0.5,2.2-0.6,2.6
		c0,0.1,0,0.1,0,0c0.2-0.2,0.8-2.9,0.7-2.9C74.3,48.6,74.3,48.8,74.3,49 M10.8,48.8c0,0.1,0,0.3,0.1,0.4l0.1,0.3l0-0.3
		C10.9,49.1,10.9,48.9,10.8,48.8l0-0.2L10.8,48.8 M42.1,49.1c0,0.1,0.8,0.8,0.8,0.8c0,0-0.2-0.2-0.4-0.4
		C42.2,49.2,42.1,49.1,42.1,49.1 M10.9,49.6c0,0,0.1,0.4,0.2,0.7c0.1,0.4,0.2,0.8,0.2,1c0,0.2,0.1,0.3,0.1,0.3
		c0.1,0.1,0-0.3-0.1-0.7c0-0.1-0.1-0.5-0.2-0.8C11,49.6,10.9,49.4,10.9,49.6 M39.4,49.6c0,0,1,1,2.1,2.2c3.6,3.6,8,8,7.9,7.8
		C49.4,59.5,39.9,50,39.4,49.6C39.4,49.6,39.4,49.6,39.4,49.6 M43.3,50.4c0,0,1.4,1.4,3.1,3l3.1,3l-3-3
		C44.7,51.7,43.3,50.3,43.3,50.4C43.3,50.3,43.2,50.3,43.3,50.4 M73.6,51.8c0,0.1,0,0.2-0.1,0.3c0,0.1,0,0.1,0,0
		C73.6,52,73.6,51.9,73.6,51.8C73.6,51.7,73.6,51.7,73.6,51.8 M11.5,51.8c0,0.1,0.3,1,0.3,0.9c0,0,0-0.3-0.1-0.5
		C11.6,52,11.5,51.8,11.5,51.8 M11.9,52.9c0,0.1,0,0.2,0.1,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.1,0.1,0.1,0.1,0.1c0,0,0-0.2-0.1-0.5
		C11.9,53,11.9,52.9,11.9,52.9 M72.9,53.7c-0.1,0.2-0.1,0.4-0.1,0.4C72.8,54.2,72.9,54,72.9,53.7c0.1-0.1,0.2-0.3,0.2-0.4
		C73.1,53.3,73,53.5,72.9,53.7 M12.4,54.4c0,0.1,0.3,0.8,0.3,0.6c0-0.1-0.1-0.3-0.2-0.4C12.4,54.4,12.4,54.3,12.4,54.4 M12.9,55.5
		c0,0.1,0.3,0.7,0.3,0.6c0,0,0-0.2-0.1-0.4C13,55.6,12.9,55.5,12.9,55.5 M49.5,56.4c0,0,1.4,1.5,3.2,3.2l3.2,3.1h4.9
		c2.7,0,4.9,0,4.9-0.1c0-0.1-4.8-4.9-5-5c-0.3-0.2,0.6,0.7,2.6,2.6c1.3,1.2,2.3,2.3,2.3,2.3c0,0-2.2,0.1-4.8,0.1h-4.8l-3.2-3.2
		C50.9,57.8,49.5,56.4,49.5,56.4 M71.5,56.9c-0.1,0.1-0.1,0.3-0.1,0.3C71.5,57.2,71.5,57.1,71.5,56.9C71.8,56.6,71.7,56.5,71.5,56.9
		 M13.8,57.5c0.1,0.2,0.3,0.5,0.3,0.4c0-0.1-0.3-0.6-0.3-0.6C13.8,57.3,13.8,57.4,13.8,57.5 M71.1,57.7c0,0-0.1,0.2-0.2,0.4
		c-0.7,1.3-1,1.8-1.7,2.7c-0.4,0.5-0.6,0.9-0.6,0.9c0.1-0.1,1.3-1.9,1.6-2.4c0.1-0.2,0.3-0.5,0.4-0.6C71.2,57.8,71.3,57.7,71.1,57.7
		C71.2,57.7,71.2,57.7,71.1,57.7 M14.4,58.5c0.1,0.1,0.2,0.4,0.3,0.5c0.2,0.4,0.8,1.2,0.8,1.2c0,0,0-0.1-0.4-0.7
		c-0.2-0.3-0.4-0.6-0.4-0.7C14.6,58.8,14.5,58.6,14.4,58.5l-0.1-0.2L14.4,58.5 M16.2,61.4c0.2,0.3,1.4,1.8,1.6,2
		c0,0.1,0.4,0.4,0.7,0.8c0.3,0.4,0.6,0.7,0.7,0.7c0,0-0.1-0.2-0.3-0.4c-0.6-0.7-1.5-1.7-2.1-2.6C16.1,61.2,15.8,60.8,16.2,61.4
		 M68.3,62.1c-0.6,0.9-2.8,3.3-4,4.4c-0.4,0.4-0.9,0.7-1.2,1.1c-0.8,0.6-0.9,0.8-0.4,0.4c0.3-0.2,0.6-0.5,0.7-0.5
		c0.1-0.1,0.3-0.3,0.5-0.4c0.4-0.4,1.7-1.6,2.4-2.3c0.9-1,1.4-1.5,2.3-2.7c0-0.1,0-0.1,0-0.1C68.5,61.8,68.4,61.9,68.3,62.1
		 M19.4,65.2c0.5,0.5,0.7,0.6,0.3,0.2c-0.2-0.2-0.4-0.4-0.5-0.4C19.2,65,19.3,65.1,19.4,65.2 M20.1,65.8c0,0.1,1.4,1.3,1.5,1.3
		c0,0-0.1-0.1-0.3-0.3c-0.2-0.1-0.5-0.5-0.8-0.7C20.3,66,20.1,65.8,20.1,65.8 M21.9,67.5c1.1,0.9,3.7,2.7,5.1,3.5
		c0.2,0.1,0.5,0.3,0.6,0.3c0.2,0.1,0.6,0.3,0.6,0.2c0,0-0.3-0.2-0.6-0.4C25.8,70.2,23,68.4,21.9,67.5c-0.1-0.2-0.2-0.3-0.2-0.3
		S21.8,67.4,21.9,67.5 M61.8,68.5c-0.5,0.4-0.6,0.5-0.1,0.2c0.2-0.1,0.4-0.3,0.5-0.4C62.2,68.2,62.1,68.3,61.8,68.5 M60.9,69.1
		C60.7,69.3,60.7,69.3,60.9,69.1c0.1,0,0.2,0,0.2-0.1C61.2,68.9,61.2,69,60.9,69.1 M59.4,70.1c-0.9,0.5-1.2,0.8-0.5,0.4
		c0.6-0.4,1.3-0.8,1.3-0.8C60.2,69.6,60.2,69.6,59.4,70.1 M58,70.9c-0.4,0.2-0.5,0.4-0.1,0.2c0.4-0.2,0.6-0.4,0.5-0.3
		C58.3,70.8,58.1,70.8,58,70.9 M56.9,71.5c-0.2,0.1-0.4,0.2-0.4,0.2c0,0,0.6-0.2,0.8-0.4C57.6,71.2,57.4,71.3,56.9,71.5 M55.6,72.1
		c-0.2,0.1-0.3,0.2-0.2,0.2c0.1,0,0.7-0.3,0.7-0.3C56,71.9,55.9,71.9,55.6,72.1 M29.5,72.2c0.1,0.1,1,0.4,1,0.4c0,0,0-0.1-0.1-0.1
		c-0.1,0-0.3-0.1-0.5-0.2C29.5,72.1,29.4,72.1,29.5,72.2 M33.3,73.6c0.1,0,0.3,0.1,0.6,0.2c0.2,0.1,0.5,0.1,0.6,0.2s0.4,0.1,0.7,0.2
		c0.3,0.1,0.6,0.1,0.8,0.2c0.1,0,0.4,0.1,0.6,0.1c0.4,0.1,0.2,0-0.6-0.2c-0.7-0.1-1.9-0.4-2.1-0.5C33.5,73.5,33,73.5,33.3,73.6
		 M51.5,73.6c-0.1,0-0.4,0.1-0.5,0.2c-0.2,0-0.2,0.1-0.1,0.1C51,73.8,51.3,73.7,51.5,73.6c0.3,0,0.5-0.1,0.4-0.1
		C51.9,73.5,51.7,73.5,51.5,73.6 M50.3,73.9c-0.2,0-0.4,0.1-0.4,0.1c-0.1,0.1,0.6-0.1,0.8-0.1C50.8,73.8,50.7,73.8,50.3,73.9
		 M49.3,74.2c-0.1,0.1-0.1,0.1,0.2,0c0.2,0,0.4-0.1,0.4-0.1C49.9,74,49.4,74.1,49.3,74.2 M48.4,74.3c-0.1,0.1,0,0.1,0.2,0
		c0.2,0,0.4-0.1,0.4-0.1C49.1,74.2,48.6,74.3,48.4,74.3 M37.3,74.5c0.5,0.1,0.8,0.1,0.5,0C37.7,74.5,37.5,74.4,37.3,74.5L37,74.4
		L37.3,74.5 M47.3,74.5c-0.3,0.1,0,0.1,0.5,0l0.3-0.1l-0.3,0C47.7,74.4,47.5,74.5,47.3,74.5 M38.1,74.6c0,0,0.3,0.1,0.7,0.1
		c0.4,0,0.5,0,0.4,0C38.9,74.6,38,74.6,38.1,74.6 M46,74.7c-0.2,0,0,0.1,0.4,0c0.4,0,0.7-0.1,0.7-0.1C47.1,74.6,46.3,74.6,46,74.7
		 M40.3,74.8c0.4,0,1.4,0.1,2.3,0.1c0.8,0,1.8,0,2.3-0.1c0.4,0-0.6-0.1-2.3-0.1S39.9,74.8,40.3,74.8"
          />
          <path
            id="path3"
            className="st3"
            d="M42.7,10.2c0.4,0.1,2.3,0.1,2.2,0c0,0-0.6,0-1.3-0.1C42.8,10.1,42.4,10.1,42.7,10.2 M39.5,10.3
		c-0.4,0-0.8,0.1-0.9,0.1c0,0,0.2,0,0.6,0c0.4,0,1.2-0.1,1.8-0.1l1.2-0.1l-1,0C40.8,10.2,40,10.2,39.5,10.3 M45,10.3
		c0,0,0.4,0.1,0.8,0.1c0.5,0,0.7,0,0.4,0C45.9,10.3,44.9,10.2,45,10.3 M37.6,10.5c-0.1,0,0,0.1,0.4,0c0.3,0,0.6-0.1,0.6-0.1
		C38.6,10.4,37.8,10.4,37.6,10.5 M46.6,10.5c0,0,0.3,0.1,0.6,0.1c0.3,0,0.5,0,0.3,0C47.3,10.4,46.5,10.4,46.6,10.5 M36.6,10.7
		l-0.2,0.1L36.6,10.7c0.1,0.1,0.2,0,0.3,0l0.2-0.1h-0.2C36.8,10.6,36.7,10.6,36.6,10.7 M48.2,10.7c0.1,0,0.2,0.1,0.3,0.1
		c0.2,0,0.2,0,0-0.1C48.3,10.6,48,10.6,48.2,10.7 M35.7,10.9l-0.2,0.1L35.7,10.9c0.1,0.1,0.2,0,0.3,0l0.2-0.1h-0.2
		C36,10.8,35.8,10.8,35.7,10.9 M49.1,10.9c0.1,0,0.2,0.1,0.3,0.1h0.2l-0.2-0.1C49.3,10.8,49.2,10.8,49.1,10.9l-0.2-0.1L49.1,10.9
		 M35,11l-0.2,0.1L35,11c0.1,0.1,0.2,0,0.3,0C35.4,10.9,35.2,10.9,35,11 M49.8,11c0.3,0.1,0.5,0.1,0.3,0C50.1,11,49.9,10.9,49.8,11
		L49.7,11L49.8,11 M50.6,11.2c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0-0.1C50.8,11.2,50.6,11.1,50.6,11.2
		C50.5,11.1,50.5,11.1,50.6,11.2 M52.3,11.7c0,0,0.1,0.1,0.2,0.1c0.2,0,0.2,0,0-0.1C52.3,11.6,52.3,11.6,52.3,11.7 M54.7,12.6
		c0,0,0.1,0.1,0.1,0.1s0.2,0.1,0.4,0.2c0.3,0.2,0.7,0.2,0.4,0.1C55.4,12.7,54.6,12.5,54.7,12.6 M29.7,12.8c-0.2,0.1-0.3,0.2-0.2,0.2
		c0.1,0,0.6-0.2,0.6-0.3C30.1,12.6,30.1,12.6,29.7,12.8 M28.3,13.4c-0.5,0.3-0.9,0.5-0.9,0.5c0.1,0,1.9-0.9,1.9-0.9
		C29.3,13,29.2,13,28.3,13.4 M55.8,13c0,0,0.2,0.1,0.4,0.2c0.2,0.1,0.6,0.3,0.9,0.4c0.3,0.1,0.5,0.2,0.5,0.2c0,0-0.3-0.2-0.7-0.4
		C56.1,13.1,55.8,13,55.8,13 M26.5,14.4c-0.1,0.1-0.2,0.1-0.2,0.2c0,0.1,0.1,0,0.3-0.1C26.8,14.2,26.8,14.2,26.5,14.4 M58.8,14.6
		c0.1,0.1,0.3,0.2,0.3,0.2c0,0,0-0.1-0.1-0.2c-0.1-0.1-0.3-0.2-0.3-0.2C58.6,14.4,58.7,14.5,58.8,14.6 M25.9,14.7
		c-0.1,0.1-0.2,0.1-0.2,0.2c0,0.1,0.1,0,0.3-0.1C26.2,14.6,26.2,14.6,25.9,14.7 M59.2,14.8c0,0.1,0.7,0.5,0.7,0.5c0,0,0-0.1-0.1-0.1
		C59.7,15,59.2,14.8,59.2,14.8 M24.8,15.5l-0.2,0.2L24.8,15.5c0.1,0,0.3-0.1,0.3-0.1C25,15.3,25,15.3,24.8,15.5 M60.1,15.3
		c0,0,0.1,0.1,0.2,0.2c0.1,0.1,0.2,0.1,0.2,0.1c0,0-0.1-0.1-0.2-0.2C60.2,15.3,60.1,15.3,60.1,15.3 M24.3,15.8L24.1,16L24.3,15.8
		c0.1,0,0.3-0.1,0.3-0.1C24.5,15.6,24.5,15.6,24.3,15.8 M60.6,15.7c0,0,0.2,0.2,0.5,0.4c0.3,0.2,0.7,0.5,0.9,0.7
		c0.2,0.2,0.4,0.3,0.4,0.2s-0.1-0.1-0.3-0.2s-0.3-0.2-0.4-0.3C61.6,16.3,60.6,15.6,60.6,15.7 M23,16.7c-0.5,0.4-0.9,0.7-1,0.8
		c0,0.1,0.3-0.2,0.7-0.5c0.4-0.3,0.9-0.7,1.1-0.8C23.9,16.1,24,16,24,16C23.9,16,23.5,16.4,23,16.7 M62.7,17.2
		c0.1,0.1,0.2,0.2,0.3,0.3c0.2,0.1,0.1-0.1-0.2-0.3L62.5,17L62.7,17.2 M21.6,17.9c-0.1,0.1-0.2,0.2-0.1,0.1c0.3-0.2,0.4-0.3,0.4-0.4
		C21.9,17.6,21.7,17.7,21.6,17.9 M63.4,17.8c0,0.1,1.1,1,1.1,0.9c0,0-0.2-0.3-0.6-0.5C63.7,18,63.4,17.8,63.4,17.8 M20.6,18.7
		c-0.1,0.1-0.2,0.2-0.2,0.2c0,0,0.2-0.1,0.3-0.2C21,18.5,20.9,18.5,20.6,18.7 M65.7,20c0.6,0.6,1.2,1.3,1.3,1.4
		c0.1,0.1,0.2,0.2,0.2,0.2c0.1-0.1-1.6-1.8-2.2-2.4C64.8,19,65.1,19.3,65.7,20 M19.6,19.7c-0.2,0.2-0.3,0.3-0.2,0.3
		c0,0,0.2-0.1,0.4-0.3c0.2-0.2,0.3-0.3,0.2-0.3C19.9,19.4,19.8,19.5,19.6,19.7 M18.5,20.8l-0.3,0.4L18.5,20.8
		c0.2-0.2,0.4-0.3,0.4-0.3C18.9,20.4,18.8,20.5,18.5,20.8 M17.9,21.5c-0.1,0.1-0.2,0.2-0.1,0.2c0,0,0.2-0.1,0.3-0.3
		c0.1-0.1,0.2-0.2,0.1-0.2C18.1,21.2,18,21.4,17.9,21.5 M67.5,21.9c0.4,0.6,1.1,1.3,1.1,1.3c0,0,0,0-0.6-0.8
		c-0.3-0.3-0.5-0.6-0.5-0.6C67.4,21.8,67.4,21.9,67.5,21.9 M17.4,22.2c-0.3,0.4-0.4,0.5-0.2,0.3c0.2-0.2,0.4-0.5,0.3-0.5
		C17.5,22,17.4,22.1,17.4,22.2 M16.7,23c-0.1,0.2-0.3,0.3-0.3,0.4c-0.1,0.1-0.8,1.1-0.8,1.1c0,0,0.3-0.4,0.7-0.9
		C17,22.7,17.3,22.3,16.7,23 M68.7,23.5c0,0.1,0.2,0.2,0.2,0.3c0.1,0.1,0.1,0,0-0.2C68.7,23.3,68.6,23.3,68.7,23.5 M69.2,24.3
		c0.1,0.2,0.3,0.4,0.3,0.4c0.1,0-0.3-0.5-0.4-0.6C69.1,24,69.1,24.1,69.2,24.3 M69.5,24.8c0,0,0.1,0.1,0.2,0.2l0.2,0.2L69.8,25
		C69.7,24.8,69.5,24.7,69.5,24.8 M70.1,25.6c0,0,0.1,0.2,0.2,0.3l0.2,0.2l-0.1-0.3C70.1,25.5,70.1,25.4,70.1,25.6 M13.6,28
		c-0.6,1.1-0.7,1.4-0.6,1.4c0,0,0.2-0.3,0.3-0.6c0.1-0.3,0.4-0.9,0.6-1.2c0.2-0.4,0.3-0.7,0.3-0.7C14.2,26.9,13.9,27.4,13.6,28
		 M29.9,27.7c-2.7,0,0.9,0,7.9,0c7,0,12.8,0,12.8-0.1C50.7,27.6,36,27.6,29.9,27.7 M60.5,27.7c0.1,0,0,0.1-0.2,0.4
		c-0.2,0.2-0.3,0.3-0.3,0.3c0.1,0,0.7-0.6,0.7-0.7C60.7,27.7,60.6,27.6,60.5,27.7C60.4,27.6,60.4,27.6,60.5,27.7 M22.7,28.9
		c-0.6,0.6-1.1,1.1-1.1,1.1c0,0,0.6-0.5,1.2-1.1c0.6-0.6,1.1-1.1,1.1-1.1C23.9,27.8,23.4,28.3,22.7,28.9 M71.8,28.8
		c0.2,0.4,0.4,1,0.5,1c0,0,0,0,0-0.1C72.2,29.5,71.9,28.9,71.8,28.8c0.1-0.1,0-0.1,0-0.2C71.8,28.6,71.8,28.7,71.8,28.8 M58.4,30
		l-1,1L58.4,30c0.6-0.6,1.1-1,1.1-1C59.5,28.9,59.2,29.1,58.4,30 M12.8,29.8c-0.1,0.2-0.1,0.4-0.1,0.4c0,0,0.1,0,0.1-0.1
		c0-0.1,0.1-0.2,0.1-0.4c0.1-0.1,0.1-0.2,0.1-0.2C12.9,29.5,12.8,29.7,12.8,29.8 M72.4,30c0,0.1,0.2,0.7,0.3,0.7c0,0,0-0.2-0.1-0.4
		C72.4,29.9,72.4,29.8,72.4,30 M20.9,30.8c-0.3,0.3-0.6,0.6-0.6,0.6c0,0,0.3-0.2,0.7-0.6c0.3-0.3,0.6-0.6,0.6-0.6
		C21.5,30.2,21.2,30.4,20.9,30.8 M12.5,30.4c0,0-0.1,0.2-0.1,0.4c-0.1,0.2-0.1,0.3,0,0.3c0,0,0.1-0.1,0.1-0.2s0-0.2,0.1-0.2
		C12.6,30.6,12.6,30.5,12.5,30.4C12.6,30.3,12.5,30.3,12.5,30.4 M72.7,30.8c0,0.1,0,0.2,0.1,0.3c0,0.1,0.1,0.3,0.1,0.4
		c0,0.1,0.1,0.1,0.1,0.1c0,0,0-0.2-0.1-0.5C72.8,30.9,72.7,30.8,72.7,30.8 M12.2,31.2c0,0.1,0,0.2-0.1,0.3c0,0.1,0,0.1,0.1,0.1
		C12.3,31.4,12.3,31.3,12.2,31.2C12.2,31.1,12.2,31.1,12.2,31.2 M55.7,32.8c-0.8,0.8-1.4,1.4-1.2,1.3c0.5-0.4,2.9-2.8,2.8-2.8
		C57.2,31.3,56.5,32,55.7,32.8 M19.3,32.4c-0.5,0.5-0.9,1-0.9,1c0.1,0,2-1.9,1.9-1.9C20.2,31.5,19.8,31.9,19.3,32.4 M11.7,32.7
		c0,0.1,0,0.2-0.1,0.3c0,0.1,0,0.1,0.1,0.1C11.7,33,11.8,32.8,11.7,32.7C11.7,32.7,11.7,32.6,11.7,32.7 M17.6,34.1
		c-0.4,0.4-0.6,0.6-0.5,0.7c0.1,0.1,4.5,0.1,13.2,0.1c7.2,0,13.2,0,13.2,0c0,0-0.6,0.6-1.2,1.4L41,37.5l1.3-1.3
		c0.7-0.7,1.3-1.4,1.3-1.4c0-0.1-4.5-0.1-13.3-0.1c-12.7,0-13.3,0-13.2-0.1c0.1-0.1,0.3-0.4,0.6-0.6c0.3-0.3,0.5-0.5,0.4-0.5
		S17.9,33.8,17.6,34.1 M52.8,35.7c-0.7,0.7-1.3,1.4-1.3,1.4c0,0,0.6-0.6,1.4-1.4c0.7-0.8,1.3-1.4,1.3-1.4
		C54.1,34.4,53.5,35,52.8,35.7 M73.9,34.6c0,0.1,0,0.2,0.1,0.3C74.1,35.1,74.1,34.9,73.9,34.6l0-0.2L73.9,34.6 M11,35.4
		c0,0.1,0,0.2,0,0.3C11,35.7,11,35.6,11,35.4c0.1,0,0.1-0.2,0.1-0.2C11,35.2,11,35.3,11,35.4 M74.1,35.4c0,0.1,0,0.2,0.1,0.2
		C74.2,35.7,74.2,35.6,74.1,35.4C74.1,35.2,74.1,35.2,74.1,35.4 M10.8,36.2c0,0.1,0,0.3,0,0.3C10.8,36.5,10.8,36.4,10.8,36.2
		c0.1-0.1,0.1-0.2,0.1-0.2C10.9,36,10.8,36.1,10.8,36.2 M74.2,36.2c0,0.1,0,0.2,0.1,0.3C74.4,36.6,74.4,36.4,74.2,36.2l0-0.2
		L74.2,36.2 M10.6,37.1c0,0.2,0,0.4,0,0.4C10.6,37.6,10.7,37.4,10.6,37.1c0.1-0.1,0.1-0.3,0.1-0.3C10.7,36.8,10.7,37,10.6,37.1
		 M74.4,37.1c0,0.1,0,0.3,0.1,0.4l0.1,0.2v-0.2C74.5,37.5,74.5,37.3,74.4,37.1v-0.2V37.1 M17.1,37.3C17.2,37.5,17.2,37.5,17.1,37.3
		c0.1-0.1,0.4-0.1,5.1-0.1c2.7,0,4.9,0,4.9,0s-2.3-0.1-5-0.1l-4.9,0L17.1,37.3 M50.8,37.7c-0.3,0.3-0.6,0.6-0.5,0.6
		c0.1,0,1.2-1.1,1.1-1.2C51.4,37.2,51.1,37.4,50.8,37.7 M27.7,38c0.2,0.2,0.4,0.4,0.5,0.4c0,0-0.7-0.8-0.8-0.8
		C27.3,37.6,27.5,37.8,27.7,38 M10.5,38.3c0,0.2,0,0.5,0,0.7c0,0.2,0,0.1,0.1-0.3C10.6,38,10.6,37.8,10.5,38.3 M74.6,38.3
		c0,0.1,0,0.4,0.1,0.5l0.1,0.3v-0.3C74.7,38.7,74.7,38.4,74.6,38.3V38V38.3 M25.2,45.4c4,3.9,8.9,8.9,11,11c2.1,2.1-1.1-1.1-7.1-7.2
		s-11-11-11-11C18,38.3,21.2,41.5,25.2,45.4 M37.9,40.6c-1.2,1.2-2.1,2.2-2.2,2.3c0,0.1,2.4,2.6,2.5,2.6c0,0-0.5-0.5-1.1-1.2
		c-0.7-0.6-1.2-1.2-1.3-1.3c-0.1-0.1,0.1-0.4,1.5-1.8c2.1-2.1,2.7-2.8,2.7-2.8C40.1,38.4,39.1,39.4,37.9,40.6 M49.5,39.1
		c-0.2,0.2-0.6,0.7-1,1.1C48,40.6,47.7,41,47.7,41c0,0,0.5-0.5,1.1-1.1c0.6-0.6,1.1-1.1,1-1.1S49.6,38.9,49.5,39.1 M30,40.2
		c0.5,0.5,1,0.9,1,0.9C31,41.1,30.6,40.7,30,40.2c-0.4-0.5-0.9-0.9-0.9-0.9C29.1,39.3,29.5,39.7,30,40.2 M10.3,39.9
		c-0.1,0.7-0.1,4.6,0,5.3c0,0.3,0.1-0.9,0.1-2.7c0-1.7,0-3.2,0-3.2C10.4,39.4,10.3,39.6,10.3,39.9 M74.8,42.5c0,1.6,0,2.7,0.1,2.4
		c0-0.3,0.1-1.4,0.1-2.4c0-1,0-2.1-0.1-2.4C74.8,39.8,74.8,40.9,74.8,42.5 M37.9,48.1c3.7,3.7,6.8,6.8,6.8,6.8
		c0,0-8.5-8.5-11.9-11.8C31.9,42,34.2,44.3,37.9,48.1 M46.6,42c-0.4,0.4-0.8,0.8-0.8,0.9c0,0.1,3.6,3.7,3.7,3.7c0,0-0.8-0.8-1.8-1.8
		c-1-1-1.8-1.8-1.8-1.9c0-0.1,0.1-0.2,1-1.1c0.3-0.3,0.6-0.6,0.6-0.6C47.4,41.2,47,41.6,46.6,42 M39.1,46.3c0.4,0.4,0.8,0.8,0.8,0.8
		c0.1,0-1.1-1.3-1.4-1.5C38.4,45.6,38.7,45.9,39.1,46.3 M10.4,46.1c0,0.2,0,0.5,0.1,0.7c0,0.1,0,0,0-0.4
		C10.5,45.7,10.4,45.5,10.4,46.1 M74.6,46.4c0,0.3,0,0.5,0,0.5C74.6,46.9,74.7,46.7,74.6,46.4c0.1-0.2,0.1-0.4,0.1-0.4
		C74.7,45.9,74.6,46.1,74.6,46.4 M74.4,47.6c0,0.2,0,0.4,0,0.4C74.4,48,74.5,47.9,74.4,47.6c0.1-0.1,0.1-0.3,0.1-0.3
		C74.5,47.3,74.5,47.4,74.4,47.6 M50.6,47.8c0.2,0.2,0.4,0.4,0.5,0.4c0,0-0.7-0.8-0.8-0.8C50.2,47.4,50.4,47.5,50.6,47.8 M10.6,47.6
		c0,0.1,0,0.3,0.1,0.4l0.1,0.2l0-0.2C10.7,47.9,10.7,47.7,10.6,47.6l0-0.2L10.6,47.6 M74.3,48.5c0,0.1-0.1,0.2-0.1,0.3
		c0,0.1,0,0.1,0.1-0.1C74.4,48.6,74.4,48.5,74.3,48.5C74.4,48.4,74.3,48.4,74.3,48.5 M10.8,48.5c0,0.1,0,0.2,0.1,0.3l0.1,0.2l0-0.2
		C10.9,48.8,10.9,48.6,10.8,48.5C10.8,48.4,10.8,48.4,10.8,48.5 M42.3,49.5c0.4,0.4,0.8,0.8,0.8,0.8C43.1,50.2,42.9,50,42.3,49.5
		C41.4,48.5,41.4,48.5,42.3,49.5 M52,49.1c0,0.1,8.4,8.4,8.4,8.4c0,0-0.8-0.9-1.9-1.9c-1-1-2.9-2.9-4.2-4.2C53.1,50.1,52,49,52,49.1
		 M10.9,49.4c0,0.1,0,0.2,0.1,0.3l0.1,0.2v-0.2C11.1,49.6,11,49.4,10.9,49.4v-0.2V49.4 M74.1,49.3c0,0.1-0.1,0.2-0.1,0.3
		c0,0.1,0,0.1,0.1-0.1C74.2,49.5,74.2,49.3,74.1,49.3C74.2,49.2,74.1,49.3,74.1,49.3 M11.1,50.1c0,0.2,0.2,0.4,0.2,0.2
		C11.3,50.3,11.2,50.1,11.1,50.1C11.1,50.1,11.1,50.1,11.1,50.1 M73.8,50.7c0,0-0.1,0.1-0.1,0.2c0,0.2,0,0.2,0.1,0
		C73.9,50.7,73.9,50.6,73.8,50.7 M43.7,50.8c0,0.1,10.5,10.5,11.6,11.5l0.6,0.5h4.9c5.5,0,5.3,0,4.7-0.5c-0.1-0.1-0.2-0.2-0.2-0.1
		c0,0,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.2,0.2c0,0-2.2,0.1-4.9,0.1h-4.9l-3.2-3.2c-1.8-1.8-4.5-4.5-6-6C45,52,43.7,50.7,43.7,50.8
		 M73.6,51.3c0,0-0.1,0.1-0.1,0.2c0,0.2,0,0.2,0.1,0C73.7,51.3,73.7,51.2,73.6,51.3 M73.4,51.9c0,0-0.1,0.1-0.1,0.2
		c0,0.2,0,0.2,0.1,0C73.5,51.9,73.5,51.8,73.4,51.9 M73.3,52.4c0,0-0.1,0.1-0.1,0.2c0,0.2,0,0.2,0.1,0
		C73.4,52.4,73.4,52.3,73.3,52.4 M11.8,52.5c0,0.2,0.2,0.4,0.2,0.2C11.9,52.6,11.9,52.5,11.8,52.5C11.8,52.5,11.8,52.5,11.8,52.5
		 M12.3,54.1c0.1,0.3,0.3,0.6,0.3,0.5c0-0.1,0-0.2-0.1-0.2c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2-0.1-0.2C12.3,53.9,12.3,53.9,12.3,54.1
		 M72.5,54.7c-0.1,0.2-0.2,0.4-0.2,0.5c-0.1,0.1-0.2,0.6-0.3,0.7c0,0,0,0.1,0,0.1c0.1,0,0.3-0.5,0.6-1.2
		C72.7,54.3,72.7,54.2,72.5,54.7 M12.7,54.9c0,0.1,0.1,0.3,0.2,0.5c0.4,1,0.6,1.3,0.6,1.3c0,0,0,0,0-0.1c0,0-0.1-0.2-0.2-0.4
		c-0.1-0.2-0.2-0.5-0.4-0.8S12.8,55,12.8,55s0-0.1-0.1-0.2C12.7,54.8,12.6,54.8,12.7,54.9 M45.4,55.4c0,0.1,0.9,1,1,1
		c0,0-0.2-0.3-0.5-0.5C45.6,55.6,45.4,55.4,45.4,55.4 M71.6,56.5C71.3,57.1,71.4,57.2,71.6,56.5c0.2-0.2,0.3-0.4,0.3-0.4
		C71.9,56.1,71.8,56.3,71.6,56.5 M47.5,57.6c0.3,0.3,0.6,0.6,0.6,0.6c0,0-1.1-1.2-1.1-1.2C46.9,57,47.1,57.2,47.5,57.6 M60.5,57.5
		c0,0,0.9,0.9,2,2l2,2l-2-2C60.6,57.5,60.5,57.4,60.5,57.5 M14.2,58.2c0,0,0.1,0.2,0.2,0.3c0.1,0.1,0.2,0.2,0.2,0.1
		s-0.1-0.2-0.2-0.3C14.3,58.2,14.2,58.2,14.2,58.2 M48.9,58.9c0,0.1,0.3,0.4,1.3,1.3c0.9,0.9,0.7,0.6-0.3-0.4
		C49.3,59.2,48.9,58.8,48.9,58.9 M14.8,59.1c0,0,0.1,0.2,0.2,0.3c0.1,0.1,0.2,0.2,0.2,0.1c0,0-0.1-0.2-0.2-0.3
		C14.8,59.1,14.8,59,14.8,59.1 M69.9,59.6c-0.2,0.4-0.3,0.6-0.1,0.3c0.2-0.2,0.4-0.6,0.3-0.6C70.2,59.3,70.1,59.4,69.9,59.6
		 M69.5,60.3c-0.2,0.3-0.1,0.3,0.1,0c0.1-0.1,0.1-0.2,0.1-0.2C69.6,60.1,69.5,60.2,69.5,60.3 M15.8,60.7c0.1,0.2,1.7,2.4,1.8,2.4
		c0.1,0,0,0-0.1-0.2c-0.1-0.1-0.4-0.5-0.7-0.9C15.9,60.7,15.7,60.5,15.8,60.7 M68.8,61.2c-0.2,0.3-0.4,0.6-0.3,0.6s0.2-0.3,0.5-0.6
		c0.2-0.3,0.4-0.6,0.3-0.6C69.3,60.6,69.1,60.9,68.8,61.2 M51.7,61.7c0.5,0.5,0.8,0.9,0.8,1c0,0.1-1.1,0.1-4.9,0.1h-4.9l-0.6-0.6
		l-0.6-0.6l0.6,0.6l0.6,0.6h5c3.7,0,5,0,5-0.1C52.7,62.7,52.3,62.2,51.7,61.7l-0.8-0.9L51.7,61.7 M67.9,62.5
		c-0.7,0.8-0.7,0.8-0.6,0.8c0,0,0.3-0.3,0.5-0.6c0.7-0.8,0.7-0.8,0.6-0.8C68.4,61.9,68.2,62.2,67.9,62.5 M18.4,64
		c0.3,0.3,0.5,0.6,0.5,0.6c0.1,0-0.9-1.1-0.9-1.1C17.9,63.4,18.1,63.7,18.4,64 M66.7,63.9c-0.3,0.3-2.2,2.3-2.8,2.8
		c-0.2,0.2-0.4,0.4-0.4,0.4c0.1,0.1,1.6-1.4,2.6-2.4c0.8-0.8,0.9-1,0.8-1C67,63.6,66.9,63.7,66.7,63.9 M19.1,64.7
		c0.4,0.5,2.5,2.4,2.5,2.3c0-0.1,0-0.1-0.1-0.1c0,0-0.6-0.5-1.3-1.2C19.5,65.1,19,64.7,19.1,64.7 M22.1,67.6c0.1,0.1,0.3,0.3,1,0.8
		c0.1,0,0.1,0.1,0.1,0c0-0.1-1.2-1-1.2-1C22,67.4,22,67.5,22.1,67.6 M62.4,68c-0.4,0.3-1.1,0.8-1.6,1.1c-0.8,0.6-1.2,0.9-0.9,0.7
		c0.3-0.1,1.4-1,2.2-1.5c1.1-0.8,1.1-0.8,1-0.8C63.1,67.5,62.7,67.7,62.4,68 M23.4,68.5c0,0,0.4,0.3,0.9,0.7c0.9,0.6,1.2,0.8,1,0.6
		C25.2,69.7,23.3,68.4,23.4,68.5 M59.1,70.2l-0.2,0.2L59.1,70.2c0.2,0,0.3-0.1,0.3-0.1C59.4,70,59.3,70.1,59.1,70.2 M26.9,70.8
		c0,0,0.1,0.1,0.3,0.2c0.1,0.1,0.3,0.1,0.3,0.1s-0.1-0.1-0.3-0.2C27,70.8,26.9,70.8,26.9,70.8 M28.2,71.5c0,0.1,0.9,0.5,1,0.5
		c0,0,0-0.1-0.2-0.1C28.7,71.6,28.2,71.4,28.2,71.5 M56.5,71.6c-0.2,0.1-0.3,0.2-0.2,0.2c0.1,0,0.2-0.1,0.4-0.2
		C57.1,71.4,56.9,71.4,56.5,71.6 M29.3,72c0,0.1,0.8,0.4,0.8,0.3c0,0-0.1-0.1-0.2-0.1c-0.1,0-0.3-0.1-0.4-0.2
		C29.4,72,29.3,72,29.3,72 M55.4,72.1c-0.2,0.1-0.3,0.2-0.4,0.2c-0.1,0.1,0.5-0.1,0.7-0.3C56,71.9,55.7,71.9,55.4,72.1 M30.4,72.5
		c0.3,0.2,1.5,0.6,1.5,0.5c0,0,0-0.1-0.1-0.1c-0.7-0.2-1-0.3-1.2-0.5c-0.1,0-0.2-0.1-0.3-0.1C30.2,72.4,30.3,72.4,30.4,72.5
		 M53.7,72.8c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.1,0.1-0.1,0.1C53.3,73,53.5,73,53.7,72.8c0.2,0,0.4-0.1,0.3-0.1
		C53.9,72.7,53.8,72.8,53.7,72.8 M32.7,73.3c0.2,0.1,0.3,0.1,0.3,0C32.9,73.3,32.8,73.2,32.7,73.3C32.5,73.2,32.5,73.2,32.7,73.3
		 M52.2,73.3c-0.1,0.1,0,0.1,0.3,0c0.2-0.1,0.2-0.1,0-0.1C52.4,73.2,52.3,73.3,52.2,73.3 M33.2,73.5c0.2,0.1,0.3,0.1,0.3,0
		C33.4,73.4,33.3,73.4,33.2,73.5C33,73.4,33,73.4,33.2,73.5 M34.4,73.8c0.2,0.1,0.3,0.1,0.3,0C34.7,73.8,34.6,73.7,34.4,73.8
		C34.3,73.7,34.3,73.7,34.4,73.8 M50.4,73.8c-0.1,0.1,0,0.1,0.3,0c0.2-0.1,0.2-0.1,0-0.1C50.6,73.7,50.5,73.8,50.4,73.8 M35.1,74
		c0.3,0.1,0.5,0.1,0.3,0C35.4,73.9,35.2,73.9,35.1,74L35,73.9L35.1,74 M49.7,74c-0.2,0.1,0,0.1,0.3,0l0.2-0.1l-0.2,0
		C49.9,73.9,49.8,73.9,49.7,74 M35.9,74.2c0.3,0.1,0.5,0.1,0.3,0C36.1,74.1,36,74.1,35.9,74.2l-0.2-0.1L35.9,74.2 M49,74.2
		c-0.2,0.1,0,0.1,0.3,0l0.2-0.1l-0.2,0C49.2,74.1,49,74.1,49,74.2 M36.7,74.3c0.3,0.1,0.5,0.1,0.3,0C37,74.3,36.8,74.2,36.7,74.3
		l-0.2-0.1L36.7,74.3 M48.1,74.3c-0.1,0.1,0.1,0.1,0.3,0c0.2-0.1,0.1-0.1-0.1-0.1C48.2,74.2,48.1,74.3,48.1,74.3 M37.8,74.5
		c0.1,0,0.3,0.1,0.4,0.1h0.2l-0.2-0.1C38.1,74.5,37.9,74.4,37.8,74.5l-0.2-0.1L37.8,74.5 M46.9,74.5l-0.3,0.1L46.9,74.5
		c0.1,0.1,0.4,0,0.5,0l0.2-0.1l-0.2,0C47.2,74.4,47,74.5,46.9,74.5 M39.1,74.7c0.1,0,0.4,0.1,0.6,0.1l0.4,0l-0.3-0.1
		C39.6,74.6,39.3,74.6,39.1,74.7l-0.3-0.1L39.1,74.7 M45.3,74.7C45,74.7,45,74.7,45.3,74.7c0.3,0.1,0.6,0,0.7,0
		C46.4,74.6,45.7,74.6,45.3,74.7 M41.7,74.8c0.5,0,1.2,0,1.7,0c0.5,0,0.1,0-0.9,0C41.6,74.8,41.2,74.8,41.7,74.8"
          />
          <path
            id="path4"
            className="st4"
            d="M32.1,1.2C32,1.2,32.1,1.2,32.1,1.2c0.1,0.1,0.2,0,0.3,0c0,0,0-0.1-0.1-0.1S32.1,1.1,32.1,1.2
		 M30.2,1.7c0,0.1,0.2,0.1,0.3,0c0,0,0-0.1-0.1-0.1C30.3,1.6,30.2,1.6,30.2,1.7 M56.6,2.3C56.6,2.3,56.6,2.4,56.6,2.3
		c0.1,0,0.2,0,0.2,0.1c0.1,0.1,0.5,0.2,0.4,0.1c0,0-0.1-0.1-0.2-0.1c-0.1,0-0.2,0-0.2-0.1C56.8,2.1,56.6,2.2,56.6,2.3 M27.7,2.5
		l-0.3,0.1L27.7,2.5c0.2,0,0.3,0,0.4-0.1C28.2,2.3,28.1,2.3,27.7,2.5 M27.1,2.7c-0.1,0-0.2,0.1-0.2,0.1c0,0.1,0.1,0,0.3-0.1
		C27.4,2.6,27.4,2.6,27.1,2.7 M62.7,5C62.7,5,62.7,5.1,62.7,5C62.8,5,62.9,5.1,63,5.1c0.1,0.1,0.2,0.1,0.2,0c0,0-0.1-0.1-0.1-0.1
		c-0.1,0-0.2-0.1-0.3-0.1C62.7,4.9,62.7,4.9,62.7,5 M64.1,5.8c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0,0c-0.1,0-0.1-0.1-0.1-0.1
		c0,0-0.1-0.1-0.1-0.1C64,5.7,64,5.7,64.1,5.8 M65.5,6.7c0.2,0.1,0.3,0.2,0.3,0c0,0,0,0-0.1,0s-0.1,0-0.1-0.1c0-0.1-0.1-0.1-0.2-0.1
		C65.4,6.5,65.4,6.5,65.5,6.7 M18.8,7c-0.1,0.1-0.2,0.2-0.1,0.2c0,0,0.2-0.1,0.3-0.2c0.1-0.1,0.2-0.2,0.1-0.2S19,6.9,18.8,7
		 M16.4,8.8c-0.2,0.1-0.3,0.3-0.2,0.3c0,0,0.2-0.1,0.3-0.3C16.8,8.5,16.8,8.5,16.4,8.8 M70.1,9.9c0,0,0.1,0.2,0.2,0.3
		c0.2,0.2,0.2,0.2,0.1,0C70.2,9.9,70.1,9.8,70.1,9.9 M39.7,10.1c-0.1,0,1.2,0.1,2.8,0.1c1.6,0,2.9,0,2.8-0.1
		C45.2,10,39.9,10,39.7,10.1 M38.2,10.3c0,0.1,0.2,0.1,0.5,0c0.8-0.1,0.8-0.1,0.1-0.1C38.5,10.2,38.2,10.2,38.2,10.3 M45.8,10.2
		c0.1,0.1,1.2,0.1,1.1,0.1c0,0-0.3-0.1-0.6-0.1C46,10.2,45.8,10.2,45.8,10.2 M37.1,10.4c0,0.1,0.1,0.1,0.4,0
		c0.6-0.1,0.6-0.1,0.1-0.1C37.3,10.3,37.1,10.4,37.1,10.4 M47.2,10.4c0.1,0.1,0.9,0.1,0.9,0.1c0,0-0.3-0.1-0.5-0.1
		C47.3,10.3,47.2,10.4,47.2,10.4 M36.2,10.6c-0.1,0.1,0,0.1,0.3,0c0.2,0,0.4-0.1,0.5-0.1C37,10.5,36.3,10.5,36.2,10.6 M48.2,10.5
		c0.1,0.1,0.8,0.1,0.7,0.1c0,0-0.2-0.1-0.4-0.1C48.3,10.5,48.2,10.5,48.2,10.5 M35.4,10.8c-0.1,0.1,0.1,0.1,0.4,0
		c0.2-0.1,0.2-0.1-0.1-0.1C35.6,10.7,35.4,10.7,35.4,10.8 M49.2,10.7c0.1,0.1,0.5,0.1,0.5,0.1c0,0-0.1-0.1-0.3-0.1
		C49.3,10.7,49.2,10.7,49.2,10.7 M34.7,10.9c0,0.1,0,0.1,0.2,0c0.3-0.1,0.4-0.1,0.1-0.1C34.9,10.9,34.7,10.9,34.7,10.9 M50.1,10.9
		c0.2,0.1,0.4,0.1,0.3,0C50.4,10.9,50.3,10.9,50.1,10.9C50,10.9,50,10.9,50.1,10.9 M71.2,10.9c0,0.1,0.8,0.9,1.1,1
		c0.1,0.1-0.1-0.2-0.8-0.9C71.3,10.9,71.2,10.9,71.2,10.9 M34,11.1c-0.1,0.1,0.1,0.1,0.3,0c0.1-0.1,0.1-0.1-0.1-0.1
		C34.2,11,34.1,11.1,34,11.1 M50.8,11.1c0.2,0.1,0.4,0.1,0.3,0C51.1,11.1,51,11,50.8,11.1C50.6,11,50.6,11,50.8,11.1 M33.5,11.3
		c0,0.1,0.1,0.1,0.3,0c0.1-0.1,0.1-0.1-0.1-0.1C33.6,11.2,33.5,11.2,33.5,11.3 M51.4,11.3c0.2,0.1,0.4,0.1,0.3,0
		C51.7,11.2,51.6,11.2,51.4,11.3C51.3,11.2,51.3,11.2,51.4,11.3 M32.9,11.5c0,0.1,0.1,0.1,0.3,0c0.1-0.1,0.1-0.1-0.1-0.1
		C33,11.4,32.9,11.4,32.9,11.5 M13,11.8c-0.1,0.1-0.2,0.2-0.1,0.2c0.1,0,0.4-0.3,0.4-0.4C13.2,11.6,13.1,11.6,13,11.8 M32.4,11.6
		c0,0.1,0.1,0.1,0.3,0c0.1-0.1,0.1-0.1-0.1-0.1C32.4,11.5,32.4,11.6,32.4,11.6 M52.5,11.6c0.2,0.1,0.3,0.1,0.3,0
		C52.8,11.6,52.7,11.5,52.5,11.6C52.4,11.5,52.4,11.6,52.5,11.6 M31.9,11.8C31.7,11.9,31.7,11.9,31.9,11.8c0.1,0.1,0.2,0,0.3,0
		c0.1-0.1,0.1-0.1,0-0.1C32,11.7,31.9,11.7,31.9,11.8 M30,12.5c-0.3,0.1-0.7,0.3-0.8,0.3c-0.1,0-0.7,0.3-1.3,0.6
		c-0.6,0.3-1.2,0.6-1.3,0.7c-0.1,0.1-0.2,0.1-0.2,0.1c0,0,0.7-0.3,1.5-0.7c0.8-0.4,1.6-0.8,1.9-0.9c0.6-0.2,1.1-0.5,1-0.5
		C30.7,12.2,30.4,12.3,30,12.5 M54.4,12.3c0,0,0.1,0.1,0.2,0.1c0.1,0,0.2,0,0.2,0.1c0,0,0.2,0.1,0.4,0.2c0.4,0.2,1.3,0.6,2,0.9
		c0.3,0.2,0.6,0.3,0.6,0.2C57.7,13.6,54.7,12.2,54.4,12.3C54.4,12.2,54.4,12.3,54.4,12.3 M11.7,13c-0.1,0.1-0.2,0.2-0.1,0.2
		c0.1,0,0.4-0.3,0.3-0.4C11.9,12.8,11.8,12.9,11.7,13 M73.2,12.9c0,0.1,0.5,0.6,0.5,0.5c0,0-0.1-0.2-0.3-0.3
		C73.3,12.9,73.2,12.8,73.2,12.9 M73.8,13.6c0,0.1,0.4,0.5,0.5,0.4c0,0,0-0.1-0.1-0.2C74,13.8,74,13.7,74,13.6
		C74,13.5,73.9,13.5,73.8,13.6C73.8,13.5,73.8,13.5,73.8,13.6 M57.9,13.9c0,0,0.1,0.1,0.3,0.2c0.1,0.1,0.3,0.1,0.3,0.1
		S58.3,14,58.2,14c-0.1,0-0.2-0.1-0.3-0.1C57.9,13.8,57.9,13.8,57.9,13.9 M26,14.5c-0.1,0.1-0.2,0.1-0.2,0.2c0,0.1,0.1,0.1,0.3-0.1
		C26.4,14.3,26.3,14.3,26,14.5 M59,14.5c0.2,0.2,0.6,0.4,0.6,0.3c0-0.1-0.7-0.5-0.7-0.5C58.8,14.3,58.9,14.4,59,14.5 M74.9,14.7
		c0,0,0.1,0.2,0.3,0.4c0.3,0.3,0.3,0.3,0.1,0C75,14.7,74.9,14.7,74.9,14.7 M24.9,15.2c-0.1,0.1-0.1,0.1-0.1,0.2c0,0,0.1,0,0.2-0.1
		c0.1-0.1,0.2-0.2,0.1-0.2C25.1,15,25,15.1,24.9,15.2 M60,15.1c0,0,0.4,0.3,0.7,0.5c0.7,0.5,2.1,1.5,2.7,2.1
		c0.2,0.2,0.4,0.3,0.4,0.3c0-0.1-1.6-1.5-2.9-2.3C60.1,15.1,59.7,14.9,60,15.1 M24.2,15.6c-0.5,0.4-1.6,1.2-1.8,1.3
		c-0.1,0-0.2,0.2-0.4,0.3c-0.4,0.3-0.8,0.7-0.8,0.7c0,0,0.2-0.1,0.5-0.4c0.6-0.5,1.7-1.3,2.4-1.8c0.3-0.2,0.5-0.3,0.5-0.4
		C24.7,15.3,24.5,15.4,24.2,15.6 M9.1,16c-0.3,0.5-0.3,0.5-0.1,0.3c0.2-0.2,0.3-0.4,0.2-0.4C9.2,15.9,9.1,15.9,9.1,16 M20.7,18.5
		c-0.7,0.6-2,1.9-2.4,2.4c-0.2,0.2,0,0,0.5-0.4c0.4-0.5,1.2-1.2,1.6-1.6C21.3,17.9,21.5,17.7,20.7,18.5 M64.8,18.9
		c0.4,0.4,1.1,1.1,1.5,1.5c0.6,0.6,0.6,0.6,0.3,0.3c-0.5-0.6-1.9-2-2.3-2.3C64.2,18.2,64.4,18.5,64.8,18.9 M7.2,18.7
		C7.1,18.8,7.1,18.8,7.2,18.7c0.2,0,0.2-0.2,0.1-0.2C7.2,18.5,7.2,18.6,7.2,18.7 M6.4,19.8c-0.2,0.2-0.2,0.2,0,0.2
		c0.1,0,0.1-0.1,0.2-0.2C6.6,19.7,6.5,19.7,6.4,19.8 M5.5,21.4c-0.1,0.1-0.1,0.2-0.1,0.2c0.1,0,0.3-0.4,0.2-0.5
		C5.6,21.2,5.5,21.3,5.5,21.4 M17.6,21.6c-0.4,0.4-0.5,0.7-0.4,0.6c0.2-0.1,0.8-0.9,0.7-0.9C17.9,21.3,17.7,21.5,17.6,21.6
		 M67.3,21.5c0.1,0.1,0.1,0.2,0.2,0.2c0.3,0.3,1.6,2.1,2.4,3.2c0.3,0.4,0.5,0.7,0.5,0.7c0,0-0.9-1.5-1.1-1.7
		c-0.1-0.1-0.1-0.1-0.1-0.2c0,0,0-0.1-0.1-0.2c-0.1-0.1-0.2-0.2-0.3-0.4c-0.2-0.3-0.3-0.4-0.7-0.8c-0.1-0.1-0.3-0.4-0.5-0.6
		c-0.2-0.2-0.3-0.4-0.3-0.4C67.2,21.3,67.2,21.4,67.3,21.5 M16.8,22.6c-0.1,0.1-0.3,0.4-0.4,0.6c-0.5,0.7-0.9,1.2-1,1.4
		c-0.2,0.3,0,0.1,0.6-0.7c0.3-0.4,0.7-0.9,0.8-1.1c0.2-0.2,0.3-0.4,0.2-0.4C17,22.3,16.9,22.4,16.8,22.6 M4.2,23.7
		c0,0.1,0,0.1,0.1,0.1c0,0,0.1-0.1,0.1-0.1s0-0.1-0.1-0.1C4.2,23.6,4.2,23.7,4.2,23.7 M15,25.3c-0.1,0.1-0.1,0.2-0.1,0.3
		C14.9,25.6,15,25.5,15,25.3C15.2,25,15.2,25,15,25.3 M70.3,25.7c0,0,0.1,0.2,0.2,0.3c0.1,0.1,0.2,0.2,0.1,0.1
		c0-0.1-0.1-0.2-0.1-0.3C70.5,25.7,70.3,25.6,70.3,25.7 M14.5,26.1c0,0.1-0.2,0.3-0.3,0.5c-0.4,0.6-0.7,1.3-0.7,1.4
		c0,0.1,0.2-0.2,0.4-0.6c0.2-0.4,0.5-0.9,0.6-1.1c0.1-0.2,0.2-0.4,0.2-0.4C14.6,25.9,14.5,26,14.5,26.1 M81.9,26.3
		c0.1,0.2,0.2,0.3,0.2,0.1C82.1,26.4,82.1,26.3,81.9,26.3C81.9,26.2,81.9,26.2,81.9,26.3 M70.8,26.6c0.1,0.2,0.3,0.5,0.3,0.6
		c0.1,0.1,0.1,0.2,0.1,0.2c0,0-0.1-0.2-0.2-0.4c-0.1-0.2-0.2-0.4-0.3-0.5s-0.1-0.1-0.1-0.1C70.7,26.3,70.7,26.4,70.8,26.6
		 M82.3,27.2c0,0.1,0.1,0.2,0.1,0.2C82.5,27.4,82.5,27.2,82.3,27.2C82.3,27,82.2,27,82.3,27.2 M82.6,27.9c0,0.2,0.1,0.3,0.1,0.3
		c0.1,0,0.1,0,0,0C82.7,28.1,82.6,28,82.6,27.9C82.6,27.6,82.6,27.6,82.6,27.9 M39.9,27.8c-2.7,0,0.7,0,7.7,0c7,0,12.7,0,12.7,0
		s-0.8,0.9-1.8,1.9c-1,1-2.3,2.4-3,3.1c-0.8,0.8-0.5,0.6,0.7-0.6c1-1,2.5-2.5,3.1-3.2l1.2-1.3l-7.9,0C48.4,27.7,42.6,27.8,39.9,27.8
		 M71.5,27.8c0,0,0.1,0.2,0.2,0.4c0.2,0.4,0.7,1.5,1,2.2c0,0.1,0.1,0.3,0.2,0.4c0,0.1,0.1,0.4,0.2,0.6c0.1,0.2,0.2,0.4,0.2,0.4
		c0.1-0.1-0.7-2-1.2-3.1C71.6,27.9,71.5,27.7,71.5,27.8 M22.7,29.3c-0.8,0.8-1.2,1.2-0.9,0.9c0.4-0.4,1.7-1.7,2.3-2.3c0,0,0,0,0,0
		C24.1,27.9,23.4,28.5,22.7,29.3 M2.4,28.1c-0.1,0.3-0.1,0.6-0.1,0.5C2.4,28.5,2.5,28,2.5,28C2.4,28,2.4,28,2.4,28.1 M13.2,28.4
		c-0.1,0.2-0.2,0.5-0.4,0.9c-0.4,0.9-0.7,1.6-0.6,1.6c0,0,0.1-0.2,0.3-0.6c0.2-0.6,0.6-1.5,0.9-2c0.1-0.1,0.1-0.2,0.1-0.2
		S13.3,28.3,13.2,28.4 M19.2,32.7c-0.9,0.9-1.3,1.3-0.9,1c1.2-1.2,2.6-2.6,2.6-2.6C20.9,31.1,20.1,31.8,19.2,32.7 M11.7,32.1
		c0,0.1,0,0.2,0,0.2C11.7,32.4,11.8,32.3,11.7,32.1c0.1,0,0.1-0.1,0.1-0.1C11.8,32,11.8,32,11.7,32.1 M73.3,32.2
		c0,0.1,0,0.2,0.1,0.2c0,0,0.1,0,0.1-0.1C73.5,32.2,73.4,32.1,73.3,32.2C73.3,32.1,73.3,32.1,73.3,32.2 M11.5,32.7
		C11.5,32.9,11.5,32.9,11.5,32.7c0.2-0.1,0.2-0.2,0.1-0.2C11.6,32.6,11.5,32.6,11.5,32.7 M73.5,32.6c0,0.2,0.2,0.4,0.2,0.2
		C73.6,32.7,73.6,32.6,73.5,32.6C73.5,32.6,73.5,32.6,73.5,32.6 M11.4,33.1c0,0-0.1,0.1-0.1,0.2c0,0.2,0,0.2,0.1,0
		C11.5,33.1,11.5,33,11.4,33.1 M11.2,33.7c0,0-0.1,0.1-0.1,0.2c0,0.2,0,0.2,0.1,0C11.4,33.7,11.3,33.6,11.2,33.7 M11.1,34.3
		c0,0-0.1,0.2-0.1,0.3l0,0.2l0.1-0.2C11.2,34.4,11.2,34.2,11.1,34.3 M74,34.4c0,0.2,0.2,0.4,0.2,0.2C74.1,34.5,74.1,34.4,74,34.4
		C74,34.4,74,34.4,74,34.4 M17.4,34.6l-0.1,0.2l12.1-0.1c6.7,0,12.1-0.1,12.1-0.1c0,0-5.4,0-12,0c-8,0-12,0-12-0.1c0,0,0-0.1,0-0.1
		C17.5,34.4,17.4,34.5,17.4,34.6 M53.7,34.6c-0.1,0.1-0.1,0.1-0.1,0.2c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2
		C53.9,34.4,53.8,34.5,53.7,34.6 M43.7,34.6c0.1,0,0.1,0.1,0.1,0.1s0,0.1,0,0.1s0.1-0.1,0.1-0.1C43.9,34.7,43.9,34.6,43.7,34.6
		C43.7,34.6,43.7,34.6,43.7,34.6 M10.9,35c0,0-0.1,0.2,0,0.3l0,0.3l0.1-0.3C11,35,11,34.9,10.9,35 M42.4,36.3
		c-0.7,0.8-1.8,1.8-2.3,2.3c-1.9,1.9-4.1,4.2-4.1,4.3c0,0.1,13.2,13.2,13.9,13.8c0.5,0.5-4.3-4.3-9.9-9.9L36,42.9l0.6-0.6
		c0.3-0.3,1.5-1.5,2.6-2.7c1.1-1.2,2.5-2.6,3.1-3.2c1.2-1.2,1.5-1.6,1.5-1.6C43.7,34.9,43.1,35.6,42.4,36.3 M52.3,36
		c-0.5,0.5-0.8,0.8-0.8,0.8c0.1,0,1.7-1.6,1.7-1.7C53.2,35.1,52.8,35.5,52.3,36 M74.1,35.2c0,0.2,0.2,0.4,0.2,0.2
		C74.3,35.2,74.3,35.1,74.1,35.2C74.2,35.1,74.1,35.1,74.1,35.2 M10.7,35.8c0,0-0.1,0.2,0,0.3l0,0.3l0.1-0.3
		C10.8,35.8,10.8,35.6,10.7,35.8 M74.4,36.1c0.1,0.4,0.1,0.5,0.1,0.2c0-0.1,0-0.3-0.1-0.4C74.3,35.8,74.3,35.8,74.4,36.1 M10.6,36.6
		c0,0-0.1,0.2,0,0.4l0,0.4l0.1-0.4C10.7,36.6,10.7,36.5,10.6,36.6 M74.5,37c0.1,0.4,0.1,0.5,0.1,0.1c0-0.2,0-0.3-0.1-0.4
		C74.5,36.7,74.5,36.8,74.5,37 M50.1,38.2c-5.1,5.2-4.7,4.6-4,5.3c0.3,0.2,0.5,0.4,0.5,0.4c0,0-0.2-0.3-0.4-0.5
		c-0.6-0.5-0.6-0.4,1-2.1c3.5-3.5,4.4-4.5,4.3-4.5C51.4,36.9,50.8,37.5,50.1,38.2 M21.2,41.3c3.9,3.9,6,5.9,6,5.9c0,0-2.2-2.2-4.9-5
		c-2.7-2.7-5-5-5-5C17.3,37.3,19,39.1,21.2,41.3 M26.9,37.3c0,0.1,7.1,7.1,7.3,7.2c0.1,0-0.5-0.6-1.4-1.5
		C30.9,41.2,26.9,37.3,26.9,37.3 M10.4,37.6c0,0.1-0.1,0.4,0,0.6l0,0.5l0.1-0.6C10.5,37.6,10.5,37.3,10.4,37.6 M74.7,38.1
		c0.1,0.6,0.1,0.6,0.1,0.1c0-0.2,0-0.5-0.1-0.5C74.7,37.7,74.7,37.8,74.7,38.1 M10.2,38.8c0,0-0.1,0.4,0,0.8l0,0.8l0.1-0.8
		C10.3,38.8,10.3,38.7,10.2,38.8 M74.9,39.9C74.9,41,75,41.1,75,40c0-0.5,0-0.9-0.1-0.9C74.9,39,74.8,39.3,74.9,39.9 M0.1,39.9
		c0,0.2,0,0.3,0,0.2c0-0.1,0-0.3,0-0.4C0.1,39.6,0.1,39.7,0.1,39.9 M10,40.9c-0.1,0.1-0.1,2.8,0,3c0,0.1,0.1-0.5,0.1-1.5
		C10.2,41.6,10.1,40.9,10,40.9C10.1,40.9,10.1,40.9,10,40.9 M75,42.5c0,0.6,0,0.8,0.1,0.8c0,0,0.1-0.4,0.1-0.8s0-0.8-0.1-0.8
		C75,41.7,75,42,75,42.5 M46.8,44.1c0,0.1,0.9,1,1,1c0,0-0.2-0.3-0.5-0.5C47.1,44.3,46.8,44.1,46.8,44.1 M74.9,45c0,0.5,0,0.9,0,0.9
		C75,46,75,45.8,74.9,45l0.1-0.9L74.9,45 M10.2,45.3c0,0.4,0,0.8,0.1,0.8c0.1,0.1,0.1-0.1,0-0.7C10.2,44.5,10.2,44.4,10.2,45.3
		 M35.9,46.2c0.7,0.7,1.2,1.2,1.2,1.2C37.1,47.4,36.6,46.9,35.9,46.2c-0.6-0.7-1.1-1.2-1.1-1.2S35.2,45.6,35.9,46.2 M52.2,49.6
		c2.2,2.2,4.2,4.1,4.4,4.3c0.3,0.2-2.1-2.1-6.2-6.2c-1.1-1.1-2.1-2.1-2.2-2.1C48.2,45.6,50,47.4,52.2,49.6 M10.3,46.8
		c0,0.3,0,0.5,0.1,0.6c0.1,0,0.1-0.1,0-0.5C10.4,46.2,10.3,46.2,10.3,46.8 M74.7,46.5c0,0.1,0,0.4-0.1,0.5c0,0.2,0,0.3,0,0.2
		c0.1-0.1,0.1-0.3,0.1-0.5C74.8,46.5,74.8,46.4,74.7,46.5 M10.5,47.9c0,0.2,0,0.5,0.1,0.5c0.1,0,0.1-0.1,0-0.4
		C10.5,47.4,10.5,47.4,10.5,47.9 M74.6,47.7c0,0.1,0,0.3-0.1,0.4c0,0.2,0,0.2,0,0.1c0.1-0.1,0.1-0.2,0.1-0.4
		C74.6,47.6,74.6,47.6,74.6,47.7 M28.3,48.2c0.3,0.4,1.7,1.7,2,2c0.2,0.2-0.1-0.2-0.8-0.8C28.9,48.8,28.3,48.2,28.3,48.2
		C28.3,48.2,28.3,48.2,28.3,48.2 M38.9,49.2c0.6,0.6,1,1,1.1,1c0,0,0-0.1-1.3-1.3C38.1,48.4,38.1,48.5,38.9,49.2 M74.4,48.6
		c0,0.1,0,0.2-0.1,0.3c0,0.2,0,0.2,0.1,0.1c0.1-0.1,0.1-0.2,0.1-0.3S74.4,48.6,74.4,48.6 M10.7,48.9c0,0.2,0,0.3,0.1,0.3
		c0,0,0.1-0.1,0-0.3C10.7,48.6,10.7,48.6,10.7,48.9 M10.8,49.7c0,0.1,0,0.3,0.1,0.3C11,50.2,11,50,10.8,49.7l0-0.2L10.8,49.7
		 M74.2,49.6c-0.1,0.2,0,0.3,0.1,0.2C74.3,49.9,74.3,49.7,74.2,49.6l0.1-0.2L74.2,49.6 M74,50.3c-0.1,0.2,0,0.3,0.1,0.2
		C74.1,50.5,74.1,50.4,74,50.3l0.1-0.2L74,50.3 M11,50.4c0,0.1,0,0.2,0.1,0.2C11.2,50.7,11.2,50.6,11,50.4C11,50.2,11,50.2,11,50.4
		 M40.4,50.6c0,0.1,0.9,1,1,1c0,0-0.2-0.3-0.5-0.5C40.6,50.8,40.4,50.6,40.4,50.6 M84.3,50.6c-0.1,0.1-0.1,0.3,0,0.3
		c0,0,0.1-0.1,0.1-0.2C84.4,50.6,84.4,50.6,84.3,50.6 M73.9,50.9c0,0.1,0,0.2-0.1,0.3c0,0.1,0,0.1,0.1,0.1
		C73.9,51.2,74,51,73.9,50.9C73.9,50.9,73.9,50.8,73.9,50.9 M11.2,51.1c0,0.1,0,0.2,0.1,0.2C11.4,51.4,11.4,51.2,11.2,51.1
		C11.2,50.9,11.2,50.9,11.2,51.1 M73.7,51.6c-0.1,0.2-0.1,0.4,0,0.3C73.8,51.8,73.8,51.6,73.7,51.6C73.8,51.4,73.7,51.4,73.7,51.6
		 M11.3,51.7c0,0.1,0,0.2,0.1,0.2C11.5,52,11.5,51.8,11.3,51.7C11.4,51.5,11.4,51.5,11.3,51.7 M32.9,52.9c0.7,0.8,1.4,1.4,1.4,1.4
		c0.1,0-1.4-1.5-2.3-2.3C31.8,51.7,32.2,52.1,32.9,52.9 M42.4,52.7c0.5,0.5,0.9,0.8,0.9,0.8c0,0-1.6-1.7-1.7-1.7
		C41.5,51.9,41.9,52.2,42.4,52.7 M73.5,52.2c0,0.1,0,0.2,0,0.2C73.5,52.5,73.6,52.4,73.5,52.2c0.1,0,0.1-0.1,0.1-0.1
		C73.6,52,73.5,52.1,73.5,52.2 M1.1,52.4c0,0.2,0.2,0.3,0.2,0.1C1.2,52.4,1.2,52.3,1.1,52.4C1.1,52.3,1.1,52.3,1.1,52.4 M73.3,52.7
		c0,0.1,0,0.2,0,0.2C73.3,53,73.4,52.9,73.3,52.7c0.1,0,0.1-0.1,0.1-0.1C73.4,52.6,73.4,52.6,73.3,52.7 M73.1,53.3
		c-0.2,0.7-1,2.6-1.8,4.1c-0.2,0.4-0.3,0.7-0.3,0.7c0.1-0.1,1.6-3.2,1.6-3.3c0,0,0.1-0.4,0.3-0.8c0.2-0.4,0.3-0.8,0.3-0.9
		C73.2,53.1,73.2,53.1,73.1,53.3 M48,58.3l4.3,4.3l-4.8,0l-4.8,0l-2.3-2.2c-1.2-1.2-2.4-2.3-2.6-2.5c-0.2-0.1,0.8,0.9,2.2,2.3
		l2.5,2.5h4.8c2.7,0,4.8,0,4.8-0.1c0-0.1-8.6-8.6-8.7-8.6C43.7,54,45.6,55.9,48,58.3 M12.2,54.1c0,0.1,0.7,1.7,1,2.2
		c0.2,0.3,0.3,0.7,0.3,0.7c0,0.1,0,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.2c0,0.1,0.1,0.2,0.1,0.1c0,0-0.1-0.4-0.3-0.8
		c-0.4-0.8-0.8-1.7-1-2.3C12.3,54.1,12.2,54,12.2,54.1 M36,55.9c0.9,0.9,1.6,1.6,1.7,1.6c0.1,0-2.4-2.5-3-3
		C34.5,54.4,35.1,55,36,55.9 M58.8,56c0.7,0.7,1.2,1.2,1.2,1.2C60,57.2,59.5,56.7,58.8,56c-0.6-0.7-1.1-1.2-1.1-1.2
		C57.6,54.8,58.1,55.4,58.8,56 M83.1,55.4c0,0.1,0,0.2,0,0.3c0,0.1,0,0,0.1-0.1C83.2,55.3,83.2,55.2,83.1,55.4 M13.8,57.8
		c0,0.1,0,0.1,0.1,0.1S14,58,14,58c0,0.1,0.1,0.2,0.2,0.3c0.1,0.2,0.1,0.1,0-0.2C13.9,57.6,13.8,57.6,13.8,57.8 M60.6,57.9
		c0.1,0.1,0.2,0.2,0.2,0.2c0,0-0.3-0.4-0.3-0.4C60.5,57.7,60.5,57.7,60.6,57.9 M51.9,58.8c0.4,0.5,1,1,1.2,1.2
		c0.2,0.2-0.1-0.2-0.8-0.8c-0.7-0.7-1.2-1.2-1.2-1.2C51.2,58,51.5,58.4,51.9,58.8 M70.8,58.5c-0.1,0.1-0.1,0.2-0.1,0.3
		C70.7,58.7,70.8,58.7,70.8,58.5c0.2,0,0.2-0.2,0.2-0.2C70.9,58.3,70.9,58.3,70.8,58.5 M62.1,59.3c0.5,0.5,0.9,0.8,0.9,0.8
		c0,0-1.6-1.7-1.7-1.7C61.3,58.5,61.6,58.9,62.1,59.3 M14.3,58.6c0,0,0.1,0.2,0.2,0.3c0.1,0.1,0.2,0.2,0.2,0.1s-0.1-0.2-0.2-0.3
		C14.4,58.6,14.3,58.6,14.3,58.6 M81.9,58.7c0,0.1,0,0.2-0.1,0.3c0,0.1,0,0.1,0.1,0.1C81.9,59,82,58.9,81.9,58.7
		C81.9,58.7,81.9,58.7,81.9,58.7 M70.4,59.1c-0.1,0.1-0.1,0.2-0.1,0.2c0.1,0,0.3-0.3,0.3-0.4C70.6,58.9,70.5,58.9,70.4,59.1
		 M15,59.7c0,0.1,1.8,2.7,2,2.9c0,0,0.2,0.3,0.4,0.5c0.5,0.6,0.6,0.7,0.6,0.7c0,0-0.1-0.1-0.2-0.3c-0.5-0.5-1.5-1.9-2.1-2.8
		C15.1,59.8,15,59.6,15,59.7 M69.6,60.4c-0.6,1-1.8,2.5-2.3,3.1C67,63.8,66.8,64,66.9,64c0,0,0.1-0.1,0.2-0.2c0.5-0.6,0.9-1.1,1-1.2
		c0.1-0.1,0.2-0.3,0.4-0.4c0.1-0.1,0.2-0.3,0.2-0.4c0-0.1,0-0.1,0.1-0.1c0.1,0,1.4-1.9,1.4-2C70.1,59.7,69.9,60,69.6,60.4
		 M63.4,60.6c0,0.1,0.8,0.8,0.8,0.8c0,0-0.2-0.2-0.4-0.5C63.6,60.7,63.4,60.5,63.4,60.6 M55.1,62l0.7,0.7h4.8c2.7,0,4.8,0,4.8-0.1
		c0-0.1-1-1.1-1.1-1.1c0,0,0.2,0.2,0.4,0.5c0.3,0.3,0.5,0.5,0.5,0.6c0,0-2.1,0-4.7,0h-4.7l-0.4-0.4c-0.2-0.2-0.5-0.5-0.7-0.7
		C54.6,61.4,54.7,61.6,55.1,62 M5.2,63.1c0,0,0.1,0.2,0.2,0.3c0.1,0.2,0.2,0.2,0.2,0.2C5.6,63.5,5.2,63,5.2,63.1 M5.8,64.1
		c0,0.1,0.1,0.3,0.2,0.5c0.1,0.2,0.2,0.3,0.2,0.2c0-0.1-0.1-0.3-0.2-0.3C6,64.3,5.9,64.2,5.8,64.1C5.9,64,5.8,64,5.8,64.1
		 M18.7,64.6c0.7,0.8,2.2,2.2,2.2,2.1c0,0-0.6-0.6-1.3-1.3C18.9,64.6,18.5,64.3,18.7,64.6 M65.4,65.5c-0.7,0.7-1.1,1.1-1,1
		c0.2-0.2,1.8-1.8,2.1-2.1C66.9,64,66.5,64.4,65.4,65.5 M77.9,66.1c-0.1,0.2-0.1,0.2,0.1,0.1c0.1-0.1,0.2-0.3,0.1-0.3
		C78,66,78,66,77.9,66.1 M63.7,67.1c-0.1,0.1-0.2,0.2-0.2,0.2C63.5,67.3,63.7,67.2,63.7,67.1c0.2,0,0.3-0.2,0.3-0.2
		C64,66.9,63.9,66.9,63.7,67.1 M21.3,67c0,0.1,0.1,0.2,0.6,0.5l0.4,0.3l-0.3-0.3C21.5,67.1,21.3,67,21.3,67 M62.4,68.1
		c-0.3,0.2-1.1,0.8-1.8,1.3c-0.7,0.5-1.3,0.9-1.3,0.9c0,0,1.8-1.1,1.8-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,1.8-1.3,1.7-1.3
		C63,67.7,62.7,67.9,62.4,68.1 M22.3,67.9C22.3,68,22.4,68,22.3,67.9c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0.1,0.3,0.2,0.5,0.4
		c0.2,0.1,0.3,0.2,0.3,0.3c0.1,0.2,2.3,1.6,2.4,1.5c0,0-0.3-0.2-0.6-0.4c-0.5-0.3-1.7-1.1-2.7-1.9C22.4,67.9,22.3,67.9,22.3,67.9
		 M76.1,68.6c-0.1,0.1-0.1,0.2,0,0.2c0,0,0.1,0,0-0.1C76.1,68.8,76.1,68.7,76.1,68.6c0.1,0,0.2,0,0.1,0
		C76.2,68.6,76.2,68.6,76.1,68.6 M9.1,68.9C9.1,68.9,9.1,69,9.1,68.9C9.2,69,9.2,69,9.2,69.1c0,0.1,0.1,0.2,0.2,0.2l0.2,0.1
		l-0.2-0.3C9.2,68.9,9.1,68.7,9.1,68.9 M9.9,70c0.1,0.1,0.1,0.2,0.2,0.2c0,0,0-0.1,0-0.2s-0.1-0.2-0.2-0.2C9.8,69.8,9.9,69.9,9.9,70
		 M25.9,70.4c0,0,0,0.1,0.1,0.1c0.1,0,0.2,0.1,0.3,0.2c0.1,0.1,0.3,0.2,0.3,0.2c0.1,0-0.2-0.2-0.5-0.4C26,70.4,25.9,70.4,25.9,70.4
		 M58.5,70.7c-0.2,0.1-0.4,0.2-0.3,0.2c0.1,0.1,0.7-0.3,0.7-0.4C58.9,70.5,58.9,70.5,58.5,70.7 M26.8,70.9c0.1,0.1,1.1,0.6,1.1,0.6
		c0,0-0.2-0.2-0.5-0.3C26.8,70.8,26.7,70.8,26.8,70.9 M57.6,71.2c-0.2,0.1-0.4,0.2-0.4,0.2c0,0,0.6-0.2,0.8-0.4
		C58.3,71,57.9,71.1,57.6,71.2 M11.4,71.6c0,0,0.1,0.2,0.2,0.3c0.2,0.2,0.3,0.2,0-0.1C11.5,71.6,11.4,71.5,11.4,71.6 M28,71.6
		c0,0,0.3,0.2,0.6,0.3c0.6,0.3,0.8,0.4,1.5,0.7c0.6,0.2,1.6,0.6,1.9,0.7c0.4,0.1,0.3,0-0.2-0.2c-0.2-0.1-0.5-0.2-0.6-0.2
		c-0.1,0-0.5-0.2-0.9-0.4c-0.4-0.2-1.1-0.4-1.5-0.6C28.1,71.5,28,71.5,28,71.6 M56.3,71.8c-0.4,0.2-0.8,0.4-1,0.5
		c-0.2,0.1-0.5,0.2-0.6,0.2c-0.3,0.1-0.4,0.2-1.1,0.4c-0.7,0.3-0.9,0.3-0.7,0.3c0.2,0,1.9-0.6,2.6-1c0.1-0.1,0.3-0.1,0.4-0.1
		c0.1,0,0.1,0,0.1-0.1c0,0,0-0.1,0.1-0.1c0.1,0,1-0.4,1-0.5C57.1,71.5,57,71.5,56.3,71.8 M11.9,72c0,0.1,0.1,0.2,0.2,0.3
		c0.3,0.2,0.3,0.2,0.1-0.1C12,72.1,11.9,72,11.9,72C11.9,71.9,11.9,72,11.9,72 M72.6,72.6c-0.1,0.1-0.3,0.3-0.3,0.3
		c0,0,0.1,0,0.2-0.1s0.2-0.2,0.3-0.3c0.1-0.1,0.1-0.2,0.1-0.2S72.8,72.5,72.6,72.6 M12.5,72.8c0,0.1,0.5,0.6,0.6,0.5
		c0,0,0-0.1-0.1-0.2C12.7,72.8,12.5,72.7,12.5,72.8 M32.4,73.4c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0-0.1
		C32.6,73.3,32.5,73.3,32.4,73.4C32.3,73.3,32.3,73.3,32.4,73.4 M52.4,73.4C52.3,73.5,52.3,73.5,52.4,73.4c0.1,0.1,0.2,0,0.3,0
		c0.1-0.1,0.1-0.1,0-0.1C52.6,73.3,52.5,73.3,52.4,73.4 M33,73.6c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0-0.1
		C33.1,73.5,33,73.5,33,73.6C32.8,73.5,32.8,73.5,33,73.6 M51.9,73.6l-0.2,0.1L51.9,73.6c0.1,0.1,0.2,0,0.3,0
		C52.4,73.5,52.1,73.5,51.9,73.6 M13.5,73.7c0,0,0.1,0.1,0.3,0.3l0.3,0.2l-0.2-0.3C13.6,73.7,13.5,73.6,13.5,73.7 M71.4,73.8
		c-0.1,0.1-0.1,0.2-0.1,0.2c0.1,0,0.3-0.2,0.3-0.3C71.6,73.7,71.5,73.7,71.4,73.8 M50.7,73.9C50.6,74,50.6,74,50.7,73.9
		c0.2,0.1,0.3,0,0.3,0C51,73.8,50.8,73.8,50.7,73.9 M34.8,74.1c0.1,0,0.2,0.1,0.3,0.1l0.2,0l-0.2-0.1C34.9,74,34.7,74,34.8,74.1
		 M50,74.1l-0.2,0.1L50,74.1c0.1,0.1,0.3,0,0.3,0C50.3,74,50.3,74,50,74.1 M35.6,74.2c0,0,0.2,0.1,0.3,0.1c0.2,0,0.2,0,0.1-0.1
		C35.7,74.1,35.5,74.1,35.6,74.2 M49.2,74.2C49,74.3,49,74.3,49.2,74.2c0.2,0.1,0.3,0,0.3,0C49.5,74.1,49.5,74.1,49.2,74.2
		 M36.4,74.4c0,0,0.2,0.1,0.4,0.1c0.4,0,0.3,0-0.1-0.1C36.4,74.3,36.4,74.3,36.4,74.4 M48.3,74.4l-0.3,0.1L48.3,74.4
		c0.2,0.1,0.4,0,0.5,0C48.9,74.3,48.7,74.3,48.3,74.4 M37.3,74.6c0,0.1,0.2,0.1,0.4,0.1c0.5,0,0.5,0-0.1-0.1
		C37.3,74.5,37.2,74.5,37.3,74.6 M47.3,74.6L47,74.6L47.3,74.6c0.3,0.1,0.4,0,0.5,0C47.9,74.5,47.8,74.5,47.3,74.6 M38.4,74.7
		c0,0,0.3,0.1,0.6,0.1c0.7,0,0.7,0-0.1-0.1C38.6,74.7,38.4,74.7,38.4,74.7 M45.9,74.8l-0.4,0.1L45.9,74.8c0.4,0.1,0.7,0,0.7,0
		C46.8,74.7,46.6,74.7,45.9,74.8 M40.2,74.9c0.1,0,1.1,0.1,2.4,0.1c1.2,0,2.3,0,2.4-0.1c0.1,0-1-0.1-2.4-0.1
		C41.2,74.9,40.2,74.9,40.2,74.9 M70,75.1c-0.3,0.2-0.6,0.4-0.5,0.4c0.1,0,0.8-0.6,0.7-0.6C70.2,74.9,70.1,75,70,75.1 M16.4,76.2
		c0.1,0.1,0.2,0.2,0.3,0.2c0,0,0.2,0.1,0.3,0.1s0.1,0.1,0.1,0c0-0.1-0.2-0.2-0.5-0.4C16.3,76,16.3,76,16.4,76.2 M67.8,76.8
		c-0.1,0.1-0.1,0.1-0.1,0.2c0,0,0.1,0,0.2-0.1c0.1-0.1,0.2-0.2,0.1-0.2C68,76.6,67.9,76.7,67.8,76.8 M17.8,77.2
		c0,0.1,0.3,0.3,0.4,0.3c0,0-0.1-0.1-0.2-0.2C17.9,77.2,17.8,77.2,17.8,77.2 M65.3,78.5c-0.1,0.1-0.2,0.2-0.1,0.2
		c0.1,0,0.4-0.2,0.3-0.3C65.5,78.4,65.4,78.4,65.3,78.5 M21.2,79.3c0,0,0.1,0.1,0.2,0.2c0.3,0.2,0.4,0.1,0.1-0.1
		C21.3,79.3,21.2,79.3,21.2,79.3 M22.6,80.2c0,0,0.1,0.1,0.1,0.1c0.1,0,0.1,0,0.1-0.1c0,0-0.1-0.1-0.1-0.1
		C22.6,80.1,22.6,80.2,22.6,80.2 M29.4,83.1c0,0,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.2,0c0,0-0.2-0.1-0.3-0.1
		C29.5,83.1,29.4,83.1,29.4,83.1 M34.6,84.4c0,0,0.1,0.1,0.2,0.1c0.2,0,0.2,0,0.1-0.1C34.7,84.3,34.6,84.3,34.6,84.4"
          />
        </g>
        <text id="Credit_Card" data-name="Credit Card" transform="translate(1116 502)" fill="#001847" fontSize="14" fontFamily="Poppins-Regular, Poppins"><tspan x="-40.705" y="0"></tspan></text>
      </g>
    </svg>

  );
};
