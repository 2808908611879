import CenteredLoadingContainer from '@Components/CenteredLoadingContainer';
import MainContentContainer from '@Components/MainContentContainer';
import { Box, Grid } from '@material-ui/core';
import NoData from '@Assets/Images/Data-not-found3.png';
import Table from '@Components/MaterialTable/v7';
import useHook from './hook';

const titleStyle = { fontWeight: 600, fontSize: 22, color: 'var(--primary-color)' };

export default function Dashboard() {
  const h = useHook();
  return (
    <MainContentContainer style={{ height: '100vh' }}>
      <div style={{ minHeight: '40rem', width: '100%' }}>
        <div className="d-flex align-item-center" style={{ justifyContent: 'space-between' }}>
          <h1
            className="my-auto pl-4 pb-2 pt-2"
            style={titleStyle}
          >
            Analytic
          </h1>
        </div>
        {/* <div className="d-flex align-items-center">
          <IconButton disableRipple style={{ width: 10, height: 10, backgroundColor: '#fcfcfc' }} onClick={() => scroll(-40)}>
            <ChevronLeft style={{ color: 'var(--primary-color)' }} />
          </IconButton>
          <List
            ref={ref}
            style={{
              display: 'flex', flexDirection: 'row', overflowX: 'hidden', marginLeft: 10, marginRight: 10,
            }}
          >
            <FilterAssetTag {...h} />
          </List>
          <IconButton
            disableRipple
            style={{
              width: 10, height: 10, backgroundColor: '#fcfcfc', marginRight: 10,
            }}
            onClick={() => scroll(40)}
          >
            <ChevronRight style={{ color: 'var(--primary-color)' }} />
          </IconButton>
        </div> */}
        <Box className="d-flex flex-wrap" style={{ margin: '20px -8px 0px' }}>
          <>
            {h.isLoading ? <CenteredLoadingContainer height="50vh" size={75} hasText text="data" /> : (
              <>
                {!!h.projects?.length
                  ? (
                    <Grid container spacing={1}>
                      <Grid xs={12}>
                        <Table
                          tableHead
                          tableData={h.projects}
                          tableMinWidth={300}
                          tableMaxHeight={900}
                        />
                      </Grid>
                    </Grid>
                  )
                  : (
                    <div className="d-flex justify-content-center w-100 m-4 p-5">
                      <img src={NoData} style={{ height: '55vh' }} />
                    </div>
                  )}
              </>
            )}
          </>
        </Box>
      </div>
    </MainContentContainer>
  );
}
