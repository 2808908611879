import CheckboxFilter from '@Components/FilterTag/CheckboxFilter';
import RadioFilter from '@Components/FilterTag/RadioFilter';
import {
  Checkbox, createMuiTheme, FormControlLabel, FormGroup, Grid, MenuItem, TextField, ThemeProvider, Tooltip, withStyles,
} from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { InfoOutlined } from '@material-ui/icons';
import React from 'react';
import { teal } from '@material-ui/core/colors';
import ReportTable from './Table';

const GenerateReportContent = (h) => {
  return (
    <Grid container>
      <Grid container item xs={12} alignItems="center">
        {[
          {
            label: 'Report Name',
            child: (
              <TextField
                size="small"
                value={h.name}
                onChange={(e) => h.set_name(e.target.value)}
                variant="outlined"
                placeholder="Enter report name here"
                className="py-1 w-75"
              />
            ),
          },
          {
            label: 'Description',
            child: (
              <TextField
                size="small"
                value={h.description}
                onChange={(e) => h.set_description(e.target.value)}
                variant="outlined"
                placeholder="Enter description here"
                className="py-1 w-75"
                multiline
                rows={3}
                rowsMax={4}
              />
            ),
          },
          {
            label: 'Date',
            child: (
              <ThemeProvider theme={defaultMaterialTheme}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <DatePicker
                    value={h.date}
                    onChange={h.set_date}
                    inputVariant="outlined"
                    className="py-1 w-75"
                    size="small"
                    disableFuture
                    showTodayButton
                    format="DD MMMM YYYY"
                  />
                </MuiPickersUtilsProvider>
              </ThemeProvider>
            ),
          },
          // {
          //   label: 'Additional Detail',
          //   child: <CheckboxGroup {...h} />,
          // },
          {
            label: 'Status',
            child: (
              <CheckboxFilter
                tags={['Closed', 'Open', 'Compliance', 'No Annotation', 'No Data']}
                tagsFilter={h.status_tags_filter}
                setTagsFilter={h.set_status_tags_filter}
              />
            ),
          },
          {
            label: 'Module',
            child: (
              <RadioFilter
                tags={h.inspection_module.map(module => module.name)}
                tagsFilter={h.module_tags_filter}
                setTagsFilter={h.set_module_tags_filter}
              />
            ),
          },
        ].map(input => (
          <>
            <Grid item xs={2}>{input.label}:</Grid>
            <Grid item xs={10}>{input.child}</Grid>
          </>
        ))}
      </Grid>
      <Grid item xs={12} className="py-1">
        <ReportTable dataTable={h.inspection_session} {...h} />
      </Grid>
    </Grid>
  );
};

const CheckboxGroup = (h) => (
  <FormGroup style={{ flexDirection: 'row' }} className="py-1">
    <div className="d-flex align-items-center">
      <FormControlLabel
        control={<GreenCheckbox checked={h.detail.basic_info} onChange={h.handleChange} name="basic_info" />}
        label="Basic Info"
        className="m-0"
      />
      <Tooltip title="Basic Info">
        <InfoOutlined className="mx-2" />
      </Tooltip>
    </div>

    <div className="d-flex align-items-center">
      <FormControlLabel
        control={<GreenCheckbox checked={h.detail.location} onChange={h.handleChange} name="location" />}
        label="Location"
        className="m-0"
      />
      <Tooltip title="Location">
        <InfoOutlined className="mx-2" />
      </Tooltip>
      {!!h.detail.location && (
        <TextField
          select
          placeholder="Location"
          variant="outlined"
          value={h.location}
          onChange={(e) => h.set_location(e.target.value)}
          size="small"
        >
          <MenuItem value="google_satelite" children={<p className="text-uppercase text-dark">Google Satelite</p>} />
          {h.layer_list.map(layer => (<MenuItem value={layer.label} children={<p className="text-uppercase text-dark">{layer.label}</p>} />))}
        </TextField>
      )}
    </div>

    <div className="d-flex align-items-center">
      <FormControlLabel
        control={<GreenCheckbox checked={h.detail.annotation_details} onChange={h.handleChange} name="annotation_details" />}
        label="Annotation Details"
        className="m-0"
      />
      <Tooltip title="Annotation Details">
        <InfoOutlined className="mx-2" />
      </Tooltip>
    </div>

    <div className="d-flex align-items-center">
      <FormControlLabel
        control={<GreenCheckbox checked={h.detail.annotation_status} onChange={h.handleChange} name="annotation_status" />}
        label="Annotation Status"
        className="m-0"
      />
      <Tooltip title="Annotation Status">
        <InfoOutlined className="mx-2" />
      </Tooltip>
    </div>
  </FormGroup>
);

const defaultMaterialTheme = createMuiTheme({
  palette: { primary: teal },
});

const GreenCheckbox = withStyles({
  root: {
    color: 'var(--secondary-color)',
    '&$checked': {
      color: 'var(--secondary-color)',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default GenerateReportContent;
