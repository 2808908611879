import MainContentNavbar from '@Components/MainContentNavbar';

export default function Navbar({ link, text, subtext }) {
  return (
    <div className="d-flex mt-1 align-items-center mb-2">
      <MainContentNavbar to={link} text={text} />
      {subtext && <h1 className="text-light d-flex align-items-center" style={{ paddingLeft: 10, fontSize: 20 }}>&gt; {subtext}</h1>}
    </div>
  );
}
