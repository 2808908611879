import React from 'react';
import NoData from '@Assets/Images/AccessDenied.svg';
import Button from '@Components/Button';
import { Link } from 'react-router-dom';

export default function AccessDeniedPage() {
  return (
    <div className="flex-standard flex-column">
      <img src={NoData} style={{ height: '50vh' }} className="mb-4 mt-5" />
      <Link to="/">
        <Button style={{ width: 200 }}>
          RETURN TO HOME PAGE
        </Button>
      </Link>
    </div>
  );
}
