import React from 'react';
import { Grid } from '@material-ui/core';
import LatestTransaction from './components/LatestTransaction';
import TransactionOverview from './components/TransactionOverview';
import useHook from './hook';
import TabBar from './components/TabBar';
import RefundCard from './components/RefundCard';
import TransferCoinsCard from './components/TransferCoinsCard';

export default function index(props) {
  const h = useHook(props);
  const isAdmin = props?.user?.RoleId === 1;
  // const csvData = h.transactions.map(d => ({
  //   User: props.user?.name,
  //   Reference: `${d?.id}${d?.PaymentId}${d?.UserId} ${moment(d.updatedAt).format('DDMMYYYY')} ${moment(d.updatedAt).format('h:mm:ss a')}`,
  //   Description: `${d.Payment.currency === 'usd' ? 'Credit Card' : 'RÄISE Token'} ${capitalize(d.status)}`,
  //   Type: `${d.Payment.type === 'random' ? 'Payment' : capitalize(d.Payment.type)}`,
  //   Status: capitalize(d.status),
  //   Amount: `$${numberWithCommas(d.Payment.amount)}`,
  // }));

  return (
    <div className="p2" style={{ marginInline: '7.5rem' }}>
      <h1 className="mr-4 mt-2" style={{ fontWeight: 600, fontSize: 22, color: 'var(--primary-color)' }}>Transaction Overview</h1>
      <Grid container spacing={3}>
        <Grid item xs={12} md={3} xl={3}>
          <LatestTransaction isAdmin={isAdmin} {...h} />
        </Grid>
        <Grid item md={9} xl={9} className="d-flex flex-column">
          <TabBar {...h} isAdmin={isAdmin} />
          {
            {
              1: <TransactionOverview {...h} />,
              2: <TransferCoinsCard isAdmin={isAdmin} {...h} />,
              3: <div>Redirect to purchase token page</div>,
              4: <RefundCard {...h} />,
            }[h.tab]
          }
        </Grid>
      </Grid>
    </div>
  );
}