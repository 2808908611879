import {
  Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, makeStyles,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import React from 'react';
import GenerateReportContent from './GenerateReportContent';
import Hook from './hook';

const GenerateReport = ({ tab, project, setJustUpdated }) => {
  const h = Hook({ project, setJustUpdated });
  const classes = useStyles();

  return (
    <>
      {tab === 'generatedReport' && (
        <Button
          variant="contained"
          style={{ color: '#FFFFFF', backgroundColor: 'var(--primary-color)', fontFamily: 'CeraProRegular', height: 30, fontSize: 12 }}
          onClick={() => h.set_open(true)}
        >
          <p className="text-white">Generate Report</p>
        </Button>
      )}
      <Dialog
        open={h.open}
        onClose={() => h.set_open(false)}
        PaperProps={{ style: { borderRadius: 10 } }}
        fullWidth
        maxWidth="lg"
        style={{ maxHeight: '100%' }}
        className="hide-scroll"
      >
        <DialogTitle className="py-0">
          <div className="w-100 d-flex justify-content-between align-items-center">
            <p style={{ color: '#022C64', fontWeight: 600 }}>Generate Report</p>
            <IconButton onClick={() => h.set_open(false)}>
              <Close fontSize="small" />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          <GenerateReportContent {...h} />
        </DialogContent>
        <DialogActions>
          <div className={classes.dialogAction}>
            <Box
              component="button"
              className="blue-button-outlined"
              onClick={() => h.set_open(false)}
              style={{ borderRadius: '6px', width: '120px' }}
            >
              <h1 className={`${classes.outlinedBtnText} flex-standard`}>
                Cancel
              </h1>
            </Box>
            <Box
              component="button"
              className={`${classes.blueBtn} blue-button`}
              onClick={h.handleSubmit}
              style={{ borderRadius: '6px', width: '180px' }}
            >
              <h1 className={`${classes.blueBtnText} flex-standard`}>
                Generate Report
              </h1>
            </Box>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};

const useStyles = makeStyles(() => ({
  blueBtn: { borderRadius: '6px', width: '150px' },
  blueBtnText: { color: '#FFFFFF', fontWeight: 600, fontSize: 16 },
  outlinedBtnText: { fontWeight: 600, fontSize: 16 },
  root: { '&$checked': { color: 'rgb(30, 52, 101)' }, transform: 'scale(0.8)' },
  dialogAction: {
    display: 'flex', justifyContent: 'space-between', padding: '0 20px', width: '100%',
  },
}));

export default GenerateReport;
