import React, { useState } from 'react';
import { Avatar, Tooltip } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import _ from 'lodash';
import RelativeDialog from '@Components/RelativeDialog/v2';

export default (props) => {
  const [onIconHover, setOnIconHover] = useState({});
  const [openRelative, setOpenRelative] = useState(false);
  const hashColor = (color) => {
    if (!color) return '#506288';
    if (!(color.split('')).includes('#')) return `#${color}`;
    return color;
  };
  const teamAssetAccess = props.allAssetAccess.filter(f => f.type === 'Team');
  const userAssetAccess = props.allAssetAccess.filter(f => f.type === 'User');
  return (
    <div>
      <div className="w-100 d-flex">
        <RelativeDialog
          openRelative={openRelative}
          setOpenRelative={setOpenRelative}
          userAccess={userAssetAccess}
          teamAccess={teamAssetAccess}
          title={`${props.open.name} authorized to`}
          assets={props.assets.map(m => m.Asset)}
        />
        <div style={{ flexGrow: 8 }}>
          <p style={{ fontSize: 16, marginTop: 2, color: 'white' }}>{`${props.open.name ?? 'Open'} & REVIEWED/VERIFIED`}</p>
          <p style={{ fontSize: 12, marginTop: 4, color: 'white' }}>Authorized to:</p>
          <div className="mt-4 d-flex justify-content-center align-items-center">
            <AvatarGroupIcon max={5} spacing="small">
              {[...(_.uniqBy(userAssetAccess, 'id')), ...(_.uniqBy(teamAssetAccess, 'id'))].map((u, index) => (
                <Tooltip title={u.name}>
                  <Avatar
                    onClick={() => setOpenRelative(true)}
                    onMouseEnter={() => setOnIconHover({ [index]: !onIconHover[index] })}
                    onMouseLeave={() => setOnIconHover({ [index]: !!onIconHover[index] })}
                    style={{
                      backgroundColor: hashColor(u.colour),
                      borderRadius: u.type === 'Workflow' && '8px',
                      zIndex: onIconHover[index] ? 1 : !index ? 1 : 0,
                      border: '2px solid var(--primary-color)',
                    }}
                    alt={u.name}
                    src={`${process.env.REACT_APP_S3}/${u?.image}`}
                  />
                </Tooltip>
              ))}
            </AvatarGroupIcon>
          </div>
        </div>
      </div>
    </div>
  );
};

const AvatarGroupIcon = styled(AvatarGroup)({
  '& .MuiAvatar-root': {
    height: '24px',
    width: '24px',
    fontSize: 14,
    // backgroundColor: `#${ Math.floor(Math.random() * 16777215).toString(16)}`,
    backgroundColor: 'gray',
    '&:hover': {
      zIndex: '2 !important',
    },
    border: '2px solid var(--primary-color)',
    cursor: 'pointer',
  },
});
