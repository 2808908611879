import React from 'react';
import {
  Paper, TextField, Checkbox, FormControlLabel, withStyles,
} from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { styled } from '@material-ui/core/styles';
import Button from '@Components/Button';
import CoinWallet from '@Components/CoinWallet';

const NumberTextField = styled(TextField)({
  '& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0,
  },
});

export default function TransferCoinsCard(h) {
  const currentAmount = CoinWallet({ valueOnly: true, isFullLength: true });
  const afterAmount = currentAmount ? currentAmount - h.amount : currentAmount;
  return (
    <Paper style={{ flex: '1 0 auto', marginTop: 20, padding: 35 }}>
      <h3>Transfer Token</h3>
      <p>Please fill in token transfer details</p>
      {h.isAdmin && (
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <FormControlLabel
            control={(
              <GreenCheckbox
                checked={h.hasSourceEmail}
                onChange={() => h.setHasSourceEmail(!h.hasSourceEmail)}
                name="basic_info"
                fontSize="small"
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
              />
            )}
            label={(
              <p style={{ fontSize: '14px', fontFamily: 'CeraProRegular' }}>
                Transfer from other user
              </p>
            )}
          />
        </div>
      )}
      {(h.isAdmin && h.hasSourceEmail)
          && (
            <div className="d-flex align-items-lg-center mt-3" style={{ width: '100%' }}>
              <p className="pr-1" style={{ width: '15%' }}>Source Email</p>
              <TextField
                variant="outlined"
                size="small"
                value={h.sourceEmail}
                fullWidth
                onChange={(e) => h.setSourceEmail(e.target.value)}
                style={{ width: '85%' }}
              />

            </div>
          )}
      <div className="d-flex align-items-lg-center mt-3" style={{ width: '100%' }}>
        <p className="pr-1" style={{ width: '15%' }}>Recipient Email</p>
        <TextField
          variant="outlined"
          size="small"
          value={h.recipientEmail}
          fullWidth
          onChange={(e) => h.setRecipientEmail(e.target.value)}
          style={{ width: '85%' }}
        />
      </div>
      <div className="d-flex align-items-lg-center mt-3" style={{ width: '100%' }}>
        <p className="pr-1" style={{ width: '15%' }}>Amount</p>
        <NumberTextField
          variant="outlined"
          size="small"
          value={!h.amount ? '' : h.amount}
          fullWidth
          onChange={(e) => h.setAmount(e.target.value)}
          style={{ width: '85%' }}
          type="number"
        />
      </div>
      {!h.hasSourceEmail && (
        <>
          <div className="d-flex align-items-lg-center mt-3" style={{ width: '100%' }}>
            <p className="pr-1" style={{ width: '15%' }}>Balance Before</p>
            <TextField
              variant="outlined"
              size="small"
              value={currentAmount}
              fullWidth
              style={{ width: '85%' }}
              disabled
            />
          </div>
          <div className="d-flex align-items-lg-center mt-3" style={{ width: '100%' }}>
            <p className="pr-1" style={{ width: '15%' }}>Balance After</p>
            <TextField
              variant="outlined"
              size="small"
              value={afterAmount}
              fullWidth
              style={{ width: '85%' }}
              disabled
            />
          </div>
        </>
      )}
      <div className="w-100 d-flex justify-content-end mt-3">
        <Button onClick={() => h.onWalletTransfer()}>Transfer</Button>
      </div>
    </Paper>
  );
}

const GreenCheckbox = withStyles({
  root: {
    color: 'var(--secondary-color)',
    '&$checked': {
      color: 'var(--secondary-color)',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);
