import React from 'react';
import {
  Dialog, DialogActions, DialogContent, Button, DialogTitle, Avatar,
} from '@material-ui/core';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

export default function index(props) {
  return (
    <MuiThemeProvider theme={getMuiTheme()}>
      <Dialog
        open={props.openRelative}
        onClose={() => props.setOpenRelative(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle><h3>{props.title}</h3></DialogTitle>
        <DialogContent className="mx-3">
          <div className="hide-scroll" style={{ maxHeight: '20em', overflowY: 'scroll' }}>
            {props.assets.map(asset => (
              <div className="mb-4">
                <h6 className="mb-1" style={{ fontWeight: 700 }}>{asset.name}</h6>
                {props.teamAccess && props?.teamAccess?.filter(f => f.AssetId === asset.id).map(t => (
                  <div className="d-flex justify-content-between align-items-center my-2">
                    <div className="d-flex align-items-center">
                      <Avatar
                        alt={t.name}
                        src={`${process.env.REACT_APP_S3}/${t?.image}`}
                        style={{ backgroundColor: `#${t.colour ? t.colour : 506288}` }}
                      />
                      <p className="ml-2">{t.name}</p>
                    </div>
                  </div>
                ))}
                {props.userAccess && props?.userAccess?.filter(f => f.AssetId === asset.id).map(u => (
                  <div className="d-flex justify-content-between align-items-center my-2">
                    <div className="d-flex align-items-center">
                      <Avatar
                        alt={u.name}
                        src={`${process.env.REACT_APP_S3}/${u?.image}`}
                        style={{ backgroundColor: u.colour ? u.colour : '#506288' }}
                      />
                      <p className="ml-2">{u.name}</p>
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </DialogContent>
        <DialogActions>
          <Button style={{ backgroundColor: 'var(--primary-color)', color: '#fff' }} onClick={() => props.setOpenRelative(false)}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </MuiThemeProvider>

  );
}

const getMuiTheme = () => createMuiTheme({
  typography: {
    fontFamily: 'CeraProRegular',
  },
});
