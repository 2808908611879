import { Grid } from '@material-ui/core';
import moment from 'moment';

export default function Sidebar({ organization_detail }) {
  const image = organization_detail?.image ?? 'static/media/defaultAssetImg-01.png';
  return (
    <Grid item xs={12} md={3} className="sidebar bottom-bar" style={{ zIndex: 1 }}>
      <img
        className="sidebar-img"
        style={{ objectFit: 'contain', width: '100%', aspectRatio: '3/2' }}
        src={`${process.env.REACT_APP_S3}/${image}`}
        alt="asset"
      />
      <div className="d-flex justify-content-center flex-wrap" style={{ flex: 1 }}>
        <p className="color-tertiary pt-3">{organization_detail?.description ?? ''}</p>
      </div>
      <div className="flex-standard flex-column" style={{ flex: 1 }}>
        <small className="pt-3" style={{ fontSize: 10, color: 'grey' }}>Signed Up Name</small>
        <p className="py-1">{organization_detail?.admin?.name ?? 'N/A'}</p>
        <small className="pt-3" style={{ fontSize: 10, color: 'grey' }}>Signed Up Email</small>
        <p className="py-1">{organization_detail?.admin?.email ?? 'N/A'}</p>
        <small className="pt-3" style={{ fontSize: 10, color: 'grey' }}>Signed Up Date</small>
        <p className="py-1">{organization_detail?.createdAt ? moment(organization_detail?.createdAt).format('DD MMM YYYY, hh:mmA') : 'N/A'}</p>
      </div>
    </Grid>
  );
}
