import { Paper } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import {
  TransactionOverviewIcon, TransferTokenIcon, PurchaseTokenIcon, RefundTokenIcon,
} from '@Assets/Icons/transaction-icons';

const tabIcons = [
  {
    tab: 1, name: 'Transaction Overview', icon: <TransactionOverviewIcon />, color: '#15CDD9',
  },
  {
    tab: 2, name: 'Transfer Token', icon: <TransferTokenIcon />, color: '#F3C522',
  },
  {
    tab: 3, name: 'Purchase Token', icon: <PurchaseTokenIcon />, color: '#AADE87', redirect: '/purchase-token',
  },
  {
    tab: 4, name: 'Refund Token', icon: <RefundTokenIcon />, color: '#F26C32', isAdmin: true,
  },
];

export default function TabBar(h) {
  const history = useHistory();
  return (
    <Paper
      style={{
        marginTop: 15,
        padding: 35,
        backgroundColor: '#275C5B',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <DonutShapeBackground />
      <div className="d-flex flex-row justify-content-between" style={{ position: 'relative', zIndex: 1, marginInline: '10%' }}>
        {tabIcons.filter(f => ((f.isAdmin && h.isAdmin) ? f.isAdmin : !f.isAdmin)).map(m => (
          <div
            className="d-flex flex-column align-items-center position-relative"
            onClick={() => (m.redirect ? history.push(m.redirect) : h.setTab(m.tab))}
          >
            <div
              className="d-flex justify-content-center align-items-center pointer"
              style={{
                backgroundColor: m.color, borderRadius: 100, width: 35, height: 35, padding: 10,
              }}
            >
              <div style={{ transform: 'scale(0.65)' }}>{m.icon}</div>
            </div>
            <p
              className="mt-2 text-white wrapper-horizontal pointer"
              style={{
                fontSize: 12, fontWeight: 600, width: '80%', textAlign: 'center',
              }}
            >{m.name}
            </p>
            {h.tab === m.tab && (
              <div style={{
                position: 'absolute', height: 3, width: 90, backgroundColor: 'white', borderRadius: 30, bottom: '-33%',
              }}
              />
            )}
          </div>
        ))}
      </div>
    </Paper>
  );
}

const DonutShapeBackground = () => (
  <svg
    style={{
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: '-32%',
      left: '-48%',
      transform: 'rotate(90deg)',
    }}
    viewBox="0 0 170 170"
  >
    <defs>
      <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
        <stop offset="0%" stopColor="rgba(255, 255, 255, 0.5)" />
        <stop offset="100%" stopColor="rgba(255, 255, 255, 0)" />
      </linearGradient>
    </defs>
    <circle cx="100" cy="100" r="100" fill="url(#gradient)" />
    <circle cx="100" cy="100" r="55" fill="#275C5B" />
  </svg>
);
