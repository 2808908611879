/* eslint-disable complexity */
/* eslint-disable no-nested-ternary */
import { useEffect, useState } from 'react';
import Api, { endpoints } from '@Helpers/api';
import { getOpenCaseFromInspection, todayDateTime } from '@Helpers';
import moment from 'moment';

export default function Hook({ project, setJustUpdated }) {
  const [all_inspection_session, set_all_inspection_session] = useState([]);
  const [inspection_session, set_inspection_session] = useState([]);
  const [inspection_module, set_inspection_module] = useState([]);
  const [open, set_open] = useState(false);
  const [name, set_name] = useState('');
  const [description, set_description] = useState('');
  const [date, set_date] = useState(todayDateTime);
  const [status_tags_filter, set_status_tags_filter] = useState(['']);
  const [module_tags_filter, set_module_tags_filter] = useState('');
  const [detail, set_detail] = useState({});
  const [location, set_location] = useState('google_satelite');
  const [layer_list, set_layer_list] = useState([]);
  const [selected_row, set_selected_row] = useState([]);

  const checkCompliance = (a) => {
    return !a.InspectionFiles.length ? 'No Data'
      : !a.has_non_compliance ? a.Module?.settings.split(',').includes('compliance') ? 'Compliance' : 'No Annotation'
        : !!a.open_case ? 'Open' : 'Closed';
  };

  useEffect(() => {
    if (!project.id) return;
    Api({
      endpoint: endpoints.getInspectionSession(),
      data: { AssetId: project.id },
      onSuccess: ({ data }) => {
        const uniqueModule = data.map(item => item.Module).filter((thing, index, self) => index === self.findIndex((t) => (
          t.id === thing.id && t.name === thing.name)));
        const filterOpenIssue = data.map(getOpenCaseFromInspection);
        const dataTable = filterOpenIssue.map(e => ({
          id: e.id,
          name: e.name,
          module: e.Module.name,
          status: checkCompliance(e),
          date: moment(e.date).format('DD MMMM YYYY'),
          type: !!e.is_map ? 'On-Map' : 'On-Image',
          ModuleId: e.ModuleId,
          ProjectPhaseId: e.ProjectPhaseId,
        }));
        set_all_inspection_session(dataTable);
        set_inspection_session(dataTable);
        set_inspection_module(uniqueModule);
      },
      onFail: () => {
        set_all_inspection_session([]);
        set_inspection_session([]);
        set_inspection_module([]);
      },
    });
    set_layer_list(project.asset_files.filter(x => x.media_type === 'orthophotos' && !!x.label));
  }, [project]);

  const handleSubmit = () => {
    if (!name) return;
    if (!selected_row.length) return;
    const arrModuleId = [...new Set(selected_row.map(e => e.ModuleId))];
    const arrProjectPhaseId = [...new Set(selected_row.map(e => e.ProjectPhaseId))];
    const data = {
      name,
      description,
      date,
      AssetId: project.id,
      InspectionId: selected_row.map(e => e.id),
      ModuleId: arrModuleId.length === 1 ? arrModuleId[0] : 'all',
      ProjectPhaseId: arrProjectPhaseId.length === 1 ? arrProjectPhaseId[0] : 'all',
    };
    Api({
      endpoint: endpoints.generateJsonReport(),
      data,
      onSuccess: () => {
        toast('success', 'Successfully generate report');
        setJustUpdated(true);
        resetForm();
      },
      // onFail: toast('error', 'Failed generate report'),
    });
  };

  const resetForm = () => {
    set_open(false);
    set_name('');
    set_description('');
    set_date(todayDateTime);
    set_status_tags_filter(['']);
    set_module_tags_filter('');
    set_detail({});
    set_location('google_satelite');
    set_selected_row([]);
  };

  const handleChange = (event) => set_detail({ ...detail, [event.target.name]: event.target.checked });

  useEffect(() => {
    // !status_tags_filter.includes('') - ada status && !!module_tags_filter - ada module
    if (!all_inspection_session.length) return;
    let filterResult = all_inspection_session;
    if (!status_tags_filter.includes('') && !!module_tags_filter) { // ada status && ada module
      filterResult = all_inspection_session.filter(e => module_tags_filter === e.module && status_tags_filter.includes(e.status));
    } else if (!status_tags_filter.includes('') && !module_tags_filter) { // ada status && no module
      filterResult = all_inspection_session.filter(e => status_tags_filter.includes(e.status));
    } else if (!!status_tags_filter.includes('') && !!module_tags_filter) { // no status && ada module
      filterResult = all_inspection_session.filter(e => module_tags_filter === e.module);
    } else if (!!status_tags_filter.includes('') && !module_tags_filter) { // no status && ada module
      filterResult = all_inspection_session;
    }
    set_inspection_session(filterResult);
    set_selected_row([]);
  }, [all_inspection_session, status_tags_filter, module_tags_filter]);

  return {
    all_inspection_session,
    set_all_inspection_session,
    inspection_session,
    set_inspection_session,
    inspection_module,
    open,
    set_open,
    name,
    set_name,
    description,
    set_description,
    date,
    set_date,
    detail,
    set_detail,
    location,
    set_location,
    status_tags_filter,
    set_status_tags_filter,
    module_tags_filter,
    set_module_tags_filter,
    handleSubmit,
    handleChange,
    layer_list,
    selected_row,
    set_selected_row,
  };
}
