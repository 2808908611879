import React from 'react';
import {
  Paper, IconButton, InputAdornment, MenuItem, TextField,
} from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { Search } from '@material-ui/icons';
import ExpensesChart from './ExpensesChart';
import SpentTokenChart from './SpentTokenChart';
import TransactionHistoryTable from './TransactionHistoryTable';

const Status = [
  { value: 'All' },
  { value: 'Success' },
  { value: 'Pending' },
  { value: 'Failed' },
];

export default function index(h) {
  return (
    <>
      <div className="d-flex flex-row" style={{ gap: 22, flex: '0 0 auto' }}>
        <ExpensesChart {...h} />
        <SpentTokenChart {...h} />
      </div>
      <Paper style={{ flex: '1 0 auto', marginTop: 20, padding: 35 }}>
        <div className="d-flex align-items-center justify-content-between">
          <h3>Transaction History</h3>
          <div className="d-flex justify-content-end align-items-center">
            <TextField
              label={<p style={{ fontSize: '12px' }}>SELECT STATUS</p>}
              size="small"
              style={{ width: 150, marginBottom: 10, marginRight: 20 }}
              select
              value={h.status}
              onChange={e => h.setStatus(e.target.value)}
            >
              {Status.map((d) => (
                <MenuItem value={d.value}>
                  {d.value}
                </MenuItem>
              ))}
            </TextField>
            <SearchBox
              onKeyDown={h.onKeyDown}
              label="Reference"
              size="small"
              variant="outlined"
              value={h.keyword}
              onChange={(e) => {
                h.handleChangeFilter(e.target.value);
                h.setKeyword(e.target.value);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment><IconButton onClick={() => h.getTransactions}><Search /></IconButton></InputAdornment>),
              }}
            />
            {/* <CsvDownload filename={`Transaction History ${props.user?.name}.csv`} data={csvData} className="border-0 p-0 bg-white">
                  <Button>
                    <GetApp style={{ color: '#045C5C' }} />
                  </Button>
                </CsvDownload> */}
          </div>
        </div>
        <TransactionHistoryTable {...h} status={h.status} />
      </Paper>
      <Paper />
    </>
  );
}

const SearchBox = styled(TextField)(() => ({
  transform: 'scale(0.8)',
  marginLeft: -25,
  '& fieldset': {
    borderRadius: '30px',
  },
}));
