import Button from '@Components/Button';
import DragDropList from '@Components/DragDropList';
import {
  Dialog, DialogTitle, DialogContent, DialogActions, MuiThemeProvider, createMuiTheme, IconButton,
} from '@material-ui/core';
import { Sort } from '@material-ui/icons';
import React, { useState } from 'react';

const SortableListDialog = ({
  list = [], onSubmit = () => null, title = '', subtitle = '',
}) => {
  const [open, setOpen] = useState(false);
  const [updatedList, setUpdatedList] = useState([]);
  const handleClose = () => setOpen(false);
  const handleSubmit = () => {
    onSubmit(updatedList);
    setOpen(false);
  };

  return (
    <>
      <IconButton onClick={() => setOpen(true)}>
        <Sort />
      </IconButton>
      <MuiThemeProvider theme={getMuiTheme()}>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle className="p-3">
            <h2>{title}</h2>
            <p className="text-secondary" style={{ fontSize: '0.75rem' }}>
              {subtitle}
            </p>
          </DialogTitle>
          <DialogContent dividers className="p-0">
            <DragDropList list={list} setUpdatedList={setUpdatedList} />
          </DialogContent>
          <DialogActions className="pt-3">
            <Button onClick={handleClose} variant="text">
              Cancel
            </Button>
            <Button onClick={handleSubmit} autofocus>
              Save Changes
            </Button>
          </DialogActions>
        </Dialog>
      </MuiThemeProvider>
    </>
  );
};

export default SortableListDialog;

const getMuiTheme = () => createMuiTheme({
  typography: {
    fontFamily: 'CeraProRegular',
  },
});
