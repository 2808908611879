import React, { useState } from 'react';
import AvatarIcon from '@Components/AvatarIcon';
import { AvatarGroup } from '@material-ui/lab';
import Button from '@Components/Button';
import { styled } from '@material-ui/core/styles';
import { Avatar, Tooltip } from '@material-ui/core';
import { Link } from 'react-router-dom';

const WorkflowAssetAccess = ({ workflow }) => {
  const [onIconHover, setOnIconHover] = useState({});
  return (
    <>
      <h6 className="mt-5 text-center mb-3" style={{ fontSize: 16 }}>
        This team asset access is according to their assigned workflow:
      </h6>
      <div className="flex-standard">
        <div className="p-3 w-50 d-flex" style={{ border: '1px solid grey', borderRadius: 5, gap: 10 }}>
          <AvatarIcon
            user={workflow}
            size="2rem"
            fontSize="12px"
            colorType="inherit"
            backgroundColor={!!workflow.colour ? `#${workflow.colour}` : '#506288'}
            style={{ color: !!workflow.colour ? 'black' : 'white', borderRadius: '10%' }}
          />
          <div style={{ width: '80%' }}>
            <p style={{ fontSize: 15 }}>{workflow?.name}</p>
            <div className="d-flex justify-content-between">
              <p style={{ fontSize: 12, color: 'grey' }}>Workflow members:</p>
              <AvatarGroupIcon max={3}>
                {workflow?.WorkflowTeams?.map((u, i) => (
                  <Tooltip title={u.Team?.name}>
                    <Avatar
                      style={{
                        backgroundColor: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
                        height: '15px',
                        width: '15px',
                        fontSize: 8,
                        zIndex: onIconHover[i] ? 999 : !i ? 10 : 1,
                      }}
                      alt={u.Team?.name}
                      src={`${process.env.REACT_APP_S3}/${u.Team?.image}`}
                      onMouseOver={() => setOnIconHover({ [i]: !onIconHover[i] })}
                      onMouseLeave={() => setOnIconHover({ [i]: !!onIconHover[i] })}
                    />
                  </Tooltip>
                ))}
              </AvatarGroupIcon>
            </div>
          </div>
        </div>
      </div>
      <p className="text-center mt-4" style={{ fontSize: 12 }}>
        You may manage the workflow asset access at the&nbsp;
        Workflow page.<br />
      </p>
      <div className="d-flex justify-content-center mt-2">
        <Link to={`/workflow/${workflow.id}`}>
          <Button size="small" style={{ width: '8.5rem' }}>VIEW WORKFLOW</Button>
        </Link>
      </div>
    </>
  );
};

const AvatarGroupIcon = styled(AvatarGroup)({
  '& .MuiAvatar-root': {
    height: '15px',
    width: '15px',
    fontSize: 8,
    backgroundColor: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
    '&:hover': {
      zIndex: '999 !important',
    },
  },
});

export default WorkflowAssetAccess;
