import React from 'react';
import NoData from '@Assets/Images/Data-not-found2.svg';

export default function NoDataInterface() {
  return (
    <div className="flex-standard flex-column">
      <img src={NoData} style={{ width: '30vw' }} alt="" />
    </div>
  );
}
