import React from 'react';
import MUIDataTable from 'mui-datatables';
import {
  createMuiTheme, MuiThemeProvider, makeStyles,
} from '@material-ui/core/styles';
import {
  Typography, Card, CardMedia, CardActionArea,
} from '@material-ui/core';
import moment from 'moment';
import NoData from '@Assets/Images/Data-not-found4.svg';

const useStyles = makeStyles({
  root: { maxWidth: '100%', margin: '0 2px', minHeight: '100%' },
  media: { height: '5vh', width: '5vw', cursor: 'default' },
  title: {
    fontWeight: 'bold', fontSize: 16, marginLeft: 10, marginTop: 5,
  },
  content: { color: '#707070', fontSize: 12 },
});

const columns = [
  {
    name: 'Asset Name',
    selector: 'name',
    align: 'left',
  },
  {
    name: 'Location',
    selector: 'location',
    align: 'left',
  },
  {
    name: 'Last Update',
    selector: 'last_update',
    align: 'right',
  },
  {
    name: 'Open',
    selector: 'open',
    options: {
      setCellHeaderProps: () => ({ align: 'center' }),
      setCellProps: () => ({ align: 'center' }),
    },
  },
  {
    name: 'In-Progress',
    selector: 'in_progress',
    options: {
      setCellHeaderProps: () => ({ align: 'center' }),
      setCellProps: () => ({ align: 'center' }),
    },
  },
  {
    name: 'On-Hold',
    selector: 'on_hold',
    options: {
      setCellHeaderProps: () => ({ align: 'center' }),
      setCellProps: () => ({ align: 'center' }),
    },
  },
  {
    name: 'Overdue',
    selector: 'overdue',
    options: {
      setCellHeaderProps: () => ({ align: 'center' }),
      setCellProps: () => ({ align: 'center' }),
    },
  },
  {
    name: 'Closed',
    selector: 'closed',
    options: {
      setCellHeaderProps: () => ({ align: 'center' }),
      setCellProps: () => ({ align: 'center' }),
    },
  },
  {
    name: 'Last Inspected Date',
    selector: 'last_inspected',
    options: {
      setCellHeaderProps: () => ({ align: 'center' }),
      setCellProps: () => ({ align: 'center' }),
    },
  },
];

export default (props) => {
  const classes = useStyles();
  const options = {
    filter: false,
    search: false,
    print: false,
    download: false,
    viewColumns: false,
    responsive: 'standard',
    selectableRows: 'none',
    rowsPerPage: props.tableData.length,
    rowsPerPageOptions: [props.tableData.length],
    sort: false,
    elevation: 0,
    textLabels: {
      body: {
        noMatch: <img src={NoData} style={{ height: '70vh' }} />,
      },
    },
  };

  const AssetContent = ({ children }) => {
    return (
      <Typography component="p" className={classes.content}>
        {children}
      </Typography>
    );
  };

  const AssetNameContent = (x) => {
    return (
      <div className="d-flex align-items-center">
        <Card>
          <CardActionArea>
            <CardMedia
              className={classes.media}
              image={`${process.env.REACT_APP_S3}/${!!x.image ? x.image : 'static/media/defaultAssetImg-01.png'}`}
            />
          </CardActionArea>
        </Card>
        <Typography gutterBottom className={classes.title}>{x?.name}</Typography>
      </div>
    );
  };

  return (
    <>
      <MuiThemeProvider theme={getMuiTheme()}>
        <MUIDataTable
          options={options}
          columns={columns.map((col) => ({ ...col, label: col.name, name: col.selector }))}
          data={props.tableData.sort((a, b) => b.issue[0].value - a.issue[0].value).map((x) => {
            return ({
              ...x,
              name: <AssetNameContent {...x} />,
              location: <AssetContent children={`${x?.location}, ${x?.state}`} />,
              last_update: <AssetContent children={`Last Update on ${moment(x?.updatedAt).format('D MMMM YYYY, hh:mm A')}`} />,
              open: x.issue[0].value,
              in_progress: x.issue[1].value,
              on_hold: x.issue[2].value,
              overdue: x.issue[3].value,
              closed: x.issue[4].value,
              last_inspected: !['No Inspections', '', null].includes(x.lastinspection) ? moment(x.lastinspection).format('DD MMMM YYYY') : 'No Inspections',
            });
          })}
        />
      </MuiThemeProvider>
    </>
  );
};

const getMuiTheme = () => createMuiTheme({
  typography: {
    fontFamily: 'CeraProRegular',
  },
  overrides: {
    MuiTableHead: {
      root: {
        '& .MuiTableCell-root': {
          color: 'gray',
          fontSize: '13px',
          fontWeight: 500,
          textTransform: 'uppercase',
        },
      },
    },
    MuiTableRow: {
      root: {
        color: '#048279',
      },
    },
    MuiTableCell: {
      body: {
        color: 'var(--primary-color) !important',
        fontSize: 14,
        fontWeight: '400px',
      },
    },
    MuiChip: {
      root: {
        backgroundColor: '#03A69A',
        color: 'white',
      },
      deleteIcon: {
        backgroundColor: '#03A69A',
        color: 'white',
      },
    },
  },
});
