import { useEffect, useState } from 'react';
import { Grid, IconButton, Tooltip } from '@material-ui/core';
import { Link, Redirect } from 'react-router-dom';
import MaterialTable from '@Components/MaterialTable';
import HighlightTabs from '@Components/HighlightTabs';
import GetAppIcon from '@material-ui/icons/GetApp';
import EditIcon from '@material-ui/icons/Edit';
import { ViewIcon, DeleteIcon } from '@Assets/Icons';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import Navbar from '@Components/Navbar';
import NoDataImg from '@Assets/Images/Data-not-found3.svg';
import DeleteDialog from '@Components/DeleteDialog';
import MainContentContainer from '@Components/MainContentContainer';
import moment from 'moment';
import Hook from './hook';
import GenerateReport from './GenerateReport';
import EditReport from './components/EditReport';
import { Refresh } from '@material-ui/icons';

const columns = [
  {
    name: 'REPORT ID',
    selector: 'id',
    align: 'left',
    minWidth: 70,
  },
  {
    name: 'REPORT GENERATED',
    selector: 'createdAt',
    align: 'center',
    minWidth: 220,
  },
  {
    name: 'DOWNLOAD',
    selector: 'download',
    align: 'center',
    minWidth: 100,
  },
  {
    name: 'ACTIONS',
    selector: 'delete',
    align: 'center',
    minWidth: 100,
  },
];

const columnsSR = [
  {
    name: 'ID',
    selector: 'id',
    align: 'left',
    minWidth: 60,
  },
  {
    name: 'FILE',
    selector: 'file_path',
    align: 'left',
    minWidth: 470,
  },
  {
    name: 'DATE',
    selector: 'created_at',
    align: 'center',
    minWidth: 100,
  },
  {
    name: 'ACTIONS',
    selector: 'action',
    align: 'center',
    minWidth: 100,
  },
];

const tabslist = [
  // { label: 'GENERATED REPORT', value: 'generated' },
  { label: 'GENERATED REPORT', value: 'generatedReport' },
  { label: 'UPLOADED REPORT', value: 'siteReports' },
];

export default function Report(props) {
  const h = Hook(props);
  const [tab, setTab] = useState('siteReports');
  const [openDialog, setOpenDialog] = useState(false);
  const showDelete = props.selectedPhaseList[props.category]?.removeReportAccess;

  const deleteButton = (report) => (
    showDelete && (
      <>
        {tab === 'siteReports' && (
          <>
            <Tooltip title="View Report">
              <Link to={{ pathname: `${report.raw_path ? report.raw_path : report.path}` }} target="_blank">
                <IconButton>
                  <ViewIcon height="20px" width="20px" />
                </IconButton>
              </Link>
            </Tooltip>
            <Tooltip title="Edit Report">
              <IconButton onClick={() => {
                h.setSelectedReport(report);
                h.setReportName(report.name);
                h.setOpenEditDialog(true);
              }}
              >
                <EditIcon height="20px" width="20px" style={{ color: 'var(--primary-color)' }} />
              </IconButton>
            </Tooltip>
          </>
        )}
        <Tooltip title="Delete Report">
          <IconButton onClick={() => { h.setSelectedReport(report); setOpenDialog(true); }}>
            <DeleteIcon height="20px" width="20px" style={{ color: 'red' }} />
          </IconButton>
        </Tooltip>
      </>
    )
  );
  useEffect(() => {
    if (!props.queryParam.id) return;
    if (!props.user?.can_view_report) return <Redirect to="/project" />;
  }, [props.queryParam.id, props.user]);
  return (
    <MainContentContainer>
      <EditReport
        open={h.openEditDialog}
        setOpen={h.setOpenEditDialog}
        selected={h.selectedReport}
        setSelected={h.setSelectedReport}
        {...h}
      />
      <div className="d-flex justify-content-between">
        <Navbar
          to="/project/"
          text="Report"
          subtext={props.filtered_projects[props.selected_project]?.name}
        />
      </div>
      <div className="d-flex justify-content-between align-items-center py-2">
        <HighlightTabs items={tabslist} tab={tab} setTab={setTab} />
        {tab === 'generatedReport' && (
          <div>
            <IconButton onClick={() => { h.refreshGenerate({ AssetId: props.project.id }); }}>
              <Refresh height="20px" width="20px" />
            </IconButton>
            <GenerateReport tab={tab} {...props} setJustUpdated={h.setJustUpdated} />
          </div>
        )}
      </div>
      <Grid item xs={12} style={{ padding: 20 }}>
        {tab === 'generatedReport' ? (
          <MaterialTable
            tableHead
            columns={columns}
            tableData={h.reports.map(x => ({
              ...x,
              createdAt: moment(x.createdAt).format('DD/MM/YYYY hh:mm A'),
              download: !x.image ? 'Pending' : (
                <Link to={{ pathname: `${process.env.REACT_APP_S3}/${x.image}` }} target="_blank">
                  <IconButton style={{
                    color: 'white', background: 'var(--primary-color)', width: 12, height: 12,
                  }}
                  ><GetAppIcon />
                  </IconButton>
                </Link>
              ),
              delete: deleteButton(x),
            }))}
            tableMinWidth={300}
            tableMaxHeight={900}
          />
        ) : (
          <>
            {!!h[tab]?.length ? (
              <MaterialTable
                tableHead
                columns={columnsSR}
                tableData={h[tab].map(x => {
                  const eachfileName = x.name ? x.name?.split(',') : '';
                  const eachfile = x.path ? x.path.split(',') : x.raw_path.split(',');
                  return ({
                    ...x,
                    created_at: moment(x.createdAt).format('DD/MM/YYYY'),
                    file_path: x.path === '' ? 'N/A' : eachfile?.map((file, idx) => (
                      <>
                        <IconButton style={{
                          color: 'white', background: 'var(--primary-color)', width: 12, height: 12, cursor: 'default',
                        }}
                        ><InsertDriveFileIcon />
                        </IconButton> &nbsp; {!!x.name ? eachfileName[idx] : 'Unnamed Files'}
                        <br />
                      </>
                    )),
                    action: deleteButton(x),
                  });
                })}
                tableMinWidth={300}
                tableMaxHeight={900}
              />
            ) : (
              <div className="d-flex justify-content-center">
                <img src={NoDataImg} style={{ width: '30vw' }} />
              </div>
            )}
          </>
        )}
      </Grid>
      <DeleteDialog
        open={openDialog}
        setOpen={setOpenDialog}
        selected={h.selectedReport}
        setSelected={h.setSelectedReport}
        deleteFunction={() => (tab === 'generatedReport' ? h.onDelete(h.selectedReport.id) : h.deleteSiteReport(h.selectedReport.id))}
      />
    </MainContentContainer>
  );
}
