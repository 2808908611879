import React, { useEffect } from 'react'
import MainContentNavbar from '@Components/MainContentNavbar';
import MainContentContainer from '@Components/MainContentContainer';
import Step from './Step';
import useHook from './hook';
import AssetType from './AssetType';
import CreateAssetForm from './CreateAssetForm';
import Preview from './Preview';
import PreviewAfter from './PreviewAfter';

export default () => {
  const h = useHook();
  const buttonStyle = {
    height: 30,
  }
  return (
    <MainContentContainer style={{ minHeight: '90vh' }}>
      <div className="d-flex justify-content-between align-items-center">
        <MainContentNavbar text="Add New Asset" />
        <a
          href="https://www.youtube.com/watch?v=CDVWmSz1yd8&t=8s"
          target="_blank"
          rel="noopener noreferrer"
          className="color-text-primary"
          style={{ fontSize: 14 }}
        >
          Learn More
        </a>
      </div>
      <Step {...h} />
      {{
        0: <AssetType {...h} buttonStyle={buttonStyle} />,
        1: <CreateAssetForm {...h} buttonStyle={buttonStyle} />,
        2: <Preview {...h} buttonStyle={buttonStyle} />,
        3: <PreviewAfter {...h} buttonStyle={buttonStyle} />,
      }[h.activeStep]}
    </MainContentContainer>
  );
};
