/* eslint-disable max-lines */
/* eslint-disable max-len */
import { primaryColor } from '@Assets/css';

export const TransactionOverviewIcon = (props) => {
  return (
    <svg width="45" height="42" viewBox="0 0 45 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M39.7674 24.675C39.7674 24.2573 39.6021 23.8567 39.3077 23.5613C39.0133 23.2659 38.614 23.1 38.1977 23.1H31.9186C31.5023 23.1 31.103 23.2659 30.8086 23.5613C30.5142 23.8567 30.3488 24.2573 30.3488 24.675V38.85H27.2093V4.725C27.2093 3.1962 27.2051 2.205 27.1088 1.4784C27.0167 0.7959 26.8681 0.5817 26.7488 0.462C26.6295 0.3423 26.416 0.1932 25.7358 0.1008C25.0095 0.0042002 24.0237 0 22.5 0C20.9763 0 19.9884 0.0042002 19.2642 0.1008C18.584 0.1932 18.3705 0.3423 18.2512 0.462C18.1319 0.5817 17.9833 0.7959 17.8912 1.4784C17.7949 2.2071 17.7907 3.1962 17.7907 4.725V38.85H14.6512V14.175C14.6512 13.7573 14.4858 13.3567 14.1914 13.0613C13.897 12.7659 13.4977 12.6 13.0814 12.6H6.80233C6.386 12.6 5.98672 12.7659 5.69233 13.0613C5.39794 13.3567 5.23256 13.7573 5.23256 14.175V38.85H1.56977C1.15344 38.85 0.754163 39.0159 0.459774 39.3113C0.165386 39.6067 0 40.0073 0 40.425C0 40.8427 0.165386 41.2433 0.459774 41.5387C0.754163 41.8341 1.15344 42 1.56977 42H43.4302C43.8466 42 44.2458 41.8341 44.5402 41.5387C44.8346 41.2433 45 40.8427 45 40.425C45 40.0073 44.8346 39.6067 44.5402 39.3113C44.2458 39.0159 43.8466 38.85 43.4302 38.85H39.7674V24.675Z" fill="white" />
    </svg>
  );
};

export const TransferTokenIcon = (props) => {
  return (
    <svg width="45" height="43" viewBox="0 0 45 43" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M34.8162 30.9301C34.8161 31.535 34.593 32.1188 34.1893 32.5708C33.7856 33.0228 33.2293 33.3116 32.6259 33.3823L32.3356 33.3996L8.47029 33.3996L13.0047 37.9139C13.4543 38.3573 13.7167 38.955 13.7382 39.5847C13.7596 40.2143 13.5386 40.8284 13.1202 41.3011C12.7018 41.7738 12.1177 42.0694 11.4876 42.1274C10.8574 42.1855 10.2288 42.0015 9.73041 41.6133L9.49723 41.4058L0.726023 32.676C0.29893 32.2508 0.0423608 31.685 0.00445421 31.0849C-0.0334486 30.4847 0.149912 29.8914 0.520137 29.4163L0.726023 29.1841L9.49723 20.4519C9.94363 20.009 10.5426 19.7519 11.1726 19.7327C11.8025 19.7135 12.4161 19.9338 12.8888 20.3488C13.3615 20.7638 13.6579 21.3423 13.7176 21.9669C13.7774 22.5915 13.5961 23.2153 13.2106 23.7117L13.0047 23.9463L8.47277 28.4606L32.3356 28.4606C32.9935 28.4606 33.6245 28.7207 34.0896 29.1839C34.5548 29.647 34.8162 30.2751 34.8162 30.9301ZM44.1009 12.9198L35.3297 21.6496C35.1008 21.8855 34.8271 22.0736 34.5245 22.203C34.2218 22.3325 33.8964 22.4006 33.567 22.4034C33.2376 22.4063 32.911 22.3438 32.6061 22.2196C32.3013 22.0955 32.0243 21.9121 31.7914 21.6802C31.5585 21.4484 31.3743 21.1726 31.2496 20.8691C31.1249 20.5656 31.0621 20.2405 31.065 19.9125C31.0678 19.5846 31.1363 19.2606 31.2663 18.9593C31.3963 18.658 31.5852 18.3855 31.8222 18.1577L36.3566 13.6434L12.4937 13.6434C11.8359 13.6434 11.2049 13.3832 10.7397 12.9201C10.2745 12.457 10.0132 11.8288 10.0132 11.1739C10.0132 10.5189 10.2745 9.8908 10.7397 9.42767C11.2049 8.96455 11.8359 8.70436 12.4937 8.70436L36.3541 8.70436L31.8222 4.18513C31.3703 3.71938 31.1203 3.09557 31.1259 2.44807C31.1316 1.80056 31.3925 1.18118 31.8524 0.723306C32.3123 0.265436 32.9345 0.00571945 33.5848 9.28632e-05C34.2352 -0.00553372 34.8618 0.24338 35.3297 0.693224L44.1009 9.42547C44.5659 9.88857 44.8271 10.5166 44.8271 11.1714C44.8271 11.8263 44.5659 12.4567 44.1009 12.9198Z" fill="white"/>
    </svg>
  );
};

export const PurchaseTokenIcon = (props) => {
  return (
    <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M22.5 0C23.7426 0 24.75 1.00736 24.75 2.25V20.25H42.75C43.9926 20.25 45 21.2574 45 22.5C45 23.7426 43.9926 24.75 42.75 24.75H24.75V42.75C24.75 43.9926 23.7426 45 22.5 45C21.2574 45 20.25 43.9926 20.25 42.75V24.75H2.25C1.00736 24.75 0 23.7426 0 22.5C0 21.2574 1.00736 20.25 2.25 20.25H20.25V2.25C20.25 1.00736 21.2574 0 22.5 0Z" fill="#FFFEFF"/>
    </svg>

  );
};

export const RefundTokenIcon = (props) => {
  return (
    <svg width="39" height="35" viewBox="0 0 39 35" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M39 7.77778H0V1.94444C0 1.42875 0.205446 0.934169 0.571142 0.569515C0.936838 0.20486 1.43283 0 1.95 0H37.05C37.5672 0 38.0632 0.20486 38.4289 0.569515C38.7946 0.934169 39 1.42875 39 1.94444V7.77778ZM39 11.6667V33.0556C39 33.5713 38.7946 34.0658 38.4289 34.4305C38.0632 34.7951 37.5672 35 37.05 35H1.95C1.43283 35 0.936838 34.7951 0.571142 34.4305C0.205446 34.0658 0 33.5713 0 33.0556V11.6667H39ZM17.55 21.3889V16.5278L8.775 25.2778H29.25V21.3889H17.55Z" fill="#FFFEFF"/>
    </svg>

  );
};
