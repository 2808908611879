/* eslint-disable max-lines */
/* eslint-disable max-len */
import { primaryColor } from '@Assets/css';

export const OnlineCloud = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 31.969 23.995"
      {...props}
    >
      <path
        id="Path_1783"
        data-name="Path 1783"
        d="M24.114,87.846H7.777A7.852,7.852,0,0,1,0,79.938a7.956,7.956,0,0,1,5.176-7.466,11.031,11.031,0,0,1,21.539.05,7.889,7.889,0,0,1-2.6,15.324ZM15.946,66.375a8.586,8.586,0,0,0-8.455,7.279l-.124.834-.821.193a5.425,5.425,0,0,0,1.244,10.67H24.114A5.432,5.432,0,0,0,29.4,79.926a5.375,5.375,0,0,0-4.063-5.195l-.821-.193-.124-.834A8.611,8.611,0,0,0,15.946,66.375Z"
        transform="translate(0 -63.851)"
        fill="#4fd8af"
      />
    </svg>
  );
};

export const OfflineCloud = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 31.969 23.995"
      {...props}
    >
      <g
        id="nvr_offline"
        data-name="nvr offline"
        transform="translate(0 -63.851)"
      >
        <path
          id="Path_1783"
          data-name="Path 1783"
          d="M24.114,87.846H7.777A7.852,7.852,0,0,1,0,79.938a7.956,7.956,0,0,1,5.176-7.466,11.031,11.031,0,0,1,21.539.05,7.889,7.889,0,0,1-2.6,15.324ZM15.946,66.375a8.586,8.586,0,0,0-8.455,7.279l-.124.834-.821.193a5.425,5.425,0,0,0,1.244,10.67H24.114A5.432,5.432,0,0,0,29.4,79.926a5.375,5.375,0,0,0-4.063-5.195l-.821-.193-.124-.834A8.611,8.611,0,0,0,15.946,66.375Z"
          fill="#ea1601"
        />
        <path
          id="Path_1784"
          data-name="Path 1784"
          d="M197.224,222.227l-1.761-1.761-2.389,2.383-2.389-2.383-1.761,1.761,2.389,2.383L188.924,227l1.761,1.761,2.389-2.389,2.389,2.389L197.224,227l-2.389-2.389Z"
          transform="translate(-177.128 -146.836)"
          fill="#ea1601"
        />
      </g>
    </svg>
  );
};

export const Updated = (props) => {
  return (
    <svg
      id="updated"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 21.455 24.002"
      {...props}
    >
      <path
        id="Path_1785"
        data-name="Path 1785"
        d="M1.514,40.486a9.1,9.1,0,0,0,3.876,7.457.751.751,0,0,1,.038,1.206l-.011.009a.749.749,0,0,1-.892.028A10.612,10.612,0,0,1,0,40.486,10.731,10.731,0,0,1,10.655,29.863v1.515a9.216,9.216,0,0,0-9.141,9.109Zm0,0"
        transform="translate(0 -28.463)"
        fill="#4fd8af"
      />
      <path
        id="Path_1786"
        data-name="Path 1786"
        d="M225.061.045l2.783,1.9a.257.257,0,0,1,0,.424l-2.783,1.9a.257.257,0,0,1-.4-.212V.257a.257.257,0,0,1,.4-.212Zm0,0"
        transform="translate(-214.128 0)"
        fill="#1ab975"
      />
      <path
        id="Path_1787"
        data-name="Path 1787"
        d="M239.7,75.887a9.1,9.1,0,0,0-3.876-7.457.751.751,0,0,1-.038-1.206l.011-.009a.749.749,0,0,1,.892-.028,10.612,10.612,0,0,1,4.526,8.7,10.724,10.724,0,0,1-10.647,10.624V85a9.209,9.209,0,0,0,9.133-9.109Zm0,0"
        transform="translate(-219.755 -63.909)"
        fill="#4fd8af"
      />
      <path
        id="Path_1788"
        data-name="Path 1788"
        d="M165.6,424.076l-2.783-1.9a.257.257,0,0,1,0-.424l2.783-1.9a.257.257,0,0,1,.4.212v3.808A.257.257,0,0,1,165.6,424.076Zm0,0"
        transform="translate(-155.074 -400.119)"
        fill="#1ab975"
      />
      <path
        id="Path_1789"
        data-name="Path 1789"
        d="M128.611,187.318l-4.559,3.781-1.149.953a1.5,1.5,0,0,1-2.164-.255l-1.773-2.363a1.209,1.209,0,0,1,1.934-1.451l1.2,1.6.01-.008,4.961-4.114a1.209,1.209,0,1,1,1.543,1.861Zm0,0"
        transform="translate(-113.159 -176.498)"
        fill="#1ed688"
      />
      <path
        id="Path_1790"
        data-name="Path 1790"
        d="M197.387,187.318l-4.559,3.781a6.124,6.124,0,0,1-1.945-1.528l4.961-4.114a1.209,1.209,0,0,1,1.543,1.861Zm0,0"
        transform="translate(-181.935 -176.498)"
        fill="#4fd8af"
      />
    </svg>
  );
};

export const Pending = (props) => {
  return (
    <svg
      id="updated"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 21.455 24.002"
      {...props}
    >
      <path
        id="Path_1785"
        data-name="Path 1785"
        d="M1.514,40.486a9.1,9.1,0,0,0,3.876,7.457.751.751,0,0,1,.038,1.206l-.011.009a.749.749,0,0,1-.892.028A10.612,10.612,0,0,1,0,40.486,10.731,10.731,0,0,1,10.655,29.863v1.515a9.216,9.216,0,0,0-9.141,9.109Zm0,0"
        transform="translate(0 -28.463)"
        fill="#ea1601"
      />
      <path
        id="Path_1786"
        data-name="Path 1786"
        d="M225.061.045l2.783,1.9a.257.257,0,0,1,0,.424l-2.783,1.9a.257.257,0,0,1-.4-.212V.257a.257.257,0,0,1,.4-.212Zm0,0"
        transform="translate(-214.128 0)"
        fill="#df1f0b"
      />
      <path
        id="Path_1787"
        data-name="Path 1787"
        d="M239.7,75.887a9.1,9.1,0,0,0-3.876-7.457.751.751,0,0,1-.038-1.206l.011-.009a.749.749,0,0,1,.892-.028,10.612,10.612,0,0,1,4.526,8.7,10.724,10.724,0,0,1-10.647,10.624V85a9.209,9.209,0,0,0,9.133-9.109Zm0,0"
        transform="translate(-219.755 -63.909)"
        fill="#ea1601"
      />
      <path
        id="Path_1788"
        data-name="Path 1788"
        d="M165.6,424.076l-2.783-1.9a.257.257,0,0,1,0-.424l2.783-1.9a.257.257,0,0,1,.4.212v3.808A.257.257,0,0,1,165.6,424.076Zm0,0"
        transform="translate(-155.074 -400.119)"
        fill="#df1f0b"
      />
    </svg>
  );
};

export const User = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 25.168 28.065"
      {...props}
    >
      <g
        id="Icon_feather-user"
        data-name="Icon feather-user"
        transform="translate(1 1)"
      >
        <path
          id="Path_1"
          data-name="Path 1"
          d="M27.168,23.688v-2.9A5.792,5.792,0,0,0,21.376,15H9.792A5.792,5.792,0,0,0,4,20.792v2.9"
          transform="translate(-4 2.376)"
          fill="none"
          stroke={primaryColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Path_2"
          data-name="Path 2"
          d="M19.584,8.792A5.792,5.792,0,1,1,13.792,3a5.792,5.792,0,0,1,5.792,5.792Z"
          transform="translate(-2.208 -3)"
          fill="none"
          stroke={primaryColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
};

export const Lock = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 28.208 31.12"
      {...props}
    >
      <g
        id="Icon_feather-lock"
        data-name="Icon feather-lock"
        transform="translate(1 1)"
      >
        <path
          id="Path_3"
          data-name="Path 3"
          d="M5.912,11H26.3a2.912,2.912,0,0,1,2.912,2.912V24.1A2.912,2.912,0,0,1,26.3,27.016H5.912A2.912,2.912,0,0,1,3,24.1V13.912A2.912,2.912,0,0,1,5.912,11Z"
          transform="translate(-3 2.104)"
          fill="none"
          stroke={primaryColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Path_4"
          data-name="Path 4"
          d="M7,15.1V9.28a7.28,7.28,0,1,1,14.56,0V15.1"
          transform="translate(-1.176 -2)"
          fill="none"
          stroke={primaryColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
};

export const CCTVIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      fill="#FFFFFF"
      {...props}
    >
      <path d="M506.625 235.884L217.344 70.434c-22.313-12.782-52.854-5.891-67.563 15.159L93.74 165.707a46.384 46.384 0 00-8.406 26.701c0 16.588 8.917 32.039 23.26 40.317l61.01 35.233-11.021 14.707c-5.969 7.955-9.25 17.818-9.25 27.765 0 10.229 3.746 19.851 9.78 27.805l-16.238 24.433H64v-32.105c0-5.901-4.771-10.561-10.667-10.561H10.667C4.771 320.002 0 324.662 0 330.563v106.763c0 5.901 4.771 10.676 10.667 10.676h42.667c5.896 0 10.667-4.775 10.667-10.676v-10.657h78.875c21.448 0 41.354-10.654 53.25-28.513l17.484-26.25c3.367.731 6.763 1.394 10.234 1.394 16.979 0 32.958-9.05 41.688-23.625l11.72-19.551 90.78 52.425a10.62 10.62 0 005.333 1.428c1.031 0 2.063-.146 3.073-.448a10.713 10.713 0 006.469-5.453l7.897-15.829c1.296.098 2.543.378 3.863.378 41.167 0 74.667-33.53 74.667-74.734 0-4.525-.668-9.188-1.661-13.955l38.432-19.231a10.69 10.69 0 005.896-9.258 10.687 10.687 0 00-5.376-9.563zM42.667 426.669H21.333v-85.333h21.333v85.333zm135.708-40.302a42.572 42.572 0 01-35.5 19H64v-21.333h78.875c7.167 0 13.802-3.552 17.75-9.5l14.771-22.164 18.318 10.988-15.339 23.009zm68.865-47.584c-7.458 12.406-25.01 16.823-37.427 9.354l-27.052-16.229c-7.458-4.469-12.094-12.656-12.094-21.354a25.12 25.12 0 014.979-14.948l12.564-16.751 70.56 40.711-11.53 19.217zm121.645 19.688L119.25 214.439c-7.76-4.479-12.583-12.833-12.583-21.802a25.076 25.076 0 014.552-14.448L138 139.939l263.967 152.284-33.082 66.248zm32.735-17.808l22.328-44.714 23.695-11.846c.119 1.309.357 2.664.357 3.93 0 27.025-20.283 49.18-46.38 52.63zm14.742-64.769l-266.078-153.5 16.977-24.246a30.042 30.042 0 0124.594-12.813 30.057 30.057 0 0114.906 3.958L478.74 244.71l-62.378 31.184z" />
      <circle cx={352.002} cy={309.335} r={10.667} />
    </svg>
  );
};

export const Cube3D = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill={props.color}
      viewBox="0 0 512 512"
      width="22.522"
      height="26.007"
      {...props}
    >
      <path d="m255.989 0-221.693 127.993v255.985l221.693 127.994 221.688-127.994v-255.985zm176.683 136.651-176.683 101.965-176.688-101.965 176.688-102.01zm-368.376 25.977 176.693 101.969v204.074l-176.693-102.013zm206.693 306.043v-204.074l176.688-101.968v204.03z" />
    </svg>
  );
};

export const Icon360 = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="#ffffff"
      viewBox="0 0 512 420"
      width="35.059"
      height="26.004"
      {...props}
    >
      <path d="m322.285156 335.644531c-7.441406 0-13.898437-5.53125-14.863281-13.105469-1.042969-8.21875 4.769531-15.726562 12.984375-16.773437 47.398438-6.039063 89.84375-18.882813 119.515625-36.171875 27.136719-15.808594 42.078125-34.394531 42.078125-52.332031 0-19.769531-17.484375-35.945313-32.15625-46.039063-6.824219-4.695312-8.550781-14.03125-3.855469-20.859375 4.695313-6.824219 14.035157-8.550781 20.859375-3.855469 29.539063 20.320313 45.152344 44.785157 45.152344 70.757813 0 29.476563-19.699219 56.535156-56.972656 78.25-33.550782 19.546875-78.789063 33.382813-130.828125 40.011719-.644531.078125-1.285157.117187-1.914063.117187zm0 0" />
      <path d="m252.34375 314.15625-40-40c-5.859375-5.859375-15.355469-5.859375-21.214844 0-5.855468 5.855469-5.855468 15.355469 0 21.210938l11.6875 11.6875c-44.8125-4.628907-85.523437-15.0625-117.046875-30.222657-35.441406-17.042969-55.769531-38.757812-55.769531-59.570312 0-17.652344 14.554688-36 40.980469-51.664063 7.128906-4.222656 9.480469-13.425781 5.257812-20.550781-4.226562-7.128906-13.429687-9.480469-20.554687-5.257813-46.023438 27.28125-55.683594 57.1875-55.683594 77.472657 0 33.28125 25.84375 64.039062 72.769531 86.609375 36.421875 17.511718 83.535157 29.242187 134.863281 33.78125l-16.503906 16.503906c-5.855468 5.855469-5.855468 15.355469 0 21.214844 2.929688 2.925781 6.769532 4.390625 10.609375 4.390625 3.835938 0 7.675781-1.464844 10.605469-4.390625l40-40c5.855469-5.859375 5.855469-15.359375 0-21.214844zm0 0" />
      <path d="m157.097656 187.222656v-3.609375c0-12.730469-7.792968-15.199219-18.242187-15.199219-6.460938 0-8.550781-5.699218-8.550781-11.398437 0-5.703125 2.089843-11.402344 8.550781-11.402344 7.21875 0 14.820312-.949219 14.820312-16.339843 0-11.019532-6.269531-13.679688-14.0625-13.679688-9.308593 0-14.058593 2.28125-14.058593 9.691406 0 6.457032-2.851563 10.828125-13.871094 10.828125-13.679688 0-15.386719-2.851562-15.386719-11.972656 0-14.816406 10.636719-34.007813 43.316406-34.007813 24.132813 0 42.371094 8.738282 42.371094 34.390626 0 13.867187-5.128906 26.789062-14.628906 31.160156 11.210937 4.179687 19.378906 12.539062 19.378906 27.929687v3.609375c0 31.160156-21.46875 42.941406-48.070313 42.941406-32.679687 0-45.21875-19.949218-45.21875-35.910156 0-8.550781 3.609376-10.832031 14.058594-10.832031 12.160156 0 15.199219 2.660156 15.199219 9.882813 0 8.929687 8.363281 11.019531 16.910156 11.019531 12.921875 0 17.484375-4.75 17.484375-17.101563zm0 0" />
      <path d="m302.066406 183.613281v1.710938c0 32.679687-20.332031 44.839843-46.550781 44.839843s-46.742187-12.160156-46.742187-44.839843v-50.351563c0-32.679687 21.089843-44.839844 48.453124-44.839844 32.109376 0 44.839844 19.949219 44.839844 35.71875 0 9.121094-4.371094 11.96875-13.871094 11.96875-8.167968 0-15.390624-2.089843-15.390624-10.828124 0-7.21875-7.597657-11.019532-16.527344-11.019532-11.210938 0-17.863282 5.890625-17.863282 19v17.097656c6.082032-6.648437 14.632813-8.359374 23.753907-8.359374 21.65625 0 39.898437 9.5 39.898437 39.902343zm-63.652344 3.800781c0 13.109376 6.460938 18.808594 17.101563 18.808594s16.910156-5.699218 16.910156-18.808594v-1.710937c0-13.871094-6.269531-19.191406-17.101562-19.191406-10.257813 0-16.910157 4.941406-16.910157 17.480469zm0 0" />
      <path d="m325.054688 185.324219v-50.351563c0-32.679687 20.328124-44.839844 46.550781-44.839844 26.21875 0 46.738281 12.160157 46.738281 44.839844v50.351563c0 32.679687-20.519531 44.839843-46.738281 44.839843-26.222657 0-46.550781-12.160156-46.550781-44.839843zm63.648437-50.351563c0-13.109375-6.457031-19-17.097656-19s-16.910157 5.890625-16.910157 19v50.351563c0 13.109375 6.269532 19 16.910157 19s17.097656-5.890625 17.097656-19zm0 0" />
      <path d="m454.351562 90c-24.816406 0-45-20.1875-45-45s20.183594-45 45-45c24.8125 0 45 20.1875 45 45s-20.1875 45-45 45zm0-60c-8.273437 0-15 6.730469-15 15 0 8.273438 6.726563 15 15 15 8.269532 0 15-6.726562 15-15 0-8.269531-6.730468-15-15-15zm0 0" />
    </svg>

  );
};

export const Search = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35.059"
      height="26.004"
      viewBox="0 0 29.018 29.018"
      {...props}
    >
      <g
        id="Icon_feather-search"
        data-name="Icon feather-search"
        transform="translate(-3.25 -3.25)"
      >
        <path
          id="Path_1648"
          data-name="Path 1648"
          d="M27.611,16.056A11.556,11.556,0,1,1,16.056,4.5,11.556,11.556,0,0,1,27.611,16.056Z"
          fill="none"
          stroke={props.color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2.5"
        />
        <path
          id="Path_1649"
          data-name="Path 1649"
          d="M31.258,31.258l-6.283-6.283"
          transform="translate(-0.758 -0.758)"
          fill="none"
          stroke={props.color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2.5"
        />
      </g>
    </svg>
  );
};
export const IconAttendance = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="25"
      viewBox="0 0 25.5 30"
      {...props}
    >
      <g
        id="Icon_feather-user-check"
        data-name="Icon feather-user-check"
        transform="translate(0 -3)"
      >
        <path
          id="Path_1684"
          data-name="Path 1684"
          d="M24,31.5v-3a6,6,0,0,0-6-6H7.5a6,6,0,0,0-6,6v3"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
        />
        <path
          id="Path_1685"
          data-name="Path 1685"
          d="M18.75,10.5a6,6,0,1,1-6-6A6,6,0,0,1,18.75,10.5Z"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
        />
      </g>
    </svg>
  );
};
export const ColorPickerIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27.004"
      height="25.5"
      viewBox="0 0 27.004 25.5"
      {...props}
    >
      <g
        id="Icon_material-format-color-fill"
        data-name="Icon material-format-color-fill"
        transform="translate(-4.496)"
      >
        <path
          id="Path_2022"
          data-name="Path 2022"
          d="M24.84,13.41,11.43,0,9.315,2.115l3.57,3.57L5.16,13.41a2.236,2.236,0,0,0,0,3.18l8.25,8.25a2.245,2.245,0,0,0,3.18,0l8.25-8.25A2.236,2.236,0,0,0,24.84,13.41ZM7.815,15,15,7.815,22.185,15ZM28.5,17.25s-3,3.255-3,5.25a3,3,0,0,0,6,0C31.5,20.5,28.5,17.25,28.5,17.25Z"
          fill={props.color}
        />
      </g>
    </svg>

  );
};

export const ReportIcon = (props) => {
  return (
    <svg id="_005-file-1" data-name="005-file-1" xmlns="http://www.w3.org/2000/svg" width="22.349" height="26.006" viewBox="0 0 22.349 26.006" transform={props.transform}>
      <path id="Path_1868" data-name="Path 1868" d="M22.621,5.436,17.308.218A.762.762,0,0,0,16.774,0H3.342A2.846,2.846,0,0,0,.5,2.843v20.32a2.846,2.846,0,0,0,2.843,2.843H20.006a2.846,2.846,0,0,0,2.843-2.843V5.98a.762.762,0,0,0-.228-.544ZM17.536,2.578l2.688,2.64H17.778a.243.243,0,0,1-.242-.242Zm2.47,21.9H3.342a1.321,1.321,0,0,1-1.319-1.319V2.843A1.32,1.32,0,0,1,3.342,1.524h12.67V4.976a1.768,1.768,0,0,0,1.766,1.766h3.547V23.163a1.32,1.32,0,0,1-1.319,1.319Zm0,0" transform="translate(-0.5 0)" fill={props.color} />
      <path id="Path_1869" data-name="Path 1869" d="M107.1,238h-8.32a.787.787,0,0,0,0,1.524h8.32a.787.787,0,0,0,0-1.524Zm0,0" transform="translate(-92.485 -219.447)" fill={props.color} />
      <path id="Path_1870" data-name="Path 1870" d="M99.04,299.524H108.8a.766.766,0,1,0,0-1.524H99.04a.766.766,0,1,0,0,1.524Zm0,0" transform="translate(-92.484 -283.423)" fill={props.color} />
      <path id="Path_1871" data-name="Path 1871" d="M108.765,358H98.953a.762.762,0,0,0,0,1.524h9.812a.762.762,0,0,0,0-1.524Zm0,0" transform="translate(-92.543 -347.398)" fill={props.color} />
    </svg>

  );
};

export const AssetFile = (props) => {
  return (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 122.88 105.02" style={{ enableBackground: 'new 0 0 122.88 105.02' }} xmlSpace="preserve" width={props.width} height={props.height}>
      <g>
        <path d="M97.25,40.58l23.85,10.28c1.48,0.64,2.17,2.36,1.53,3.85c-0.32,0.75-0.93,1.3-1.63,1.57l-23.19,9.39l23.29,10.04 c1.48,0.64,2.17,2.36,1.53,3.84c-0.32,0.75-0.93,1.3-1.63,1.57l-58.52,23.69c-0.73,0.3-1.52,0.27-2.2,0L1.83,81.05 c-1.5-0.61-2.22-2.31-1.61-3.81c0.33-0.82,0.99-1.4,1.76-1.67l22.97-9.96l-23.12-9.4c-1.5-0.61-2.22-2.31-1.61-3.81 c0.33-0.82,0.99-1.4,1.76-1.67l23.53-10.21L1.83,30.9c-1.5-0.61-2.22-2.31-1.61-3.81c0.33-0.82,0.99-1.4,1.76-1.67L60.02,0.24 c0.77-0.33,1.6-0.31,2.31,0l0-0.01l58.77,25.32c1.48,0.64,2.17,2.36,1.53,3.84c-0.32,0.75-0.93,1.3-1.63,1.57L97.25,40.58 L97.25,40.58z M112.36,53.47l-22.73-9.79L62.49,54.66c-0.73,0.3-1.52,0.27-2.2,0L33.08,43.6L10.47,53.4L61.39,74.1L112.36,53.47 L112.36,53.47z M90.19,68.75l-27.7,11.21c-0.73,0.3-1.52,0.27-2.2,0L32.52,68.68l-22.05,9.56l50.92,20.69l50.97-20.63L90.19,68.75 L90.19,68.75z M61.17,6.1l-50.7,21.99l50.92,20.69l50.97-20.63L61.17,6.1L61.17,6.1z" fill={props.color} />
      </g>
    </svg>
  );
};

export const CCTV = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none" {...props}>
      <path d="M61.15 38.9999L57.59 36.4199L59 34.5399C59.3109 34.1113 59.4392 33.5769 59.3567 33.0539C59.2742 32.5309 58.9877 32.062 58.56 31.7499L22.71 5.74988C22.4975 5.59438 22.2563 5.48239 22.0004 5.42033C21.7444 5.35828 21.4788 5.34738 21.2186 5.38826C20.9584 5.42914 20.7089 5.521 20.4844 5.65856C20.2598 5.79613 20.0646 5.97669 19.91 6.18988L6.91 24.1899C6.7498 24.3991 6.63252 24.6379 6.56489 24.8926C6.49727 25.1472 6.48063 25.4127 6.51595 25.6739C6.55126 25.935 6.63782 26.1866 6.77066 26.4141C6.90349 26.6417 7.07999 26.8407 7.29 26.9999L24.45 39.4399L16.88 49.8799H6V47.1499C6 46.6195 5.78929 46.1107 5.41421 45.7357C5.03914 45.3606 4.53043 45.1499 4 45.1499C3.46957 45.1499 2.96086 45.3606 2.58579 45.7357C2.21071 46.1107 2 46.6195 2 47.1499V56.6099C2 57.1403 2.21071 57.649 2.58579 58.0241C2.96086 58.3992 3.46957 58.6099 4 58.6099C4.53043 58.6099 5.03914 58.3992 5.41421 58.0241C5.78929 57.649 6 57.1403 6 56.6099V53.8799H17.9C18.2164 53.8796 18.5283 53.8042 18.8099 53.6599C19.0915 53.5156 19.3349 53.3065 19.52 53.0499L27.69 41.7799L43.14 52.9999C43.5686 53.3108 44.1029 53.4391 44.6259 53.3566C45.1489 53.2741 45.6179 52.9876 45.93 52.5599L47.31 50.6499L50.87 53.2299C51.2986 53.5408 51.8329 53.6691 52.3559 53.5866C52.8789 53.5041 53.3479 53.2176 53.66 52.7899L61.6 41.7899C61.9099 41.3601 62.0365 40.8249 61.9521 40.3018C61.8678 39.7787 61.5793 39.3105 61.15 38.9999V38.9999ZM51.6 48.7999L49.66 47.3899C51.05 45.4699 53.85 41.5999 55.25 39.6799L57.19 41.0899L51.6 48.7999Z" fill={props.color} />
    </svg>
  );
};

export const Helmet = (props) => {
  return (
    <svg width="15px" height="15px" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M 13 7 L 13 8.40625 C 8.035156 9.6875 4.410156 14.082031 4.125 19.375 C 2.953125 19.785156 2 20.695313 2 22 L 2 25 L 30 25 L 30 22 C 30 20.695313 29.046875 19.785156 27.875 19.375 C 27.589844 14.082031 23.964844 9.6875 19 8.40625 L 19 7 Z M 15.03125 9 L 17 9 C 16.980469 9.113281 16.980469 9.230469 17 9.34375 L 17 15 L 19 15 L 19 10.5 C 22.738281 11.679688 25.566406 14.972656 25.96875 19 L 24 19 L 24 21 L 27 21 C 27.566406 21 28 21.433594 28 22 L 28 23 L 4 23 L 4 22 C 4 21.433594 4.433594 21 5 21 L 8 21 L 8 19 L 6.03125 19 C 6.433594 14.972656 9.261719 11.679688 13 10.5 L 13 15 L 15 15 L 15 9.5 C 15.050781 9.339844 15.0625 9.167969 15.03125 9 Z" fill="#d9d9d9" />
    </svg>
  );
};

export const ViewIcon = ({ color = 'var(--primary-color)', ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15.996" height="15.996" viewBox="0 0 23.996 15.996" {...props}>
      <g id="Icon_feather-eye" data-name="Icon feather-eye" transform="translate(-0.5 -5)">
        <path id="Path_2106" data-name="Path 2106" d="M12.5,5a10.588,10.588,0,0,1,5.157,1.375,15.176,15.176,0,0,1,3.7,2.943A19.874,19.874,0,0,1,24.4,13.556a1,1,0,0,1,0,.894,19.874,19.874,0,0,1-3.041,4.238,15.176,15.176,0,0,1-3.7,2.943,10.358,10.358,0,0,1-10.313,0,15.176,15.176,0,0,1-3.7-2.943A19.874,19.874,0,0,1,.606,14.45a1,1,0,0,1,0-.894A19.874,19.874,0,0,1,3.646,9.317a15.176,15.176,0,0,1,3.7-2.943A10.588,10.588,0,0,1,12.5,5Zm9.862,9a18.769,18.769,0,0,0-2.5-3.355C17.584,8.227,15.106,7,12.5,7s-5.08,1.227-7.363,3.648A18.766,18.766,0,0,0,2.641,14a18.769,18.769,0,0,0,2.5,3.355C7.424,19.778,9.9,21.006,12.5,21.006s5.08-1.227,7.363-3.648A18.766,18.766,0,0,0,22.366,14Z" fill={color} />
        <path id="Path_2107" data-name="Path 2107" d="M16.5,12.5a4,4,0,1,1-4,4A4.006,4.006,0,0,1,16.5,12.5Zm0,6a2,2,0,1,0-2-2A2,2,0,0,0,16.5,18.5Z" transform="translate(-3.997 -2.498)" fill={color} />
      </g>
    </svg>
  );
};

export const EditIcon = ({ color = 'var(--primary-color)', ...props }) => {
  return (
    <svg id="Component_128_25" data-name="Component 128 – 25" xmlns="http://www.w3.org/2000/svg" width="15.996" height="15.996" viewBox="0 0 15.996 15.996" {...props}>
      <path id="Icon_material-edit" data-name="Icon material-edit" d="M4.5,17.16v3.332H7.832l9.827-9.827L14.327,7.333ZM20.236,8.088a.885.885,0,0,0,0-1.253L18.157,4.756a.885.885,0,0,0-1.253,0L15.278,6.382,18.61,9.714Z" transform="translate(-4.5 -4.496)" fill={color} />
    </svg>
  );
};

export const DeleteIcon = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12.444" height="16" viewBox="0 0 12.444 16" {...props}>
      <path id="Icon_material-delete" data-name="Icon material-delete" d="M8.389,18.722A1.783,1.783,0,0,0,10.167,20.5h7.111a1.783,1.783,0,0,0,1.778-1.778V8.056H8.389ZM19.944,5.389H16.833L15.944,4.5H11.5l-.889.889H7.5V7.167H19.944Z" transform="translate(-7.5 -4.5)" fill="#ff1e1e" />
    </svg>
  );
};
