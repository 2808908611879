import { Grid } from '@material-ui/core';
import Navbar from '@Components/Navbar';
import ProfileCard from './components/ProfileCard';
import OrganizationCard from './components/OrganizationCard';
import ActivityLog from './components/ActivityLog';
import useHook from './hook';
import UserAccess from './components/UserAccess';

export default (props) => {
  const h = useHook();
  const isNotOrgAdminSelf = props.user?.id !== h.user?.id;
  return (
    <div className="mb-5">
      <div className="d-flex justify-content-between align-items-center">
        <Navbar to="/user" text="User Management" subtext={h.user?.name} />
        <a
          href="https://www.youtube.com/watch?v=x7oj0JukCak"
          target="_blank"
          rel="noopener noreferrer"
          className="color-text-primary"
          style={{ fontSize: 14 }}
        >
          Learn More
        </a>
      </div>
      <Grid container direction="row" justifyContent="space-evenly" spacing={3}>
        <Grid item xs={12} md={4} xl={isNotOrgAdminSelf ? 3 : 4}>
          <ProfileCard {...h} isNotSelf={isNotOrgAdminSelf} />
        </Grid>
        {isNotOrgAdminSelf ? (
          <Grid item container xs={12} md={4} xl={5}>
            <UserAccess {...h} />
          </Grid>
        ) : (
          <Grid item xs={12} md={4} xl={isNotOrgAdminSelf ? 4 : 4}>
            <OrganizationCard {...h} />
          </Grid>
        )}
        <Grid item container xs={12} md={4} xl={4}>
          <ActivityLog {...h} isNotSelf={isNotOrgAdminSelf} />
        </Grid>
      </Grid>
    </div>
  );
};
