import { useEffect, useState } from 'react';
import Api, { endpoints } from '@Helpers/api';

export default ({ user }) => {
  const [status, setStatus] = useState('');
  const [reference, setReference] = useState('');
  const [description, setDescription] = useState('');
  const [type, setType] = useState('');
  const [keyword, setKeyword] = useState('');
  const [filter, setFilter] = useState('');
  const [amount, setAmount] = useState(0);
  const [transactions, setTransactions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [perpage, setPerpage] = useState(30);
  const [totalData, setTotalData] = useState(100);
  const [tab, setTab] = useState(1);
  const [transactionId, setTransactionId] = useState('');
  const [recipientEmail, setRecipientEmail] = useState('');
  const [sourceEmail, setSourceEmail] = useState('');
  const [currentWalletAmount, setCurrentWalletAmount] = useState('');
  const [users, setUsers] = useState([]);
  const [hasSourceEmail, setHasSourceEmail] = useState(false);

  const handleChangeFilter = (e) => {
    const search = e.toString().split('-');
    setFilter(`status,${status},id,${search[0]},PaymentId,${search[1]},UserId,${search[2]}`);
  };

  const getTransactions = () => {
    setIsLoading(true);
    const data = {
      perpage,
      page,
      sortby: 'createdAt,DESC',
    };
    if (user?.RoleId === 1) data.filter = `${filter}${filter.length ? ',' : ''}`;
    if ([2, 3].includes(user?.RoleId)) data.filter = `${filter}${filter.length ? ',' : ''}UserId,${user?.id}`;
    Api({
      endpoint: endpoints.getTransactionHistory(),
      data,
      onSuccess: (res) => {
        setTransactions(res.data);
        setTotalData(res.total);
        setIsLoading(false);
      },
      onFail: () => {
        toast('error', 'Failed get transaction list');
      },
    });
    Api({
      endpoint: endpoints.getUsers(),
      onSuccess: (res) => {
        setUsers(res.data);
      },
      onFail: () => {
        toast('error', 'Failed get transaction list');
      },
    });
  };

  const getRefund = () => {
    if (!transactionId) return toast('error', 'Please put transaction ID');
    setIsLoading(true);
    Api({
      endpoint: endpoints.getRefundCoins(),
      data: { transactionId },
      onSuccess: () => {
        toast('success', `Successfully refund to transaction ID: ${transactionId}`);
        setTransactionId('');
        setTab(4);
        getTransactions();
        setIsLoading(false);
      },
      onFail: (e) => {
        const getErrorMessage = (err) => {
          if (typeof err === 'string') {
            return err;
          } if (typeof err === 'object') {
            return 'Failed to refund coins';
          }
          return 'Failed to refund coins';
        };
        toast('error', getErrorMessage(e));
        getTransactions();
      },
    });
  };

  const onWalletTransfer = () => {
    if (!recipientEmail) return toast('error', 'Please put email recipient');
    if (!Number(amount)) return toast('error', 'Please put transferable amount');
    const data = { recipientEmail, amount };
    if (hasSourceEmail && sourceEmail) data.sourceEmail = sourceEmail;
    setIsLoading(true);
    Api({
      endpoint: endpoints.createWalletTransfer(),
      data,
      onSuccess: () => {
        toast('success', `Successfully transfer ${amount} RCoin to ${recipientEmail}`);
        setRecipientEmail('');
        setSourceEmail('');
        setAmount(0);
        setTab(2);
        getTransactions();
        setIsLoading(false);
      },
      onFail: (e) => {
        const getErrorMessage = (err) => {
          if (typeof err === 'string') {
            return err;
          } if (typeof err === 'object') {
            return 'Failed to refund coins';
          }
          return 'Failed to refund coins';
        };
        toast('error', getErrorMessage(e));
        getTransactions();
      },
    });
  };

  useEffect(() => {
    setTransactionId('');
    setSourceEmail('');
    setRecipientEmail('');
    setAmount(0);
  }, [tab]);

  useEffect(() => {
    getTransactions();
  }, [page, filter, keyword, status, user?.id]);

  const onKeyDown = ({ key }) => {
    if (key === 'Enter') getTransactions();
  };
  return {
    status,
    setStatus,
    reference,
    setReference,
    description,
    setDescription,
    type,
    setType,
    amount,
    setAmount,
    transactions,
    onKeyDown,
    keyword,
    setKeyword,
    filter,
    setFilter,
    handleChangeFilter,
    isLoading,
    tab,
    setTab,
    transactionId,
    setTransactionId,
    getRefund,
    recipientEmail,
    setRecipientEmail,
    sourceEmail,
    setSourceEmail,
    currentWalletAmount,
    setCurrentWalletAmount,
    onWalletTransfer,
    users,
    setHasSourceEmail,
    hasSourceEmail,
  };
};
