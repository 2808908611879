/* eslint-disable max-nested-callbacks */
import * as L from 'leaflet';
import { SimpleMapScreenshoter } from 'leaflet-simple-map-screenshoter';

const snapshotOptions = {
  hideElementsWithSelectors: [
    '.leaflet-control-container',
    '.leaflet-dont-include-pane',
    '#snapshot-button',
  ],
  hidden: true,
};

// Add screenshotter to map
export const screenshotter = new SimpleMapScreenshoter(snapshotOptions);

// What happens when you clikc the "Snapshot Greek Border" button:

export const takeScreenShot = (map, polygonborder, cb) => {
  // Get bounds of feature, pad ot a but too
  const featureBounds = polygonborder ? polygonborder.getBounds().pad(0.1) : map.getBounds().pad(0.1);

  // Get pixel position on screen of top left and bottom right
  // of the bounds of the feature
  const nw = featureBounds.getNorthWest();
  const se = featureBounds.getSouthEast();
  const topLeft = map.latLngToContainerPoint(nw);
  const bottomRight = map.latLngToContainerPoint(se);

  // Get the resulting image size that contains the feature
  const imageSize = bottomRight.subtract(topLeft);

  // Set up screenshot function
  screenshotter
    .takeScreen('image')
    .then((image) => {
      // Create <img> element to render img data
      const img = new Image();

      // once the image loads, do the following:
      img.onload = () => {
        // Create canvas to process image data
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        // Set canvas size to the size of your resultant image
        canvas.width = imageSize.x;
        canvas.height = imageSize.y;

        // Draw just the portion of the whole map image that contains
        // your feature to the canvas
        // from https://stackoverflow.com/questions/26015497/how-to-resize-then-crop-an-image-with-canvas
        ctx.drawImage(
          img,
          topLeft.x,
          topLeft.y,
          imageSize.x,
          imageSize.y,
          0,
          0,
          imageSize.x,
          imageSize.y,
        );

        // Create URL for resultant png
        const imageurl = canvas.toDataURL('image/png');
        cb(imageurl);
        // setMainImageAnnotations(prevVal => prevVal.map(ann => {
        //   return !(polygonborder.idx === ann.id) ? ann : { ...ann, thumbnail: imageurl };
        // }));
        // show back on dom
        // const resultantImage = new Image();
        // resultantImage.style = 'border: 1px solid black';
        // resultantImage.src = imageurl;

        // document.body.appendChild(canvas);

        // canvas.toBlob((blob) => {
        //   // saveAs function installed as part of leaflet snapshot package
        //   console.log('blob', blob);
        //   // saveAs(blob, 'greek_border.png');
        // });
      };

      // set the image source to what the snapshotter captured
      // img.onload will fire AFTER this
      img.src = image;
    })
    .catch((e) => {
      alert(e.toString());
    });
};

// export const TestButton = L.Control.extend({
//   constructor(args) {
//     console.log('b args',args);
//     this.lyr = args.lyr;
//   },
//   options: {
//     position: 'topright',
//   },

//   onAdd(map) {
//     const container = L.DomUtil.create('div', 'leaflet-bar leaflet-control leaflet-control-custom');

//     container.style.backgroundColor = 'white';
//     container.style.backgroundImage = 'url(http://t1.gstatic.com/images?q=tbn:ANd9GcR6FCUMW5bPn8C4PbKak2BJQQsmC-K9-mbYBeFZm1ZM2w2GRy40Ew)';
//     container.style.backgroundSize = '30px 30px';
//     container.style.width = '30px';
//     container.style.height = '30px';

//     container.onclick = () => {
//       console.log('b args', map);
//       takeScreenShot(map, this.lyr);
//       console.log('buttonClicked', this.lyr);
//     };

//     return container;
//   },
// });
