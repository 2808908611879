/* eslint-disable max-len */
import Api, { endpoints } from '@Helpers/api';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

export default ({ setIsLoadingData = () => null }) => {
  const [open, setOpen] = useState(false);

  const createDemoAccount = (type, demoId) => {
    let data = {};
    if (type === 'add') data = { demoId };
    if (type === 'clear') data = { clear: 1 };
    setIsLoadingData(true);
    Api({
      endpoint: endpoints.createDemoAsset(),
      data,
      onSuccess: () => {
        setIsLoadingData(false);
        setOpen(false);
        window.location.reload();
      },
      onFail: (error) => toast('error', error),
    });
  };

  return {
    open,
    setOpen,
    createDemoAccount,
  };
};
