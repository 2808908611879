import { useEffect, useState } from 'react';
import Api, { endpoints } from '@Helpers/api';

export default function Hook({ project }) {
  const [reports, setReports] = useState([]);
  const [storedReports, setStoredReports] = useState([]);
  const [siteReports, setSiteReports] = useState([]);
  const [justUpdated, setJustUpdated] = useState(false);
  const [selectedReport, setSelectedReport] = useState({});
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [reportName, setReportName] = useState(selectedReport?.name);

  const refreshGenerate = (data) => {
    Api({
      endpoint: endpoints.getReports(),
      data,
      onSuccess: (response) => setReports(response.data),
      onFail: setReports([]),
    });
  };
  useEffect(() => {
    if (!project.id) return;
    const data = { AssetId: project.id };
    refreshGenerate(data);
    Api({
      endpoint: endpoints.getStoredReport(),
      data,
      onSuccess: (response) => setStoredReports(response.data),
      onFail: setStoredReports([]),
    });
    Api({
      endpoint: endpoints.getSiteReport(project.id, 'site-reports'),
      onSuccess: (response) => setSiteReports(response.data),
      onFail: setSiteReports([]),
    });
    setJustUpdated(false);
  }, [project, justUpdated]);
  const onSave = (data) => {
    Api({
      endpoint: endpoints.createReport(),
      data,
      onSuccess: (response) => {
        toast('success', 'Successfully Create ');
        setJustUpdated(true);
      },
      onFail: console.log('lol'),
    });
  };
  const onDelete = (id) => {
    Api({
      endpoint: endpoints.deleteReport(id),
      onSuccess: (response) => {
        toast('success', 'Successfully Delete ');
        setJustUpdated(true);
      },
      onFail: console.log('lol'),
    });
  };

  const updateReport = () => {
    const data = {};
    if (!!reportName) data.name = reportName;
    Api({
      endpoint: endpoints.updateAssetFile(selectedReport.id),
      data,
      onSuccess: () => {
        setOpenEditDialog(false);
        toast('success', 'Report name updated successfully');
        setJustUpdated(true);
      },
      onFail: (err) => {
        toast('error', `Failed to update report name: ${err.toString()}`);
      },
    });
  };

  const deleteSiteReport = (id) => {
    Api({
      endpoint: endpoints.deleteAssetFile(id),
      onSuccess: () => {
        toast('success', 'Successfully Delete ');
        setJustUpdated(true);
      },
      onFail: console.log('lol'),
    });
  };

  return {
    reports,
    onDelete,
    onSave,
    storedReports,
    siteReports,
    deleteSiteReport,
    openEditDialog,
    setOpenEditDialog,
    reportName,
    setReportName,
    updateReport,
    selectedReport,
    setSelectedReport,
    setJustUpdated,
    refreshGenerate,
  };
}
