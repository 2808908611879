import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import React from 'react';

const ReportTable = ({ dataTable, selected_row, set_selected_row }) => {
  const options = {
    filter: 'false',
    filterType: 'multiselect',
    print: 'false',
    download: 'false',
    sort: 'false',
    viewColumns: 'false',
    search: 'false',
    tableBodyMaxHeight: '35vh',
    rowsPerPage: dataTable.length,
    rowsSelected: selected_row.map((row) => dataTable.findIndex((item) => item.id === row.id)),
    rowsPerPageOptions: [dataTable.length],
    onRowSelectionChange: (currentRowsSelected, allRowsSelected, rowsSelected) => {
      const ids = rowsSelected.map((index) => dataTable[index]);
      set_selected_row(ids);
    },
    onRowsDelete: () => false,
    customToolbarSelect: () => <div />,
  };
  return (
    <MuiThemeProvider theme={getMuiTheme()}>
      <MUIDataTable
        options={options}
        columns={columns}
        data={dataTable}
      />
    </MuiThemeProvider>
  );
};

const getMuiTheme = () => createMuiTheme({
  typography: {
    fontFamily: 'CeraProRegular',
  },
  overrides: {
    MuiTableHead: {
      root: {
        '& .MuiTableCell-root': {
          color: 'gray',
          fontSize: '13px',
          fontWeight: 500,
        },
      },
    },
    MuiTableRow: {
      root: {
        color: '#048279',
        '&$selected:hover': {
          backgroundColor: '#80808054',
        },
      },
    },
    MuiTableCell: {
      body: {
        color: 'var(--primary-color) !important',
        fontSize: 14,
        fontWeight: 400,
      },
    },
    MuiChip: {
      root: {
        backgroundColor: '#03A69A',
        color: 'white',
      },
      deleteIcon: {
        backgroundColor: '#03A69A',
        color: 'white',
      },
    },
    MUIDataTableToolbar: {
      root: { display: 'none' },
    },
  },
});

export default ReportTable;

const columns = [
  { name: 'id', label: 'ID', options: { filter: false, sort: false } },
  { name: 'name', label: 'Name', options: { filter: false, sort: false } },
  { name: 'module', label: 'Module', options: { filter: false, sort: false } },
  { name: 'type', label: 'Type', options: { filter: false, sort: false } },
  { name: 'status', label: 'Status', options: { filter: false, sort: false } },
  { name: 'date', label: 'Date', options: { filter: false, sort: false } },
];
