import React, { useState } from 'react';
import {
  Dialog, DialogContent, DialogTitle, Grid, DialogActions, TextField
} from '@material-ui/core';
import Button from '@Components/Button';

export default function EditReport({ open, setOpen, selected , ...h}) {
  return (
    <Dialog open={h.openEditDialog} onClose={() => setOpen(false)} fullWidth>
      <div className="d-flex justify-content-between">
        <DialogTitle>Edit Asset File</DialogTitle>
      </div>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid xs={12}>
            <CustomTextField
              label="Report Name"
              value={h.reportName}
              onChange={(e) => h.setReportName(e.target.value)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className="mt-5 mb-3 mr-3">
        <Button onClick={() => setOpen(false)} variant="text">Cancel</Button>
        <Button onClick={h.updateReport}>Save Changes</Button>
      </DialogActions>
    </Dialog>
  );
}

const CustomTextField = (props) => (
  <TextField
    variant="outlined"
    fullWidth
    size="small"
    className="mb-3 mt-2"
    {...props}
  />
);
