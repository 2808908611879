import update from 'immutability-helper';
import { memo, useCallback, useEffect, useState } from 'react';
import { useDrop } from 'react-dnd';
import { Card } from './Card.js';

const DnDContainer = memo(({ items, setUpdatedList }) => {
  const [cards, setCards] = useState(items);
  const findCard = useCallback(
    (id) => {
      const card = cards.filter((c) => `${c.id}` === id)[0];
      return {
        card,
        index: cards.indexOf(card),
      };
    },
    [cards],
  );
  const moveCard = useCallback(
    (id, atIndex) => {
      const { card, index } = findCard(id);
      setCards(
        update(cards, {
          $splice: [
            [index, 1],
            [atIndex, 0, card],
          ],
        }),
      );
    },
    [findCard, cards, setCards],
  );
  const [, drop] = useDrop(() => ({ accept: 'card' }));
  useEffect(() => {
    setUpdatedList(cards);
  }, [cards]);

  return (
    <div ref={drop} style={{ width: 400 }}>
      {cards.map((card) => (
        <Card
          key={card.id}
          id={`${card.id}`}
          text={card.text}
          moveCard={moveCard}
          findCard={findCard}
        />
      ))}
    </div>
  );
});

export default DnDContainer;
