import { useEffect, useState } from 'react';
import Api, { endpoints } from '@Helpers/api';
import { uuid } from 'uuidv4';

export default function Hook() {
  const [layers, setLayers] = useState([]);
  const [show, setShow] = useState(false);
  const [uploadPercentages, setUploadPercentages] = useState([0]);
  const [totalFiles, setTotalFiles] = useState(0);
  const [uploadedfiles, setuploadedfiles] = useState(0);

  const refresh = () => {
    Api({
      endpoint: endpoints.getMapping(),
      onSuccess: (response) => setLayers(response.data),
      onFail: setLayers([]),
    });
  };

  const startProcessing = (id) => {
    Api({
      endpoint: endpoints.updateMapping(id),
      data: {
        status: 1,
      },
      onSuccess: () => {
        toast('success', 'Layer Processing Initiated');
        refresh();
      },
      onFail: () => {
        toast('error', 'Error occurred. Please try again later.');
      },
    });
  };

  const uploadFile = (cpid, files) => {
    if (!files.length) return;
    setTotalFiles(files.length);
    // turn off tracker
    setUploadPercentages([...Array(files.length)].map(() => 0));
    // turn off tracker
    const batchuploaduuid = uuid();
    uploadFileRecursion(cpid, batchuploaduuid, files, 0);
  };

  const uploadFileRecursion = (cpid, batchuploaduuid, filesToUpload, idx) => {
    Api({
      endpoint: endpoints.uploadCloudProcessingBySession(cpid, batchuploaduuid),
      files: [filesToUpload[idx]],
      uploadPercent: (p) => updatePercentage(idx, p),
      onSuccess: () => {
        // turn off tracker
        toast('success', `${filesToUpload[idx].name} uploaded`);
        updatePercentage(idx, 'done');
        if (idx + 1 < filesToUpload.length) uploadFileRecursion(cpid, batchuploaduuid, filesToUpload, idx + 1);
        setuploadedfiles(v => v + 1);
        // turn off tracker
      },
      onFail: (error) => {
        toast('error', 'Something went wrong, please try again.');
        // if (['Internet Interrupted'].includes(error)) setIsNetworkInterrupted(true);
        // failResponse();
      },
    });
  };

  const updatePercentage = (i, p) => {
    if (p === 100) p = 99;
    if (p === 'done') p = 100;
    setUploadPercentages(arr => { arr[i] = p; return [...arr]; });
  };

  useEffect(() => {
    if (!totalFiles) return;
    if (uploadedfiles === totalFiles) refresh();
  }, [uploadedfiles, totalFiles]);

  useEffect(() => {
    refresh();
  }, []);

  return {
    layers,
    setLayers,
    startProcessing,
    show,
    setShow,
    uploadPercentages,
    setUploadPercentages,
    uploadedfiles,
    setuploadedfiles,
    uploadFile,
  };
}
