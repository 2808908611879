import { useEffect, useState } from 'react';
import Api, { endpoints } from '@Helpers/api';
import _ from 'lodash';

export default function Hook() {

  const [pageAccessList, setPageAccessList] = useState([]);
  const [allPageAccessList, setAllPageAccessList] = useState([]);
  const [isLoadingPageAccess, setIsLoadingPageAccess] = useState(false);

  const getPageAccessList = () => {
    setIsLoadingPageAccess(true);
    Api({
      endpoint: endpoints.getStaticData(),
      onSuccess: ({ data: { PageAccess } }) => {
        setPageAccessList(PageAccess);
        setAllPageAccessList(PageAccess);
        setIsLoadingPageAccess(false);
      },
      onFail: () => { toast('error', 'Failed get page access data'); },
    });
  };

  useEffect(() => {
    getPageAccessList();
  }, [])

  return {
    pageAccessList,
    setPageAccessList,
    allPageAccessList,
    isLoadingPageAccess,
  };
}
