import { useEffect, useState } from 'react';
import Api, { endpoints } from '@Helpers/api';
import _ from 'lodash';

export default function Hook() {
  const [allProjects, setAllProjects] = useState([]);
  const [searchProjects, setSearchProjects] = useState([]);
  const [filterProjects, setFilterProjects] = useState([]);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tags, setTags] = useState([]);
  const [tagsFilter, setTagsFilter] = useState(['']);

  useEffect(() => {
    refresh();
  }, []);

  const refresh = () => {
    setIsLoading(true);
    Api({
      endpoint: endpoints.getAssets(),
      onSuccess: ({ data }) => {
        setAllProjects(data);
        setSearchProjects(data);
        setFilterProjects(null);
        setTags(data.map(d => d.asset_type));
        setIsLoading(false);
      },
      onFail: (err) => { toast('error', err); setIsLoading(false); },
    });
  };

  const handleFilter = (e) => {
    const filter = e.target.value.toLowerCase();
    if (filter === '0') {
      setSearchProjects(allProjects);
      setFilterProjects(null);
      return;
    }
    const result = allProjects?.filter((data) => {
      return data.state.toLowerCase().search(filter) != -1;
    });
    setSearchProjects(result);
    setFilterProjects(result);
  };

  useEffect(() => {
    if (tagsFilter.length === 0) {
      setSearchProjects(allProjects);
    } else {
      const result = tagsFilter.map(data => {
        const projs = [...allProjects].filter(x => !!x.asset_type);

        return projs?.filter(project => {
          console.log('vvv', project.asset_type);
          return project.asset_type.toLowerCase().search(data.toLowerCase()) != -1;
        });
      });
      setSearchProjects(_.uniq(result.flat()));
    }
  }, [tagsFilter]);

  return {
    projects: filterProjects?.length ? filterProjects && searchProjects : searchProjects,
    searchProjects,
    setSearchProjects,
    handleFilter,
    open,
    setOpen,
    isLoading,
    tags: _.uniq(tags.filter(tag => !!tag).map(t => (t.indexOf(',') > -1 ? t.split(',') : t)).flat()),
    tagsFilter,
    setTagsFilter,
    filterProjects,
    refreshAsset: refresh,
  };
}
