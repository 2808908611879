/* eslint-disable max-len */
export const IconDropzone = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 127.804 89.463"
      {...props}
    >
      <g data-name="Group 2190">
        <g data-name="Group 2189" fill="#ced4da">
          <path
            data-name="Path 1606"
            d="M117.153 0H10.65A10.663 10.663 0 000 10.65v68.162a10.663 10.663 0 0010.65 10.65h106.5a9.861 9.861 0 001.783-.156 10.621 10.621 0 008.867-10.495V10.65A10.663 10.663 0 00117.153 0zM10.65 85.2a6.39 6.39 0 01-6.365-5.828l42.469-27.767 43.189 33.6zm112.893-6.39a6.375 6.375 0 01-5.346 6.3 5.792 5.792 0 01-1.044.092H96.882L68.66 63.239l25.24-20.18 29.646 17.788zm0-22.932L94.82 38.646a2.13 2.13 0 00-2.428.162L65.2 60.556 48.17 47.311a2.13 2.13 0 00-2.471-.1L4.26 74.3V10.65a6.39 6.39 0 016.39-6.39h106.5a6.39 6.39 0 016.39 6.39z"
          />
          <path
            data-name="Path 1607"
            d="M63.902 12.78a12.78 12.78 0 1012.78 12.78 12.78 12.78 0 00-12.78-12.78zm0 21.3a8.52 8.52 0 118.52-8.52 8.52 8.52 0 01-8.52 8.52z"
          />
        </g>
      </g>
    </svg>
  );
};
